import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { FollowUpTaskStatus } from '@app/crud/customers/customer.type';

import { usePermissions } from '@app/hooks';
import useActionHandler from '@app/pages/customers/Hooks/useActionHandler';
import { actions } from '@app/store/modal/modal.store';

import LeadActions from './LeadActions';
import LeadRowContent from './LeadRowContent';
import LeadRowImage from './LeadRowImage';

const LeadRow = ({
    lead,
    parent,
    hasActions,
    isSelectable,
    isSelected,
    displayChild,
    flatChild,
    separator,
    isChild,
    onClick,
    isLastChild,
}) => {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(isSelected);
    const handleActionClick = useActionHandler(lead);
    const leadTypeSlug = lead.leadType.slug.toLowerCase();
    const leadStatusSlug = lead.leadStatus.slug.toLowerCase();
    const isFollowUpTaskStatusOpen = lead.flags.includes(FollowUpTaskStatus.OPEN);
    const isAwaitingOpportunity = ['in_progress_awaiting_opportunity_update'].includes(leadStatusSlug);
    const { hasPermission } = usePermissions();

    const handleClick = () => {
        if (isSelectable) {
            onClick(lead, !selected);
            setSelected(!selected);
        }
    };

    useEffect(() => {
        setSelected(isSelected);
    }, [setSelected, isSelected]);

    const redirectToSaleforce = () => {
        const { REACT_APP_SALESFORCE_BMW } = process.env;
        const leadOriginId = lead?.originId?.replace('BMW_GROUP_', '');
        window.open(`${REACT_APP_SALESFORCE_BMW}/${leadOriginId}/view`, '_blank');
    };

    const renderActions = () => {
        if (hasActions) {
            if (lead.nextActions.length !== 0) {
                return (
                    <div className="lead-row__actions py-lg-4 pt-0 pb-4">
                        <LeadActions
                            lead={lead}
                            onClick={
                                isFollowUpTaskStatusOpen ? () => handleActionClick('follow_up_task') : handleActionClick
                            }
                        />
                    </div>
                );
            } else if (isAwaitingOpportunity) {
                return (
                    <div className="lead-row__wrapper-label font-weight-bold p-4">
                        <span className="font-weight-bold font-size-sm">{lead?.leadStatus?.subName}</span>
                        <Button
                            className="align-self-center w-100 mt-2"
                            variant="outline-primary"
                            onClick={() => redirectToSaleforce()}
                        >
                            <FormattedMessage id="CUSTOMERS.ACTIONS.VIEW_SALEFORCE_DETAILS" />
                        </Button>
                    </div>
                );
            }
            return (
                <Button
                    className="align-self-center"
                    variant="outline-primary"
                    disabled={!hasPermission(['LEAD_VIEW_LEAD_DETAILS'])}
                    onClick={() => handleActionClick('show_details')}
                >
                    <FormattedMessage id="CUSTOMERS.ACTIONS.VIEW_LEAD_DETAILS" />
                </Button>
            );
        }
        return null;
    };

    return (
        <>
            <div
                role="presentation"
                onClick={handleClick}
                className={classNames('lead-row', {
                    'lead-row--children': isChild,
                    'lead-row--selected': selected,
                    'lead-row--selectable': isSelectable,
                })}
            >
                {isChild && <div className="lead-row__junction" />}
                <div className={`lead-row__type lead-row__type--${leadTypeSlug}`} />
                <div className="lead-row__wrapper">
                    <div
                        className={`lead-row__picture-wrapper ${
                            lead.leadType.slug.includes('marketing_campaign') &&
                            'lead-row__picture-wrapper--marketing-campaign'
                        }`}
                    >
                        <LeadRowImage type={leadTypeSlug} />
                    </div>
                    <div className="lead-row__content py-4">
                        <LeadRowContent lead={lead} />
                    </div>
                    {renderActions()}
                    {isChild && isLastChild && (
                        <div
                            role="presentation"
                            className="d-flex align-items-end text-secondary font-weight-semibold"
                            onClick={() =>
                                dispatch(
                                    actions.modalChange('unlink_lead', {
                                        parentId: parent.id,
                                    }),
                                )
                            }
                        >
                            <div className="lead-row__unlink mb-4">
                                <FormattedMessage id="CUSTOMERS.ACTIONS.VIEW_LEAD_DETAILS" />
                                <i className="la la-lg la-unlink text-secondary ml-2" />
                            </div>
                        </div>
                    )}
                </div>
                {!isLastChild && separator}
            </div>
            {lead?.linkedLeads &&
                displayChild &&
                lead.linkedLeads.map((linkedLead, idx) => (
                    <LeadRow
                        key={linkedLead.id}
                        lead={linkedLead}
                        parent={lead}
                        isChild={!flatChild}
                        flatChild={flatChild}
                        isSelected={isSelected}
                        isLastChild={!flatChild && idx + 1 === lead.linkedLeads.length}
                        hasActions={false}
                    />
                ))}
        </>
    );
};

LeadRow.defaultProps = {
    parent: null,
    hasActions: true,
    isChild: false,
    isLastChild: false,
    isSelectable: false,
    isSelected: false,
    displayChild: true,
    flatChild: false,
    onClick: () => {},
};

export default LeadRow;
