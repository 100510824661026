import classNames from 'classnames';
import * as dayjs from 'dayjs';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { empty } from '@app/helpers/ToolsHelper';

import Loader from '@app/partials/content/Loader';

import BusinessChild from './BusinessChild';
import BusinessRowInfo from './BusinessRowInfo';
import './Business.scss';

const CustomerBusiness = ({ business, loading }) => {
    const userData = useSelector((state) => state.auth.userData);
    const context = userData?.context?.toUpperCase();

    return (
        <Col className="business">
            <Card className="card--shadowed">
                <Card.Header>
                    <div className="d-flex align-items-center">
                        <i className="la la-money la-2x text-light mr-2" />
                        <span>{Intl.formatMessage({ id: 'CUSTOMER.VIEW.BUSINESS.TITLE' })}</span>
                    </div>
                </Card.Header>
                <Card.Body
                    className="position-relative"
                    style={loading ? { paddingTop: '4.50rem', paddingBottom: '4.50rem' } : null}
                >
                    {loading ? (
                        <Loader
                            style={{ width: '5rem', height: '5rem' }}
                            overlay
                            wrapperStyle={{ top: '0', left: '0' }}
                        />
                    ) : (
                        <Row>
                            {(business && business.error) || !business ? (
                                <Col className="text-center">
                                    <span className="font-weight-normal">
                                        {Intl.formatMessage({ id: 'CUSTOMER.VIEW.BUSINESS.NO_COMMAND' }, { context })}
                                    </span>
                                </Col>
                            ) : (
                                <>
                                    <Col>
                                        <BusinessChild
                                            title={Intl.formatMessage({
                                                id: 'CUSTOMER.VIEW.BUSINESS.CARD.CUSTOMER.TITLE',
                                            })}
                                            className={classNames({
                                                'business--disabled': empty(business?.lastActivityDate),
                                            })}
                                            icone="la-user"
                                        >
                                            <Row>
                                                <Col className="d-flex align-items-center mb-4">
                                                    <BusinessRowInfo
                                                        title={Intl.formatMessage({
                                                            id: 'CUSTOMER.VIEW.BUSINESS.CARD.CUSTOMER.ACTIVITY',
                                                        })}
                                                        icone="la-clock-o"
                                                        value={
                                                            business?.lastActivityDate
                                                                ? dayjs(business?.lastActivityDate).format('DD/MM/YYYY')
                                                                : '-'
                                                        }
                                                        variant="success"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="d-flex align-items-center">
                                                    <BusinessRowInfo
                                                        title={Intl.formatMessage({
                                                            id: 'CUSTOMER.VIEW.BUSINESS.CARD.CUSTOMER.CONTACT',
                                                        })}
                                                        icone="la-map-marker"
                                                        value={business?.lastActivityDealership}
                                                        variant="success"
                                                    />
                                                </Col>
                                            </Row>
                                        </BusinessChild>
                                    </Col>

                                    <Col>
                                        <BusinessChild
                                            title={Intl.formatMessage({ id: 'CUSTOMER.VIEW.BUSINESS.CARD.VO.TITLE' })}
                                            className={classNames({
                                                'business--disabled': empty(
                                                    business?.turnoverCategories?.turnoverVo?.averageTurnover,
                                                ),
                                            })}
                                            icone="la-car"
                                        >
                                            <Row>
                                                <Col className="d-flex align-items-center mb-4">
                                                    <BusinessRowInfo
                                                        title={Intl.formatMessage({
                                                            id: 'CUSTOMER.VIEW.BUSINESS.CARD.VO.NB_BUY',
                                                        })}
                                                        icone="la-shopping-cart"
                                                        value={
                                                            business?.turnoverCategories?.turnoverVo?.activitiesCounter
                                                        }
                                                        variant="primary"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="d-flex align-items-center">
                                                    <BusinessRowInfo
                                                        title={Intl.formatMessage({
                                                            id: 'CUSTOMER.VIEW.BUSINESS.BUDGET',
                                                        })}
                                                        icone="la-euro"
                                                        value={
                                                            <NumberFormat
                                                                value={
                                                                    business?.turnoverCategories?.turnoverVo
                                                                        ?.averageTurnover || '0'
                                                                }
                                                                decimalSeparator="."
                                                                thousandSeparator=" "
                                                                decimalScale="2"
                                                                displayType="text"
                                                                suffix=" €"
                                                            />
                                                        }
                                                        variant="primary"
                                                    />
                                                </Col>
                                            </Row>
                                        </BusinessChild>
                                    </Col>

                                    <Col>
                                        <BusinessChild
                                            title={Intl.formatMessage({ id: 'CUSTOMER.VIEW.BUSINESS.CARD.VN.TITLE' })}
                                            className={classNames({
                                                'business--disabled': empty(
                                                    business?.turnoverCategories?.turnoverVn?.averageTurnover,
                                                ),
                                            })}
                                            icone="la-car"
                                        >
                                            <Row>
                                                <Col className="d-flex align-items-center mb-4">
                                                    <BusinessRowInfo
                                                        title={Intl.formatMessage({
                                                            id: 'CUSTOMER.VIEW.BUSINESS.CARD.VN.NB_BUY',
                                                        })}
                                                        icone="la-shopping-cart"
                                                        value={
                                                            business?.turnoverCategories?.turnoverVn?.activitiesCounter
                                                        }
                                                        variant="danger"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="d-flex align-items-center">
                                                    <BusinessRowInfo
                                                        title={Intl.formatMessage({
                                                            id: 'CUSTOMER.VIEW.BUSINESS.BUDGET',
                                                        })}
                                                        icone="la-euro"
                                                        value={
                                                            <NumberFormat
                                                                value={
                                                                    business?.turnoverCategories?.turnoverVn
                                                                        ?.averageTurnover || '0'
                                                                }
                                                                decimalSeparator="."
                                                                decimalScale="2"
                                                                thousandSeparator=" "
                                                                displayType="text"
                                                                suffix=" €"
                                                            />
                                                        }
                                                        variant="danger"
                                                    />
                                                </Col>
                                            </Row>
                                        </BusinessChild>
                                    </Col>

                                    <Col>
                                        <BusinessChild
                                            title={Intl.formatMessage({ id: 'CUSTOMER.VIEW.BUSINESS.CARD.APV.TITLE' })}
                                            className={classNames({
                                                'business--disabled': empty(
                                                    business?.turnoverCategories?.turnoverApv?.averageTurnover,
                                                ),
                                            })}
                                            icone="la-wrench"
                                        >
                                            <Row>
                                                <Col className="d-flex align-items-center mb-4">
                                                    <BusinessRowInfo
                                                        title={Intl.formatMessage({
                                                            id: 'CUSTOMER.VIEW.BUSINESS.CARD.APV.NB_BENEFITS',
                                                        })}
                                                        icone="la-wrench"
                                                        value={
                                                            business?.turnoverCategories?.turnoverApv?.activitiesCounter
                                                        }
                                                        variant="yellow"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="d-flex align-items-center">
                                                    <BusinessRowInfo
                                                        title={Intl.formatMessage({
                                                            id: 'CUSTOMER.VIEW.BUSINESS.BUDGET',
                                                        })}
                                                        icone="la-euro"
                                                        value={
                                                            <NumberFormat
                                                                value={
                                                                    business?.turnoverCategories?.turnoverApv
                                                                        ?.averageTurnover || '0'
                                                                }
                                                                decimalSeparator="."
                                                                thousandSeparator=" "
                                                                decimalScale="2"
                                                                displayType="text"
                                                                suffix=" €"
                                                            />
                                                        }
                                                        variant="yellow"
                                                    />
                                                </Col>
                                            </Row>
                                        </BusinessChild>
                                    </Col>

                                    <Col lg={3}>
                                        <BusinessChild
                                            title={Intl.formatMessage({ id: 'CUSTOMER.VIEW.BUSINESS.CARD.CA.TITLE' })}
                                            className={classNames({
                                                'business--disabled': empty(business?.fullTurnover),
                                            })}
                                            icone="la-signal"
                                        >
                                            <Row>
                                                <Col className="d-flex align-items-center mb-4">
                                                    <BusinessRowInfo
                                                        title={Intl.formatMessage({
                                                            id: 'CUSTOMER.VIEW.BUSINESS.CARD.CA.CA_TOTAL',
                                                        })}
                                                        icone="la-calculator"
                                                        value={
                                                            <NumberFormat
                                                                value={business?.fullTurnover || '0'}
                                                                decimalSeparator="."
                                                                thousandSeparator=" "
                                                                decimalScale="2"
                                                                displayType="text"
                                                                suffix=" €"
                                                            />
                                                        }
                                                        variant="closed"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="d-flex align-items-center">
                                                    <BusinessRowInfo
                                                        title={Intl.formatMessage({
                                                            id: 'CUSTOMER.VIEW.BUSINESS.CARD.CA.12MONTH',
                                                        })}
                                                        icone="la-euro"
                                                        value={
                                                            <NumberFormat
                                                                value={business?.twelveMonthsTurnover || '0'}
                                                                decimalSeparator="."
                                                                thousandSeparator=" "
                                                                decimalScale="2"
                                                                displayType="text"
                                                                suffix=" €"
                                                            />
                                                        }
                                                        variant="closed"
                                                    />
                                                </Col>
                                                <Col className="d-flex align-items-center">
                                                    <BusinessRowInfo
                                                        title={Intl.formatMessage({
                                                            id: 'CUSTOMER.VIEW.BUSINESS.CARD.CA.36MONTH',
                                                        })}
                                                        icone="la-euro"
                                                        value={
                                                            <NumberFormat
                                                                value={business?.thirtysixMonthsTurnover || '0'}
                                                                decimalSeparator="."
                                                                thousandSeparator=" "
                                                                decimalScale="2"
                                                                displayType="text"
                                                                suffix=" €"
                                                            />
                                                        }
                                                        variant="closed"
                                                    />
                                                </Col>
                                            </Row>
                                        </BusinessChild>
                                    </Col>
                                </>
                            )}
                        </Row>
                    )}
                </Card.Body>
            </Card>
        </Col>
    );
};

export default CustomerBusiness;
