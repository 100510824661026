import classNames from 'classnames';
import { Divider } from 'primereact/divider';
import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import store from '@app/store/store';

import Label from '@app/partials/content/Label';

import { getTransformation } from '../../helpers/DashboardHelper';

import DashboardPerfState from './DashboardPerfState';

const OBJECTIF = 15;

const DashboardOnlinePerf = ({ data, className }) => {
    const { context } = store.getState().auth.userData;
    const excludedTypes = ['local', 'marketing_campaign'];
    const details = Object.entries(data || {})?.filter?.(
        ([type]) => !excludedTypes.some((excludedType) => type.startsWith(excludedType)),
    );

    const countersStrongTransformations = details.reduce(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (acc, [_type, detail]) => ({
            won: acc.won + (detail?.wonStrong || 0),
            total: acc.total + (detail?.takenStrong || 0),
        }),
        {
            won: 0,
            total: 0,
        },
    );

    const countersGeneralTransformations = details.reduce(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (acc, [_type, detail]) => ({
            won: acc.won + (detail?.won || 0),
            total: acc.total + (detail?.taken || 0),
        }),
        {
            won: 0,
            total: 0,
        },
    );

    const strongTransformationPercentage = getTransformation(
        countersStrongTransformations?.won,
        0,
        countersStrongTransformations?.total,
    );
    const generalTransformationPercentage = getTransformation(
        countersGeneralTransformations?.won,
        0,
        countersGeneralTransformations?.total,
    );

    const isLeadConstructor = (leadType) => leadType === 'constructeur';

    return (
        <div className={classNames('dashboard-online-perf d-flex flex-column', className)}>
            <div className="flex-grow-1">
                <div className="font-size-lg font-weight-bold d-flex flex-wrap align-items-center justify-content-between">
                    <i className="dashboard-general-perf__icon las la-2x la-mouse-pointer mr-2" />
                    <div className="flex-grow-1 d-flex align-items-center">
                        <FormattedMessage id="DASHBOARD.ONLINE_PERF.TITLE" />
                        <Label className="ml-2" variant="primary">
                            <FormattedHTMLMessage
                                id="DASHBOARD.GENERAL_PERF.GOAL"
                                values={{ context: context.toUpperCase(), number: OBJECTIF }}
                            />
                        </Label>
                    </div>
                </div>
                <div className="d-flex flex-wrap flex-column flex-lg-row justify-content-between mt-3 mb-1">
                    <div className="dashboard-block mb-3 mb-lg-0 mr-lg-2 d-flex flex-grow-1 flex-wrap align-items-center">
                        <div
                            className={classNames(
                                'dashboard-general-perf__taux flex-md-grow-0 flex-grow-1 font-weight-semibold',
                                {
                                    'text-danger': strongTransformationPercentage < OBJECTIF,
                                    'text-success': strongTransformationPercentage > OBJECTIF,
                                },
                            )}
                        >
                            {strongTransformationPercentage}&nbsp;%
                        </div>
                        <div className="font-weight-bold mx-3 line-height-md text-nowrap">
                            <FormattedHTMLMessage id="DASHBOARD.GENERAL_PERF.STRONG_TRANSFORMATION" />
                        </div>
                    </div>
                    <div className="dashboard-block ml-lg-2 d-flex flex-grow-1 flex-wrap align-items-center text-dark-50">
                        <div className="dashboard-general-perf__taux flex-md-grow-0 flex-grow-1 font-weight-semibold">
                            {generalTransformationPercentage}&nbsp;%
                        </div>
                        <div className="font-weight-bold mx-3 line-height-md text-nowrap">
                            <FormattedHTMLMessage id="DASHBOARD.GENERAL_PERF.TRANSFORMATION" />
                        </div>
                    </div>
                </div>
            </div>
            {details?.length > 0 && (
                <div className="d-flex flex-lg-nowrap flex-wrap justify-content-between dashboard-online-perf__list">
                    {details.map(([leadType, detail]) => (
                        <div key={detail?.name} className="dashboard-online-perf__item p-0 mt-2">
                            <div className="d-inline-block">
                                <div className="font-weight-bold font-size-lg">{detail?.name}</div>
                                <Divider className="my-2" />
                                <div>
                                    <strong>{detail?.taken}</strong>{' '}
                                    <FormattedMessage id="DASHBOARD.ONLINE_PERF.TAKEN" />
                                </div>
                                <DashboardPerfState detail={detail} leadType={leadType} />
                                {/* hide other types until we get the right data for this section - cf: AMNG-2454 */}
                                {['vo', 'vn', 'vd'].includes(leadType) && (
                                    <div>
                                        <strong>
                                            {!isLeadConstructor(leadType)
                                                ? getTransformation(detail?.wonStrong, 0, detail?.takenStrong)
                                                : getTransformation(detail?.won, 0, detail?.taken)}
                                            &nbsp;% <FormattedMessage id="DASHBOARD.ONLINE_PERF.TRANSFORMATION" />
                                        </strong>
                                        {` `}
                                        {!isLeadConstructor(leadType) && (
                                            <FormattedMessage id="DASHBOARD.ONLINE_PERF.STRONG" />
                                        )}
                                        <span className="font-size-xs font-weight-bold">
                                            &nbsp;({!isLeadConstructor(leadType) ? detail?.wonStrong : detail?.won} /{' '}
                                            {!isLeadConstructor(leadType) ? detail?.takenStrong : detail?.taken})
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

DashboardOnlinePerf.Skeleton = () => (
    <Skeleton borderRadius="10px" height="200px" className="d-flex flex-column flex-lg-grow-1 mt-lg-2 mt-6 p-35" />
);
export default DashboardOnlinePerf;
