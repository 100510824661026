import classNames from 'classnames';
import dayjs from 'dayjs';
import { Column } from 'primereact/column';
import React, { useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { routeTo } from '@app/helpers/RoutesHelper';

import { useAppSelector, useModal, usePermissions } from '@app/hooks';
import getTypeColor from '@app/pages/stock/helpers/StockHelper';
import { isPublishAutosphere } from '@app/pages/stock/helpers/StockRules';
import StockPrice from '@app/pages/stock/partials/price/StockPrice';
import StockPriceLeadsButton from '@app/pages/stock/partials/price/StockPriceLeadsButton';
import UnPublishedLabel from '@app/pages/stock/partials/UnPublishedLabel';
import ROUTES from '@app/router/Routes';
import { actions as modalActions } from '@app/store/modal/modal.store';
import { actions as stockActions } from '@app/store/stock/stock.store';
import { Vehicle } from '@app/store/stock/stock.type';

import HoCDataTable from '@app/partials/content/HoCDataTable';
import Label from '@app/partials/content/Label';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';
import {
    getBrandTemplate,
    getPictureTemplate,
    getPublishDays,
    getRegistrationTemplate,
    getVehicleInternalIdTemplate,
} from '@app/partials/content/Vehicle/VehicleHelpers';

import Price from '@app/components/formatters/price/Price';

import useRadarEncoder from '../hooks/useRadarEncoder';
import { CancelModal, ConfirmModal, ModifyModal } from '../modals';

import { checkProcessingPricingExpiration } from './helpers/ProcessingPricing';
import StockPricingToDoCheckEvent from './StockPricingToDoCheckEvent';
import StockPricingToDoHistoryItem from './StockPricingToDoHistoryEvent';

import '../modals/pricing.scss';

type StockActionsTodoDatatableProps = {
    actions: {
        _data: Vehicle[];
    };
    handleFilter: Function;
    handlePagination: Function;
    handleSort: Function;
    loading: boolean;
    total: number;
    items: number;
    start: number;
    sortField: string;
    sortOrder: string;
    isHistory: boolean;
    page: string;
    timeRemaining: {
        distance: number;
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
    };
    duration: number;
    refresh: () => void;
};

const StockActionsTodoDatatable = ({
    actions,
    handleFilter,
    handlePagination,
    handleSort,
    loading,
    total,
    items,
    start,
    sortField,
    sortOrder,
    isHistory,
    page,
    timeRemaining,
    duration,
    refresh,
}: StockActionsTodoDatatableProps) => {
    const [showPricingConfirmModal, setShowPricingConfirmModal] = useModal(false, 'pricing_confirm');
    const [showPricingModifyModal, setShowPricingModifyModal] = useModal(false, 'pricing_modify');
    const [showPricingCancelModal, setShowPricingCancelModal] = useModal(false, 'pricing_cancel');
    const dispatch = useDispatch();
    // eslint-disable-next-line no-underscore-dangle
    const getVehicle = (action: { _data: Vehicle }) => action._data;
    const { vehiclesSelected } = useAppSelector((state) => state.stock);
    const [vehicleItem, setVehicleItem] = useState(null);
    const { hasPermission } = usePermissions();
    const [pricingProcessing, setPricingProcessing] = useState([]);

    useEffect(() => {
        checkProcessingPricingExpiration(new Date().getTime()).then((onGoingPricingProcessing) =>
            Array.isArray(onGoingPricingProcessing) && onGoingPricingProcessing.length > 0
                ? // @ts-ignore
                  setPricingProcessing(onGoingPricingProcessing.map((item) => item.sku))
                : setPricingProcessing([]),
        );
    }, [actions]);

    const vehicles = actions || null;

    const updateCheckedList = (vehicle: Vehicle, checked: boolean) => {
        !Object.keys(vehiclesSelected).includes(vehicle.sku)
            ? (vehiclesSelected[vehicle.sku] = { ...vehicle })
            : delete vehiclesSelected[vehicle.sku];

        dispatch(stockActions.UpdateVehicleSelected(vehiclesSelected));
    };

    const headerIdColumn = () => {
        // @ts-ignore
        const vehiclesData = vehicles?.reduce(
            // @ts-ignore
            (acc, vehicle: { _data: Vehicle }) => {
                // eslint-disable-next-line no-underscore-dangle
                const vehicleData = vehicle._data;
                const identifier = vehicleData?.sku;

                if (!pricingProcessing.includes(identifier)) {
                    return {
                        ...acc,
                        [identifier]: {
                            ...vehicleData,
                        },
                    };
                }

                return acc;
            },
            [],
        );

        return (
            <>
                {!isHistory && vehiclesData && (
                    // @ts-ignore
                    <input
                        type="checkbox"
                        className="p-checkbox--small mr-3"
                        disabled={!timeRemaining?.distance && !hasPermission('STOCK_CHECK_PRICING')}
                        checked={
                            JSON.stringify(
                                Object.keys(vehiclesSelected || {})
                                    .filter(
                                        (v) =>
                                            // @ts-ignore
                                            vehiclesSelected[v] !== undefined,
                                    )
                                    .sort(),
                            ) ===
                            // @ts-ignore
                            JSON.stringify(Object.keys(vehiclesData || {}).sort())
                        }
                        onChange={(e) => {
                            dispatch(
                                stockActions.UpdateVehicleSelected(
                                    (e?.target as HTMLInputElement).checked && vehicles ? vehiclesData : [],
                                ),
                            );
                        }}
                    />
                )}
                <FormattedHTMLMessage id="STOCK_VEHICLES.ACTIONS.TABLE.VEHICLE" />
            </>
        );
    };

    const vehicleBodyTemplate = (action: { _data: Vehicle }) => {
        const vehicle = getVehicle(action);
        const identifier = vehicle?.sku;

        return (
            <div className="d-flex align-items-center">
                {!isHistory && (
                    <input
                        type="checkbox"
                        className="p-checkbox--small mr-3"
                        checked={
                            // @ts-ignore
                            vehiclesSelected?.[identifier] !== undefined && !pricingProcessing.includes(vehicle.sku)
                        }
                        disabled={
                            pricingProcessing.includes(vehicle.sku) ||
                            (!timeRemaining?.distance && !hasPermission('STOCK_CHECK_PRICING'))
                        }
                        onChange={(e) => {
                            updateCheckedList(vehicle, (e.target as HTMLInputElement).checked);
                        }}
                    />
                )}
                {getPictureTemplate(vehicle, '') && (
                    <Image className="action-todo__picture mr-2" src={getPictureTemplate(vehicle)} />
                )}
                <div>
                    <div className="text-primary">
                        <Link
                            target="_blank"
                            to={routeTo(ROUTES.STOCK_VEHICLE.PATH, {
                                sku: vehicle?.sku,
                            })}
                        >
                            <u>
                                {getBrandTemplate(vehicle)} {vehicle?.model}
                            </u>
                        </Link>
                    </div>
                    <div>{vehicle?.version}</div>
                    <div className="mt-2 mb-2 d-flex flex-wrap align-items-center">
                        <div className="mr-2 font-weight-bold">{getRegistrationTemplate(vehicle, false)}</div>
                        <Label variant="secondary" className="mr-2 text-initial font-weight-semibold">
                            {getVehicleInternalIdTemplate(vehicle)}
                        </Label>
                        <Label variant="secondary" className="mr-2 text-initial font-weight-semibold">
                            {vehicle?.police_number}
                        </Label>
                        <Label
                            className={classNames('mr-2 font-weight-semibold', {
                                [`label-${getTypeColor(vehicle?.type)}`]: true,
                            })}
                        >
                            {vehicle?.type}
                        </Label>
                    </div>
                    {!isHistory && (
                        <div className="mr-2 mb-2">
                            {isPublishAutosphere(vehicle) ? (
                                <OverlayTooltip label="STOCK_VEHICLES.TABLE.PUBLICATION_TOOLTIP" id="publication">
                                    <Label variant="success" className="font-weight-bold">
                                        <FormattedMessage id="STOCK_VEHICLES.TABLE.PUBLISHED" />
                                        &nbsp; ({getPublishDays(vehicle)}
                                        &nbsp;
                                        <FormattedMessage id="STOCK_VEHICLES.TABLE.DAYS" />)
                                    </Label>
                                </OverlayTooltip>
                            ) : (
                                <UnPublishedLabel vehicle={vehicle} />
                            )}
                            <StockPriceLeadsButton
                                key={identifier}
                                vehicleRegistration={vehicle.license_number}
                                variant="primary"
                                icon
                                label={<FormattedMessage id="STOCK.PRICING.BUTTON.LEADS" />}
                                className="ml-2"
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const stateBodyTemplate = (action: { _data: Vehicle }) => {
        const vehiclePricing = getVehicle(action).pricing;

        return (
            <div className="d-flex flex-column justify-content-between h-100">
                <div>
                    <p className="font-size-sm">
                        <FormattedMessage
                            id="STOCK.PRICING.LIST.DATA_DATE"
                            values={{
                                date: dayjs(vehiclePricing.createdAt.date).format('DD/MM/YYYY [à] HH[h]mm'),
                            }}
                        />
                    </p>
                    • <FormattedMessage id="STOCK.PRICING.TITLE.SPOT_PRICE" />
                    <strong>
                        <Price value={vehiclePricing.spotPrice} />
                    </strong>
                    <br />
                    • <FormattedMessage id="STOCK.PRICING.TITLE.AVERAGE_AGE" />
                    <strong>{vehiclePricing.averageSellingTime}</strong>
                    <br />
                    {vehiclePricing.event?.refusalStreak > 1 && page === 'check' && (
                        <>
                            • <FormattedMessage id="STOCK.PRICING.TITLE.REFUSAL_STREAK" />
                            <strong>{vehiclePricing.event.refusalStreak}</strong>
                            <br />
                        </>
                    )}
                </div>
                {vehiclePricing.radar && (
                    <a href={useRadarEncoder(vehiclePricing.radar)} target="_blank" rel="noreferrer">
                        <u>
                            <FormattedMessage id="STOCK.PRICING.TITLE.RADAR_LINK" />
                        </u>
                    </a>
                )}
            </div>
        );
    };

    const evolutionPrice = (oldPrice: number, newPrice: number) => {
        if (!oldPrice || !newPrice) {
            return null;
        }

        const percentage = parseFloat((((newPrice - oldPrice) / oldPrice) * 100).toFixed(2));
        return (
            <>
                {percentage > 0 && '+'}
                {percentage}&nbsp;%
            </>
        );
    };

    const furtherInfosBodyTemplate = (action: { _data: Vehicle }) => {
        const vehicle = getVehicle(action);
        const vehiclePricing = getVehicle(action).pricing;

        return (
            <div className="d-flex d-flex flex-column align-items-start justify-content-between h-100">
                {page === 'list' ? (
                    <div>
                        <FormattedMessage id="STOCK.PRICING.TITLE.CURRENT_PRICE" />
                        <strong>
                            <Price value={vehicle.price} />
                        </strong>
                        <br />
                        <Label variant="primary" size="label-xl">
                            <span className="mr-1">
                                <FormattedMessage id="STOCK.PRICING.TITLE.RECO_PRICE" />
                            </span>
                            <strong>
                                <Price value={vehiclePricing.recoPrice} />
                                &nbsp;
                                {vehicle.price > 0 && <>({evolutionPrice(vehicle.price, vehiclePricing.recoPrice)})</>}
                            </strong>
                        </Label>
                        <br />
                        <p className="font-size-sm mt-1">
                            <FormattedMessage
                                id="STOCK.PRICING.LIST.RECO_DATE"
                                values={{
                                    date: dayjs(vehicle.pricing.createdAt.date).format('DD/MM/YYYY [à] HH[h]mm'),
                                }}
                            />
                        </p>
                    </div>
                ) : (
                    <StockPricingToDoCheckEvent vehicle={vehicle} event={vehiclePricing.event} />
                )}
                <button
                    type="button"
                    className="text-primary btn btn-link p-0 mt-1 font-size-sm"
                    onClick={() => setVehicleItem(vehicle)}
                >
                    <u>
                        <FormattedMessage id="STOCK.PRICING.TITLE.PRICE_HISTORY_LINK" />
                    </u>
                </button>
            </div>
        );
    };

    const actionsBodyTemplate = (action: { _data: Vehicle }) => {
        const vehicle = getVehicle(action);
        const isProcessing = pricingProcessing.includes(vehicle.sku);

        return isProcessing ? (
            <div className="text-center text-primary font-weight-bold">
                <FormattedHTMLMessage id="STOCK.PRICING.ACTIONS.PROCESSING" />
            </div>
        ) : timeRemaining?.distance > 0 || hasPermission('STOCK_CHECK_PRICING') ? (
            <>
                <Button
                    className="w-100 mb-2"
                    variant="success"
                    size="sm"
                    onClick={() => {
                        dispatch(
                            modalActions.modalChange('pricing_confirm', {
                                vehicles: {
                                    [action._data.sku]: {
                                        newPrice: action._data.pricing.recoPrice,
                                    },
                                },
                                licenseNumber: action._data.license_number,
                                checkMode: page === 'check',
                            }),
                        );
                    }}
                >
                    <FormattedMessage id="STOCK.PRICING.ACTIONS.CONFIRM" />
                </Button>
                <Button
                    className="w-100 mb-2"
                    variant="primary"
                    size="sm"
                    onClick={() => {
                        dispatch(
                            modalActions.modalChange('pricing_modify', {
                                vehicles: {
                                    [action._data.sku]: {
                                        newPrice:
                                            action._data.pricing.recoPrice || action._data.pricing.event.recoPrice,
                                        price: action._data.price,
                                    },
                                },
                                licenseNumber: action._data.license_number,
                                checkMode: page === 'check',
                            }),
                        );
                    }}
                >
                    <FormattedMessage id="STOCK.PRICING.ACTIONS.MODIFY" />
                </Button>
                {page !== 'check' && (
                    <Button
                        className="w-100"
                        variant="danger"
                        size="sm"
                        onClick={() => {
                            dispatch(
                                modalActions.modalChange('pricing_cancel', {
                                    vehicles: {
                                        [action._data.sku]: {
                                            newPrice: action._data.pricing.recoPrice,
                                        },
                                    },
                                    licenseNumber: action._data.license_number,
                                }),
                            );
                        }}
                    >
                        <FormattedMessage id="STOCK.PRICING.ACTIONS.CANCEL" />
                    </Button>
                )}
            </>
        ) : (
            <div className="text-center text-danger font-weight-bold">
                <FormattedHTMLMessage id="STOCK.PRICING.COUNTDOWN.OVER.TEXT" values={{ duration }} />
            </div>
        );
    };

    const actionsCompletedBodyTemplate = (action: { _data: Vehicle }) => {
        const vehicle = getVehicle(action);
        const vehiclePricing = getVehicle(action).pricing;

        return (
            <>
                {vehiclePricing?.event && (
                    <StockPricingToDoHistoryItem vehicle={vehicle} event={vehiclePricing.event} />
                )}
                <button
                    type="button"
                    className="text-primary btn btn-link p-0 mt-1 font-size-sm"
                    onClick={(e) => setVehicleItem(vehicle)}
                >
                    <u>
                        <FormattedMessage id="STOCK.PRICING.TITLE.PRICE_HISTORY_LINK" />
                    </u>
                </button>
            </>
        );
    };

    const rowClassHandler = (action: { _data: Vehicle }) => {
        const vehicle = getVehicle(action);

        return {
            processing: !isHistory && pricingProcessing.includes(vehicle.sku),
        };
    };

    const cols = [
        {
            field: 'vehicle',
            header: headerIdColumn(),
            body: vehicleBodyTemplate,
        },
        !isHistory && {
            field: 'state',
            header: <FormattedHTMLMessage id="STOCK.PRICING.LIST.HEADINGS.INFORMATIONS" />,
            body: stateBodyTemplate,
            style: { width: '20%' },
        },
        !isHistory && {
            field: 'further_infos',
            header: <FormattedHTMLMessage id="STOCK_VEHICLES.ACTIONS.TABLE.FURTHER_INFOS" />,
            body: furtherInfosBodyTemplate,
        },
        !isHistory && {
            field: 'actions',
            header: <FormattedHTMLMessage id="STOCK_VEHICLES.ACTIONS.TABLE.ACTIONS" />,
            body: actionsBodyTemplate,
            style: { width: '10%', verticalAlign: 'middle' },
        },
        isHistory && {
            field: 'actions_completed',
            header: <FormattedHTMLMessage id="STOCK.PRICING.LIST.HEADINGS.LAST_ACTION" />,
            body: actionsCompletedBodyTemplate,
            style: { width: '65%' },
        },
    ];

    const dynamicColumns = cols.map((col) => col && <Column key={col.field} {...col} />);

    return (
        <>
            {/* @ts-ignore */}
            <HoCDataTable
                value={actions || []}
                totalRecords={total || 0}
                lazy
                loading={loading}
                paginator
                onFilter={handleFilter}
                onPage={handlePagination}
                onSort={handleSort}
                paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                rows={items}
                first={start}
                sortField={sortField}
                sortOrder={sortOrder}
                removableSort
                rowClassName={rowClassHandler}
                className="pricing pricing-actions"
                emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
            >
                {dynamicColumns}
            </HoCDataTable>
            {vehicleItem && (
                <StockPrice
                    vehicle={vehicleItem}
                    showDetails={false}
                    showTitleHistory={false}
                    asModal
                    show
                    setShowModal={setVehicleItem}
                    title={
                        <FormattedHTMLMessage
                            id="STOCK_VEHICLE.MODAL.PRICE.HISTORICAL.TITLE"
                            values={{
                                licenseNumber: vehicleItem?.license_number,
                            }}
                        />
                    }
                />
            )}
            {page !== 'history' && (
                <>
                    <ConfirmModal
                        showModal={showPricingConfirmModal}
                        setShowModal={setShowPricingConfirmModal}
                        refresh={refresh}
                    />
                    <ModifyModal
                        showModal={showPricingModifyModal}
                        setShowModal={setShowPricingModifyModal}
                        refresh={refresh}
                    />
                    <CancelModal
                        showModal={showPricingCancelModal}
                        setShowModal={setShowPricingCancelModal}
                        refresh={refresh}
                    />
                </>
            )}
        </>
    );
};

export default StockActionsTodoDatatable;
