import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import Loader from '@app/partials/content/Loader';

import StockIndicator from './helpers/StockIndicator';

export default function StockIndicators({ counter, loading, handleFilter, resetFilters }) {
    const isContextEFB = useSelector((state) => state.auth.userData.context === 'efb');

    const indicators = [
        {
            key: 'indicator_publish',
            textColor: 'black',
            bgColor: 'indicator-secondary',
            title: Intl.formatMessage({ id: 'STOCK_VEHICLES.INDICATOR.PUBLISHED_STOCK' }),
            totalStockPublished: counter?.afr_total_published_true || 0,
            totalStock: (counter?.afr_total_published_true || 0) + (counter?.afr_total_published_false || 0) || 0,
            onClick: () => {
                resetFilters(['internal_type', 'is_eligible_fw']);
            },
        },
        {
            key: 'indicator_occasions',
            textColor: 'white',
            bgColor: 'indicator-primary',
            title: Intl.formatMessage({ id: 'STOCK_VEHICLES.INDICATOR.OCCASIONS' }),
            totalStockPublished: counter?.afr_vo_published_true || 0,
            totalStock: (counter?.afr_vo_published_true || 0) + (counter?.afr_vo_not_published_true || 0) || 0,
            onClick: () => {
                handleFilter({
                    internal_type: {
                        value: 'vo,vd',
                    },
                    is_eligible_fw: {
                        value: '',
                    },
                });
            },
        },
        !isContextEFB && {
            key: 'indicator_fullweb',
            textColor: 'white',
            bgColor: 'indicator-danger',
            title: Intl.formatMessage({ id: 'STOCK_VEHICLES.INDICATOR.FULL_WEB' }),
            totalStockPublished: counter?.afr_fw_published_true || 0,
            totalStock: (counter?.afr_fw_published_true || 0) + (counter?.afr_fw_not_published_true || 0) || 0,
            onClick: () => {
                handleFilter({
                    is_eligible_fw: {
                        value: true,
                        filterName: 'Full Web',
                    },
                    internal_type: {
                        value: '',
                    },
                });
            },
        },
        {
            key: 'indicator_news',
            textColor: 'white',
            bgColor: 'indicator-warning',
            title: Intl.formatMessage({ id: 'STOCK_VEHICLES.INDICATOR.NEWS' }),
            totalStockPublished: counter?.afr_vn_published_true || 0,
            totalStock: (counter?.afr_vn_published_true || 0) + (counter?.afr_vn_not_published_true || 0) || 0,
            onClick: () => {
                handleFilter({
                    internal_type: {
                        value: 'vn',
                    },
                    is_eligible_fw: {
                        value: '',
                    },
                });
            },
        },
    ];

    return (
        <Row className="stock-indicators flex-grow-0">
            {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            {indicators.map(
                (indicator) =>
                    indicator && (
                        <Col key={indicator.key} lg={3}>
                            <StockIndicator {...indicator} />
                        </Col>
                    ),
            )}
        </Row>
    );
}
