import React from 'react';
import { Table } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';

import { Vehicle } from '@app/store/stock/stock.type';

import Price from '@app/components/formatters/price/Price';

import StockPriceLeadsButton from './StockPriceLeadsButton';

type StockPriceDetailsProps = {
    vehicle: Vehicle;
};

const StockPriceDetails = ({ vehicle }: StockPriceDetailsProps) => (
    <div className="price-handler_detail-table pb-0">
        <Table className="mb-0">
            <thead>
                <tr>
                    <th>
                        <FormattedHTMLMessage id="STOCK_VEHICLE.CARD.PRICE_HANDLER.TABLE.ACTUAL_PRICE" />
                    </th>
                    <th>
                        <FormattedHTMLMessage id="STOCK_VEHICLE.CARD.PRICE_HANDLER.TABLE.PRICE_SPOT" />
                    </th>
                    <th>
                        <FormattedHTMLMessage id="STOCK_VEHICLE.CARD.PRICE_HANDLER.TABLE.AVERAGE_AGE" />
                    </th>
                    <th>
                        <FormattedHTMLMessage id="STOCK_VEHICLE.CARD.PRICE_HANDLER.TABLE.LEADS_NUMBER" />
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="font-weight-bold">
                        <Price value={vehicle.price} />
                    </td>
                    <td className="font-weight-bold">
                        {vehicle.pricing?.spotPrice ? <Price value={vehicle.pricing?.spotPrice} /> : '-'}
                    </td>
                    <td className="font-weight-bold">
                        {vehicle.pricing?.averageSellingTime ? (
                            <>
                                {vehicle.pricing?.averageSellingTime}{' '}
                                <FormattedHTMLMessage id="STOCK_VEHICLES.TABLE.DAYS" />
                            </>
                        ) : (
                            '-'
                        )}
                    </td>
                    <td className="font-weight-bold">
                        <StockPriceLeadsButton vehicleRegistration={vehicle.license_number} variant="secondary" />
                    </td>
                </tr>
            </tbody>
        </Table>
    </div>
);

export default StockPriceDetails;
