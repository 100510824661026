import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import useFetch from '@app/hooks/useFetch';

import { routeTo } from '@app/helpers/RoutesHelper';

import {
    getDashboardAllOrdersRasc,
    getDashboardAllOrdersSr,
    getDashboardPerformancesApv,
    getOrdersCounter,
} from '@app/crud/dashboard/dashboard.crud';

import { useAppSelector } from '@app/hooks';
import ROUTES from '@app/router/Routes';

import { DashboardContext } from '../../DashboardProvider';

const DashboardStatApv = () => {
    const { overriddenParams } = useContext(DashboardContext);
    const { userData } = useAppSelector((state) => state.auth);
    const roleWithSellerFilter = ['manager-autosphere-contact', 'responsable-apv', 'conseiller-commercial-services'];

    const { data, fetch } = useFetch({
        fetchAction: getDashboardPerformancesApv,
    });

    const { data: allOrdersData, fetch: fetchAllOrdersData } = useFetch({
        fetchAction: getDashboardAllOrdersSr,
    });

    const { data: allOrdersRascData, fetch: fetchAllOrdersRascData } = useFetch({
        fetchAction: getDashboardAllOrdersRasc,
    });

    const { data: allOrdersApvData, fetch: fetchAllOrdersApvData } = useFetch({
        fetchAction: getOrdersCounter,
    });

    useEffect(() => {
        if (overriddenParams) {
            const { dealershipId } = overriddenParams;
            const params = {
                ...overriddenParams,
                currentPeriod: overriddenParams?.currentPeriod ?? 'currentMonth',
            };

            fetch(params);
            fetchAllOrdersData({
                ...params,
                orderAppointmentType: 'in_progress',
            });
            fetchAllOrdersRascData({
                ...params,
                orderAppointmentType: '!null',
                activeUserFilter: roleWithSellerFilter.includes(userData?.role?.slug),
            });
            fetchAllOrdersApvData({
                dealershipIds: dealershipId,
                currentPeriod: overriddenParams?.currentPeriod ?? 'currentMonth',
            });
        }
    }, [fetch, fetchAllOrdersData, fetchAllOrdersRascData, fetchAllOrdersApvData, overriddenParams]);

    return (
        <div className="dashboard-general-perf dashboard-stat-apv">
            <div className="dashboard-general-perf d-flex flex-column mb-9">
                <div className="font-size-lg font-weight-bold d-flex flex-wrap align-items-center justify-content-between">
                    <div className="d-flex flex-wrap align-items-center">
                        <i className="dashboard-general-perf__icon las la-2x la-chart-line mr-2" />
                        <span className="mr-4">
                            <FormattedHTMLMessage id="DASHBOARD.GENERAL_APV.TITLE" />
                        </span>
                    </div>
                </div>
                <div className="dashboard-general-perf__groups align-items-center flex-grow-1">
                    <div className="dashboard-general-perf__leads-apv flex-grow-1">
                        <div className="d-flex align-items-center">
                            <i className="las la-2x la-chart-bar dashboard-stat-apv--number-lead-default" />
                            <p className="dashboard-general-perf__state ml-2 line-height-sm text-nowrap m-0 ml-9">
                                <FormattedHTMLMessage id="DASHBOARD.TOTAL" />
                                <span className="dashboard-stat-apv--number-lead">{data.total || '0'}</span>
                            </p>
                        </div>
                        <Link
                            className="d-flex align-items-center text-initial"
                            to={{
                                pathname: routeTo(ROUTES.APV_LEADS.PATH, {
                                    statusType: 'all',
                                }),
                            }}
                        >
                            <i className="las la-2x la-bullseye dashboard-stat-apv--number-lead-default" />
                            <p className="dashboard-general-perf__state ml-2 line-height-sm text-nowrap m-0 ml-9">
                                <FormattedHTMLMessage id="DASHBOARD.IN.PROGRESS.TOTAL" />
                                <span className="dashboard-stat-apv--number-lead">{data.inProgress || '0'}</span>
                            </p>
                        </Link>
                        <div className="d-flex align-items-center">
                            <i className="las la-2x la-hand-pointer text-light-danger" />
                            <p className="dashboard-general-perf__state ml-2 line-height-sm text-nowrap m-0 ml-9">
                                <FormattedHTMLMessage id="DASHBOARD.NEW.TOTAL" />
                                <span className="dashboard-stat-apv--number-lead text-danger">{data.new || '0'}</span>
                            </p>
                        </div>
                        <div className="d-flex align-items-center">
                            <i className="las la-2x la-lock dashboard-stat-apv--number-lead-default" />
                            <p className="dashboard-general-perf__state ml-2 line-height-sm text-nowrap m-0 ml-9">
                                <FormattedHTMLMessage id="DASHBOARD.CLOSED" />
                                <span className="dashboard-stat-apv--number-lead dashboard-stat-apv--number-lead-color">
                                    {data.done || '0'}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dashboard-stat-apv__container">
                <div className="dashboard-general-perf dashboard-stat-apv--border d-flex justify-content-between flex-column h-100">
                    <div className="d-flex flex-wrap align-items-center">
                        <i className="dashboard-general-perf__icon las la-2x la-mouse-pointer mr-2" />
                        <span className="mr-4">
                            <FormattedHTMLMessage id="DASHBOARD.ORDER.AFR.WORKSHOP" />
                        </span>
                    </div>
                    <p className="dashboard-stat-apv__number">{allOrdersApvData > 0 ? allOrdersApvData : '0'}</p>
                </div>
                <div className="dashboard-general-perf dashboard-stat-apv--border d-flex justify-content-between flex-column h-100">
                    <div className="d-flex flex-wrap align-items-center">
                        <i className="dashboard-general-perf__icon las la-2x la-shopping-cart mr-2" />
                        <span className="mr-4">
                            <FormattedHTMLMessage id="DASHBOARD.ORDER.DEALERSHIP.APPOINTMENT.WORKSHOP" />
                        </span>
                    </div>
                    <p className="dashboard-stat-apv__number">{allOrdersRascData > 0 ? allOrdersRascData : '0'}</p>
                </div>
                <div className="dashboard-general-perf  dashboard-stat-apv--border d-flex justify-content-between flex-column h-100">
                    <div className="d-flex flex-wrap align-items-center">
                        <i className="dashboard-general-perf__icon las la-2x la-gavel mr-2" />
                        <span className="mr-4">
                            <FormattedHTMLMessage id="DASHBOARD.ORDER.SMART.REPAIR" />
                        </span>
                    </div>
                    <p className="dashboard-stat-apv__number">{allOrdersData > 0 ? allOrdersData : '0'}</p>
                </div>
            </div>
        </div>
    );
};

DashboardStatApv.Skeleton = () => (
    <Skeleton borderRadius="10px" className="d-flex flex-column flex-lg-grow-1 mt-lg-2 mt-6 p-15" />
);

export default DashboardStatApv;
