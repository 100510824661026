import dayjs from 'dayjs';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { getPricingReasonsByStatusSlug, refuseVehiclePriceRecommendation } from '@app/crud/stock/stock.crud';

import { useAppSelector, useFetch } from '@app/hooks';
import { actions } from '@app/store/stock/stock.store';
import { Vehicle } from '@app/store/stock/stock.type';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import { getPublishDays } from '@app/partials/content/Vehicle/VehicleHelpers';
import ErrorForm from '@app/partials/layout/ErrorForm';

import Price from '@app/components/formatters/price/Price';

import StockPriceLeadsButton from '../../partials/price/StockPriceLeadsButton';
import { addProcessingPricing } from '../todo/helpers/ProcessingPricing';

type CancelModalProps = {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    refresh: () => void;
};

const CancelModal = ({ showModal, setShowModal, refresh }: CancelModalProps) => {
    const Intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [internalReason, setInternalReason] = useState(null);
    const [internalComment, setInternalComment] = useState('');
    const [reasons, setReasons] = useState(null);
    const commentRequiredLength = 10;
    const { params } = useAppSelector((state) => state.modal);
    const vehicles = params?.vehicles;
    const multiple = vehicles && Object.keys(vehicles).length > 1;
    const dispatch = useDispatch();
    const licenseNumber = params?.licenseNumber;
    const { vehiclesSelected } = useAppSelector((state) => state.stock);

    const { control, register, handleSubmit, errors, watch } = useForm({
        shouldUnregister: true,
    });

    useFieldArray({
        control,
        name: 'pricing',
    });

    const {
        fetch: fetchReasons,
        data,
        loading: reasonsLoading,
    } = useFetch({
        fetchAction: getPricingReasonsByStatusSlug,
    });

    const closeModal = () => {
        setShowModal(false);
    };

    const onConfirm = (data: any) => {
        setLoading(true);
        refuseVehiclePriceRecommendation(data.pricing)
            .then(() => {
                if (refresh) refresh();
                dispatch(actions.UpdateVehicleSelected([]));
                addProcessingPricing(Object.keys(vehicles));
                setShowModal(false);
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.CONFIRMED' }),
                });
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'STOCK_VEHICLES.MODAL.PRICE.CONFIRM.REASON.FAILED' }),
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        setInternalReason(null);
        setInternalComment('');
    }, [showModal]);

    useEffect(() => {
        fetchReasons('refused');
    }, [fetchReasons]);

    useEffect(() => {
        if (data) {
            setReasons(
                // @ts-ignore
                data.map((item: any) => ({
                    label: item.id,
                    value: item.description,
                })),
            );
        }
    }, [data]);

    const rowExpansionTemplate = (col: any) => {
        return (
            <div className="d-flex" id={col.sku}>
                <Form.Group className="mt-2 pr-5" style={{ width: '40%' }}>
                    <Form.Label>
                        <FormattedMessage id="STOCK.PRICING.HISTORY.REASON" />
                    </Form.Label>
                    <Form.Control
                        as="select"
                        defaultValue={-1}
                        name={`pricing[${col.sku}].reasonId`}
                        ref={register({
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            validate: (value) =>
                                value !== '-1' ? true : Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                        })}
                    >
                        <option value={-1} disabled>
                            {Intl.formatMessage({ id: 'STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.PLACEHOLDER' })}
                        </option>
                        {reasons?.map((reason: any) => (
                            <option key={`reason_${reason.label}`} value={reason.label}>
                                {reason.value}
                            </option>
                        ))}
                    </Form.Control>
                    <ErrorForm errors={errors} name={`pricing[${col.sku}].reasonId`} />
                </Form.Group>
                <Form.Group className="mt-2 pl-5" style={{ width: '60%' }}>
                    <Form.Label>
                        <FormattedMessage id="TRANSLATOR.COMMENT" />
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        name={`pricing[${col.sku}].comment`}
                        defaultValue=""
                        minLength={commentRequiredLength}
                        // @ts-ignore
                        ref={register({
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            validate: (value) =>
                                value.length >= commentRequiredLength ? (
                                    true
                                ) : (
                                    <FormattedMessage id="STOCK.PRICING.MODAL.MODIFY.MULTIPLE.COMMENT_TOO_SHORT" />
                                ),
                        })}
                    />
                    <ErrorForm errors={errors} name={`pricing[${col.sku}].comment`} />
                    <p className="mt-1 mb-0">
                        <FormattedHTMLMessage
                            id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.PRECISE.REQUIRED"
                            values={{
                                caracters: Number(watch(`pricing[${col.sku}].comment`)?.length) || 0,
                                requiredLength: commentRequiredLength,
                                color:
                                    watch(`pricing[${col.sku}].comment`)?.length >= commentRequiredLength
                                        ? 'success'
                                        : 'danger',
                            }}
                        />
                    </p>
                </Form.Group>
            </div>
        );
    };

    const cols = [
        {
            field: 'vehicle',
            header: <FormattedMessage id="STOCK.PRICING.MODAL.TABLE.HEADER.VEHICLE" />,
            body: (vehicle: Vehicle) => (
                <>
                    <strong>{vehicle.license_number}</strong>
                    <br />
                    {vehicle.brand_model}
                </>
            ),
        },
        {
            field: 'price',
            header: <FormattedMessage id="STOCK.PRICING.MODAL.TABLE.HEADER.PRICE" />,
            body: (vehicle: Vehicle) => (
                <strong>
                    <Price value={vehicle.price} />
                </strong>
            ),
        },
        {
            field: 'days_in_stock',
            header: <FormattedHTMLMessage id="STOCK.PRICING.MODAL.TABLE.HEADER.DAYS_IN_STOCK" />,
            body: (vehicle: Vehicle) => (
                <>
                    {dayjs().diff(dayjs(vehicle.date_in_stock).format('YYYY-MM-DD'), 'day')}{' '}
                    <FormattedMessage id="TRANSLATOR.DAYS" />
                </>
            ),
        },
        {
            field: 'days_published',
            header: <FormattedHTMLMessage id="STOCK.PRICING.MODAL.TABLE.HEADER.DAYS_PUBLISHED" />,
            body: (vehicle: Vehicle) => (
                <>
                    {getPublishDays(vehicle)} <FormattedMessage id="TRANSLATOR.DAYS" />
                </>
            ),
        },
        {
            field: 'leads',
            header: <FormattedHTMLMessage id="STOCK.PRICING.MODAL.TABLE.HEADER.LEADS" />,
            body: (vehicle: Vehicle) => (
                <StockPriceLeadsButton
                    vehicleRegistration={vehicle.license_number}
                    variant="primary"
                    icon
                    label={<FormattedMessage id="STOCK.PRICING.BUTTON.LEADS" />}
                    className="ml-2"
                />
            ),
        },
        {
            field: 'spot_price',
            header: <FormattedMessage id="STOCK.PRICING.MODAL.TABLE.HEADER.SPOT_PRICE" />,
            body: (vehicle: Vehicle) => (
                <Price value={vehicle.pricing.spotPrice} thousandSeparator=" " displayType="text" suffix=" €" />
            ),
        },
        {
            field: 'average_age',
            header: <FormattedHTMLMessage id="STOCK.PRICING.MODAL.TABLE.HEADER.AVERAGE_AGE" />,
            body: (vehicle: Vehicle) => (
                <>
                    {vehicle.pricing.averageSellingTime} <FormattedMessage id="TRANSLATOR.DAYS" />
                </>
            ),
        },
        {
            field: 'reco_price',
            header: <FormattedHTMLMessage id="STOCK.PRICING.MODAL.TABLE.HEADER.RECO_PRICE" />,
            body: (vehicle: Vehicle) => (
                <strong className="text-primary">
                    <Price value={vehicle.pricing.recoPrice} thousandSeparator=" " displayType="text" suffix=" €" />
                </strong>
            ),
        },
        {
            field: 'delete',
            header: '',
            // @ts-ignore
            body: (vehicle: Vehicle) => <i className="las la-times" onClick={() => deleteRow(vehicle.sku)} />,
        },
    ];

    const deleteRow = async (sku: string) => {
        await dispatch(actions.DeleteVehicleSelectedItem(sku));

        Object.values(vehiclesSelected).filter((item: any) => item.newPrice !== undefined);
    };

    const dynamicColumns = cols.map((col) => col && <Column key={col.field} {...col} />);

    return (
        <>
            {vehicles && (
                <ModalDefault
                    show={showModal}
                    loading={loading || reasonsLoading}
                    hideModal={() => closeModal()}
                    className={`${multiple && 'modal--fullwidth'}`}
                    icon={
                        multiple ? (
                            <i className="text-danger las la-money-bill-wave fa-2x" />
                        ) : (
                            <i className="text-danger las la-times-circle fa-2x" />
                        )
                    }
                    title={
                        <span className="text-danger">
                            {multiple ? (
                                <FormattedHTMLMessage id="STOCK.PRICING.MODAL.CANCEL.MULTIPLE.TITLE" />
                            ) : (
                                <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.TITLE" />
                            )}
                            {licenseNumber && (
                                <>
                                    <br />
                                    <strong>{licenseNumber}</strong>
                                </>
                            )}
                        </span>
                    }
                    body={
                        multiple ? (
                            <div className="pricing d-flex flex-column align-items-center h-100">
                                <p className="text-center">
                                    <FormattedHTMLMessage
                                        id="STOCK.PRICING.MODAL.CANCEL.MULTIPLE.TEXT"
                                        values={{ vehicles: Object.keys(vehiclesSelected).length }}
                                    />
                                </p>
                                {vehiclesSelected && (
                                    // @ts-ignore
                                    <DataTable
                                        value={Object.values(vehiclesSelected)}
                                        expandedRows={Object.values(vehiclesSelected)}
                                        // @ts-ignore
                                        rowExpansionTemplate={rowExpansionTemplate}
                                        scrollable
                                        scrollHeight="55vh"
                                        className="w-100"
                                    >
                                        {dynamicColumns}
                                    </DataTable>
                                )}
                            </div>
                        ) : (
                            <div className="d-flex flex-column align-items-center h-100" style={{ minHeight: '10vh' }}>
                                <Form.Group className="mt-2 w-100">
                                    <Form.Label>
                                        <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.TITLE" />
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        name={`pricing[${Object.keys(vehicles)[0]}].reasonId`}
                                        ref={register}
                                        defaultValue={-1}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setInternalReason(Number(e.target.value));
                                        }}
                                    >
                                        <option value={-1} disabled>
                                            {Intl.formatMessage({
                                                id: 'STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.PLACEHOLDER',
                                            })}
                                        </option>
                                        {reasons &&
                                            reasons.map((reason: { label: string; value: string }, key: number) => (
                                                <option key={key} value={reason.label}>
                                                    {reason.value}
                                                </option>
                                            ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="mt-2 w-100">
                                    <Form.Label>
                                        <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.PRECISE" />
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name={`pricing[${Object.keys(vehicles)[0]}].comment`}
                                        ref={register}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setInternalComment(e.target.value);
                                        }}
                                    />
                                    <p className="mt-1 mb-0">
                                        <FormattedHTMLMessage
                                            id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.PRECISE.REQUIRED"
                                            values={{
                                                caracters: internalComment.trim().length,
                                                requiredLength: commentRequiredLength,
                                                color:
                                                    internalComment.trim().length >= commentRequiredLength
                                                        ? 'success'
                                                        : 'danger',
                                            }}
                                        />
                                    </p>
                                </Form.Group>
                            </div>
                        )
                    }
                    footer={
                        <div className="d-flex justify-content-center">
                            <Button className="mr-2" variant="outline-primary" onClick={() => closeModal()}>
                                <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.CANCEL" />
                            </Button>
                            <Button
                                variant="primary"
                                onClick={handleSubmit(onConfirm)}
                                disabled={
                                    !multiple &&
                                    (!internalReason ||
                                        loading ||
                                        internalComment.trim().length < commentRequiredLength)
                                }
                            >
                                <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.CONFIRM" />
                            </Button>
                        </div>
                    }
                />
            )}
        </>
    );
};

export default CancelModal;
