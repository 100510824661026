import { DevTool } from '@hookform/devtools';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { createReceptionistLead, createReceptionistNote, OutStockVehicle } from '@app/crud/customers/customer.crud';

import { useAppSelector } from '@app/hooks';
import { fetchCustomer, refreshLeads } from '@app/store/customers/customers.thunk';
import { Vehicle } from '@app/store/stock/stock.type';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import ModalStepper, { ModalStepperType } from '@app/partials/content/modals/ModalStepper';
import toast from '@app/partials/content/Toast';

import { CommerceRequestReason, CommerceRequestTransfer, CommerceRequestVehicle } from './partials';

type CommerceRequestProps = {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
};

export interface SubmittedProps {
    default_dealership: string;
    lead_type: string;
    type: string;
    team: string;
    customerProject: string;
    dealership: string;
    appointment: Array<{
        subject: string;
        dateStart: string;
        dateEnd: string;
        comment: string;
        notifyCustomer: boolean;
    }>;
    vehicleType: string;
    vehicles?: Array<Vehicle | OutStockVehicle>;
    referent?:
        | {
              id: string | number;
              first_name: string;
              last_name: string;
          }
        | string;
}

const CommerceRequest = ({ showModal, setShowModal }: CommerceRequestProps) => {
    const Intl = useIntl();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [data, setData] = useState<Partial<SubmittedProps>>({});
    const { customer } = useAppSelector((state) => state.customer);

    const methods = useForm();
    const { control, handleSubmit, reset } = methods;

    const defaultStepperState = {
        currentStep: 0,
        steps: [
            {
                name: 'Motifs',
                status: 'pending',
                content: <CommerceRequestReason data={data} />,
            },
            {
                name: 'Véhicule',
                status: 'pending',
                content: <CommerceRequestVehicle data={data} />,
            },
            {
                name: 'Transfert',
                status: 'pending',
                content: <CommerceRequestTransfer data={data} />,
            },
        ],
    };
    const [stepperState, setStepperState] = useState<ModalStepperType>(defaultStepperState);

    const formatVehicleData = (vehicles: Array<Vehicle | OutStockVehicle>) =>
        vehicles.map((vehicle) => {
            if ((vehicle as Vehicle).sku) {
                return {
                    sku: (vehicle as Vehicle).sku,
                };
            }
            return {
                make: (vehicle as OutStockVehicle).make,
                model: (vehicle as OutStockVehicle).model,
                fuelType: (vehicle as OutStockVehicle).fuelType || null,
                gearsType: (vehicle as OutStockVehicle).gearsType || null,
            };
        });

    const onSubmit = (submittedData: SubmittedProps) => {
        if (data.type === 'buying_project') {
            setLoading(true);
            createReceptionistLead({
                contact: customer.data.id,
                leadType: submittedData.team,
                customerProject: submittedData.customerProject,
                dealership: submittedData.dealership,
                autouserSource: Number(submittedData.referent) || null,
                vehicles: data.vehicles?.length ? formatVehicleData(data.vehicles) : null,
                appointment: (submittedData.appointment?.length && submittedData.appointment[0]) || null,
            })
                .then(() => {
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE_LOCAL_COMMERCE.SUCCESS' }),
                    });

                    dispatch(refreshLeads(customer.data.id.toString(), 'local_commerce'));

                    setShowModal(false);
                })
                .catch()
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(true);
            createReceptionistNote({
                contactId: customer.data.id,
                type: 'sales',
                message: submittedData.customerProject,
                noteTypeSlug: data.type,
                dealershipSource: submittedData.dealership,
                referentId: Number(submittedData.referent),
                team: submittedData.team,
            })
                .then(() => {
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE_LOCAL_COMMERCE_NOTE.SUCCESS' }),
                    });

                    dispatch(fetchCustomer({ id: customer.data.id.toString() }));
                })
                .catch()
                .finally(() => {
                    setData({});
                    setStepperState(defaultStepperState);
                    setLoading(false);
                    setShowModal(false);
                });
        }
    };

    const updateData = (stepData: Partial<SubmittedProps>) => {
        setData((stateData) => ({
            ...stateData,
            ...stepData,
        }));
        setStepperState((state) => {
            const newStepperState = { ...state };
            newStepperState.steps[state.currentStep].status = 'valid';
            do {
                newStepperState.currentStep += 1;
            } while (
                newStepperState.steps[newStepperState.currentStep] &&
                newStepperState.steps[newStepperState.currentStep].status === 'disabled'
            );
            return newStepperState;
        });
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const incrementStepUntilEnabled = (state: ModalStepperType, direction: 'forward' | 'backward') => {
        const stepChange = direction === 'forward' ? 1 : -1;
        do {
            state.currentStep += stepChange;
        } while (state.steps[state.currentStep] && state.steps[state.currentStep].status === 'disabled');
        return state.currentStep;
    };

    const resetModalAndClose = () => {
        setData({});
        reset();
        setStepperState(defaultStepperState);
        setLoading(false);
        setShowModal(false);
    };

    const getModalTitle = () => {
        if (!data.type || data.type === 'buying_project') {
            return Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE_LOCAL_COMMERCE.TITLE' });
        }
        return Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE_LOCAL_COMMERCE_NOTE.TITLE' });
    };

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => resetModalAndClose()}
            icon={<i className="la la-2x text-primary la-car" />}
            title={getModalTitle()}
            body={
                <>
                    <ModalStepper state={stepperState} navigate={false} />
                    <FormProvider {...methods}>
                        {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
                        {stepperState.currentStep === 0 && (
                            <CommerceRequestReason data={data} setStepperState={setStepperState} />
                        )}
                        {stepperState.currentStep === 1 && <CommerceRequestVehicle data={data} />}
                        {stepperState.currentStep === 2 && <CommerceRequestTransfer data={data} />}
                    </FormProvider>
                </>
            }
            footer={
                <div className="d-flex justify-content-between">
                    {stepperState.currentStep > 0 ? (
                        <Button
                            variant="outline-primary"
                            onClick={() => {
                                setStepperState((state) => {
                                    const newStepperState = { ...state };
                                    newStepperState.currentStep = incrementStepUntilEnabled(stepperState, 'backward');
                                    return newStepperState;
                                });
                            }}
                            className="mr-5"
                        >
                            <FormattedMessage id="TRANSLATOR.PREVIOUS" />
                        </Button>
                    ) : (
                        <Button variant="outline-primary" onClick={() => setShowModal(false)} className="mr-5">
                            <FormattedMessage id="TRANSLATOR.CANCEL" />
                        </Button>
                    )}
                    {stepperState.currentStep < stepperState.steps.length - 1 ? (
                        <Button variant="outline-primary" onClick={handleSubmit(updateData, onError)}>
                            <FormattedMessage id="TRANSLATOR.NEXT" />
                        </Button>
                    ) : (
                        <Button variant="primary" onClick={handleSubmit(onSubmit, onError)}>
                            <FormattedMessage id="TRANSLATOR.VALIDATE" />
                        </Button>
                    )}
                </div>
            }
        />
    );
};

export default CommerceRequest;
