import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { User } from '@app/store/login/login.store';

const { REACT_APP_AUTOUSER_URL } = process.env;

type UserCreationData = {
    email: string;
};

export async function addUser(data: UserCreationData) {
    return ApiGateway.post(`${REACT_APP_AUTOUSER_URL}/admin/users`, {
        ...data,
        active: true,
        external: true,
    });
}

export function associateUser(username: string, params = {}) {
    return ApiGateway.post(`${REACT_APP_AUTOUSER_URL}/admin/users`, {
        email: username,
        external: false,
        active: true,
        ...params,
    });
}

export function deleteUser(userId: number) {
    return ApiGateway.delete(`${REACT_APP_AUTOUSER_URL}/admin/users/${userId}`);
}

type GetUsersParams = {
    firtName?: string;
    lastName?: string;
    email?: string;
    research?: string;
    callTransfer?: boolean;
    role?: number;
    outlookPlanning?: boolean;
    leadAlert?: boolean;
    tags?: string;
    dealershipCode?: string;
    active?: boolean;
    roles?: string;
    permissions?: boolean;
};

interface UsersResponse<T> {
    count: number;
    result: T;
}

export function getUsers(params: GetUsersParams) {
    return ApiGateway.get<User[], UsersResponse<User[]>>(`${REACT_APP_AUTOUSER_URL}/admin/users`, {
        params,
    });
}

type GetUsersFromAdParams = {
    q: string;
};

export function getUsersFromAd(params: GetUsersFromAdParams) {
    return ApiGateway.get<User[], UsersResponse<User[]>>(`${REACT_APP_AUTOUSER_URL}/admin/users/search`, {
        params,
    });
}

export function getUser(id: number) {
    return ApiGateway.get(`${REACT_APP_AUTOUSER_URL}/admin/users/${id}`);
}

export enum UserPartnerAttributeSlug {
    SALESFORCE_BMW_ID = 'salesforce_bmw_id',
}

export interface UpdateUserPartnerParams {
    user_id: number;
    attributes: {
        [key in UserPartnerAttributeSlug]: string;
    };
}

export function getUserPartner(id: number) {
    return ApiGateway.get<
        UpdateUserPartnerParams['attributes'],
        CustomAxiosResponse<UpdateUserPartnerParams['attributes']>
    >(`${REACT_APP_AUTOUSER_URL}/admin/users/partner-attribute-value/${id}`);
}

export function updateUserPartner(user_id: number, attributes: UpdateUserPartnerParams['attributes']) {
    return ApiGateway.post<UpdateUserPartnerParams, UpdateUserPartnerParams>(
        `${REACT_APP_AUTOUSER_URL}/admin/users/partner-attribute-value`,
        {
            user_id,
            attributes,
        },
    );
}

export interface UpdateUserParams {
    last_name: string;
    first_name: string;
    email: string;
    cell_phone: undefined;
    phone: undefined;
    job: undefined;
}

export function updateUser(id: number, data: UpdateUserParams) {
    return ApiGateway.patch(`${REACT_APP_AUTOUSER_URL}/admin/users/${id}`, data);
}

export function updatePassword(userId: number, password: string) {
    return ApiGateway.post(`${REACT_APP_AUTOUSER_URL}/admin/users/${userId}/change-password`, {
        password,
    });
}

export function addUserConnection() {
    return ApiGateway.post(`${REACT_APP_AUTOUSER_URL}/user/connect`);
}

export function getAllPerimeter(user_id: number) {
    return ApiGateway.get(
        `${REACT_APP_AUTOUSER_URL}/admin/users/perimeter${user_id ? `/${user_id}` : ''}?showInactiveDealerships=0`,
    );
}

export interface PerimeterData {
    id: string;
    title: string;
    codeType: number;
    type: string;
    codePvo?: string;
    children?: Record<string, PerimeterData>;
}

export interface Perimeter {
    [key: string]: PerimeterData;
}

export function getUserPerimeter() {
    return ApiGateway.get(`${REACT_APP_AUTOUSER_URL}/users/perimeter?showInactiveDealerships=0`);
}

export function getDealershipVendors(refDealershipId: number | string) {
    return ApiGateway.get<User[], UsersResponse<User[]>>(`${REACT_APP_AUTOUSER_URL}/vendors/${refDealershipId}`);
}

interface AuthorizeResponse {
    url: string;
}

export const authorize = (context: string) =>
    ApiGateway.get<AuthorizeResponse, AuthorizeResponse>(`${REACT_APP_AUTOUSER_URL}/adlogin/url?context=${context}`);

interface DealershipPerimeter {
    code: string;
    name: string;
    type: string;
}

export interface UserReturn {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    dealership_perimeter: DealershipPerimeter[];
    perimeter_codes: string[];
}

interface DealershipReturn {
    [key: string]: UserReturn[];
}

export const vendorsSearch = (param: {
    dealerships: string;
    scope: string | boolean;
    roles?: string;
    withHierarchy?: boolean;
}) =>
    ApiGateway.post<ApiResponse<DealershipReturn>, CustomAxiosResponse<ApiResponse<DealershipReturn>>>(
        `${REACT_APP_AUTOUSER_URL}/vendors/search`,
        param,
    );
