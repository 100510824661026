import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import Permission from '@app/partials/content/Permission';

import StockVehicleCRVO from './StockVehicleCRVO';
import StockVehicleDealership from './StockVehicleDealership';
import StockVehicleEligibilityPublication from './StockVehicleEligibilityPublication';
import StockVehicleInfo from './StockVehicleInfo';
import StockVehiclePriceHandler from './StockVehiclePriceHandler';
import StockVehicleStatistic from './StockVehicleStatistic';
import StockVehicleStockTimeLine from './StockVehicleStockTimeLine';
import StockVehicleTrade from './StockVehicleTrade';

const StockVehicleVO = ({ vehicle, history, dispatch }) => {
    const isContextEFB = useSelector((state) => state.auth.userData.context === 'efb');

    return (
        <>
            <Row>
                <Col lg={4}>
                    <StockVehicleInfo vehicle={vehicle} />
                </Col>
                <Col lg={3}>
                    <Row className="h-100">
                        <Col lg={12}>
                            <StockVehicleDealership vehicle={vehicle} />
                        </Col>
                        <Col className="mt-8" lg={12}>
                            <StockVehicleStatistic vehicle={vehicle} />
                        </Col>
                    </Row>
                </Col>
                <Col lg={5}>
                    <StockVehiclePriceHandler vehicle={vehicle} history={history} dispatch={dispatch} />
                </Col>
            </Row>
            {((isContextEFB && vehicle?.timeline?.events) || !isContextEFB) && (
                <Row className="mt-8">
                    <Col lg={12}>
                        <StockVehicleStockTimeLine vehicle={vehicle} />
                    </Col>
                </Row>
            )}
            {vehicle?.is_crvo && (
                <Permission permissions={['STOCK_VIEW_CRVO']}>
                    <Row className="mt-8">
                        <Col lg={12}>
                            <StockVehicleCRVO vehicle={vehicle} />
                        </Col>
                    </Row>
                </Permission>
            )}
            <Row className="mt-8">
                {!isContextEFB && (
                    <Col lg={9}>
                        <StockVehicleEligibilityPublication vehicle={vehicle} />
                    </Col>
                )}
                <Col lg={isContextEFB ? 12 : 3}>
                    <StockVehicleTrade vehicle={vehicle} isVN={false} />
                </Col>
            </Row>
        </>
    );
};

export default StockVehicleVO;
