import dayjs from 'dayjs';
import { Chips } from 'primereact/chips';
import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { UserReturn, vendorsSearch } from '@app/crud/autouser/user.crud';

import { useAppSelector } from '@app/hooks';
import Appointment from '@app/pages/customers/Modals/Appointment';
import DealershipSelect from '@app/pages/customers/Partials/DealershipSelect';

import Loader from '@app/partials/content/Loader';
import ErrorForm from '@app/partials/layout/ErrorForm';

import { SubmittedProps } from '../CommerceRequest';

type CommerceRequestTransferProps = {
    data: Partial<SubmittedProps>;
};

const teams = {
    local_vo: 'Local VO',
    local_vn: 'Local VN',
    local_vd: 'Local VD',
};

const CommerceRequestTransfer = ({ data }: CommerceRequestTransferProps) => {
    const Intl = useIntl();
    const vehicleType =
        data.vehicles?.length > 0 && 'internal_type' in data.vehicles[0] ? data.vehicles[0].internal_type : null;
    const [appointmentModalOpen, setAppointmentModalOpen] = useState(false);
    const [team, setTeam] = useState<string>(`local_${vehicleType || 'vo'}`);
    const [appointment, setAppointment] = useState(null);
    const [loading, setLoading] = useState(false);

    const methods = useFormContext();
    const { errors, register, watch, setValue } = methods;
    const { data: customer } = useAppSelector((state) => state.customer.customer);

    const [vendors, setVendors] = useState<Array<UserReturn>>(null);
    const dealership = watch('dealership') as string;
    const appointmentDisplay = watch('appointment');
    register('appointment');

    useEffect(() => {
        if (!team || !dealership) return;
        setLoading(true);

        vendorsSearch({
            dealerships: dealership,
            scope: team,
            roles: data.type === 'suivi-post-livraison-administratif' ? 'secretaire' : 'conseiller-commercial,manager',
        })
            .then((res) => {
                setVendors(res.result[dealership.toUpperCase()]);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [dealership, team]);

    const formatAppointment = (newAppointment: {
        subject: string;
        dateStart: string;
        dateEnd: string;
        comment: string;
        dealer: number;
    }): void => {
        if (!newAppointment) return null;
        setValue('appointment', [
            {
                subject: newAppointment.subject,
                dateStart: dayjs(newAppointment.dateStart).format('YYYY-MM-DD HH:mm:ss'),
                dateEnd: dayjs(newAppointment.dateEnd).add(1, 'hour').format('YYYY-MM-DD HH:mm:ss'),
                comment: newAppointment.comment,
                notifyCustomer: true,
            },
        ]);
        setValue('referent', newAppointment.dealer);
    };

    // only used to handle Chips display
    useEffect(() => {
        if (appointmentDisplay) setAppointment([appointmentDisplay[0]?.dateStart]);
    }, [appointmentDisplay]);

    return (
        <>
            {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <Form.Group as={Col} md={12}>
                <DealershipSelect methods={methods} customer={customer} forceDisplay />
                <ErrorForm errors={errors} name="dealership" />
            </Form.Group>
            <Form.Group as={Col} md={12}>
                <Form.Label>
                    <FormattedMessage id="CUSTOMERS.MODAL.LOCAL_COMMERCE.TRANSFER.TEAM.LABEL" />
                </Form.Label>
                <Form.Control
                    as="select"
                    name="team"
                    defaultValue={`local_${vehicleType || 'vo'}`}
                    readOnly={!!vehicleType}
                    className={`${!!vehicleType && 'bg-disabled'}`}
                    ref={register({
                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    })}
                    onChange={(e) => setTeam(e.target.value)}
                >
                    {Object.entries(teams).map(([key, value]) => (
                        <option value={key}>{value}</option>
                    ))}
                </Form.Control>
                <ErrorForm errors={errors} name="team" />
            </Form.Group>
            {data.type === 'buying_project' && (
                <Form.Group as={Col} md={12}>
                    <Form.Label>
                        <FormattedMessage id="CUSTOMERS.MODAL.LOCAL_COMMERCE.TRANSFER.APPOINTMENT.LABEL" />
                    </Form.Label>
                    <InputGroup className="d-flex flex-nowrap">
                        <Chips
                            value={appointment}
                            disabled={!vendors?.length}
                            onRemove={() => {
                                setValue('appointment', null);
                                setAppointment(null);
                            }}
                            style={{ color: '#FFFFFF' }}
                        />
                        <InputGroup.Append>
                            <InputGroup.Text>
                                <button
                                    type="button"
                                    className="fas fa-calendar-alt text-primary btn-link border-0 bg-transparent"
                                    onClick={() => vendors && setAppointmentModalOpen(true)}
                                    aria-label={Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.LOCAL_COMMERCE.TRANSFER.APPOINTMENT.LABEL',
                                    })}
                                />
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                    <ErrorForm errors={errors} name="dealership" />
                </Form.Group>
            )}
            <Form.Group as={Col} md={12}>
                <Form.Label>
                    <FormattedMessage id="CUSTOMERS.MODAL.LOCAL_COMMERCE.TRANSFER.REFERRER.LABEL" />
                </Form.Label>
                <Form.Control
                    as="select"
                    name="referent"
                    ref={register()}
                    readOnly={appointment}
                    disabled={!vendors?.length}
                    className={`${appointment && 'bg-disabled'}`}
                >
                    <option value="none">
                        {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LOCAL_COMMERCE.TRANSFER.REFERRER.DEFAULT' })}
                    </option>
                    {vendors?.map((vendor: { id: number; first_name: string; last_name: string }) => (
                        <option value={vendor.id}>
                            {vendor.first_name} {vendor.last_name}
                        </option>
                    ))}
                </Form.Control>
                <ErrorForm errors={errors} name="dealership" />
            </Form.Group>
            <Form.Group as={Col} md={12}>
                <Form.Label>
                    <FormattedMessage id="CUSTOMERS.MODAL.LOCAL_COMMERCE.TRANSFER.NOTES.LABEL" />
                </Form.Label>
                <Form.Control
                    as="textarea"
                    ref={register({
                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    })}
                    name="customerProject"
                    rows={5}
                />
                <ErrorForm errors={errors} name="customerProject" />
            </Form.Group>
            <Appointment
                showModal={appointmentModalOpen}
                setShowModal={setAppointmentModalOpen}
                onSubmitOverride={formatAppointment}
                defaultValues={{ vendors }}
                disabledFields={['dealership', 'leadType', 'source', 'type', 'carSearch']}
            />
        </>
    );
};

export default CommerceRequestTransfer;
