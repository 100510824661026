import React, {Dispatch, SetStateAction} from 'react';
import {motion} from "framer-motion";
import classNames from "classnames";

const variants = {
    show: {
        opacity: 1,
        rotate: 0,
        scale: 1
    },
    hide: {
        opacity: 0,
        rotate: 360,
        scale: 0
    }
}

type ExpanderProps = {
    collapsed: boolean,
    setCollapsed?: Dispatch<SetStateAction<boolean>>,
    size?: string,
    variant?: string,
    className?: string
}
const Expander = ({collapsed, setCollapsed, size, variant, className}: ExpanderProps) => {
    return (
        <div className={classNames('h-100 position-relative', className)} onClick={() => setCollapsed?.(!collapsed)}>
            <motion.div className="right-0 position-absolute"
                        variants={variants}
                        animate={collapsed ? "show" : "hide"}>
                <i className={`las ${size} text-${variant} la-plus`} />
            </motion.div>
            <motion.div className="right-0 position-absolute"
                        variants={variants}
                        animate={collapsed ? "hide" : "show"}>
                <i className={`las ${size} text-${variant} la-minus`} />
            </motion.div>
        </div>
    );
};

Expander.defaultProps = {
    className: '',
    size: 'la-2x',
    variant: 'secondary'
}

export default Expander;
