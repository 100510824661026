import { Divider } from 'primereact/divider';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import type { Package } from '@app/pages/apv/orders/Order';

interface PackageProps {
    orderPackage: Package;
}
const PackageRow: React.FC<PackageProps> = ({ orderPackage }) => (
    <>
        <Row>
            <Col md={7} lg={7}>
                <span className="font-weight-semibold">{orderPackage.name}</span>&nbsp;
                <span>&#8226;</span>&nbsp;
                <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.PACKAGE_REF" />
                &nbsp;
                <span>{orderPackage.code}</span>
            </Col>
            <Col md={5} lg={5}>
                <div className="justify-content-end d-flex font-weight-semibold">
                    {orderPackage.totalPrice > 0 ? (
                        <>
                            {`${orderPackage.totalPrice} € `}
                            <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.PACKAGE_PRICE_TTC" />
                        </>
                    ) : (
                        <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.FREE_PACKAGE" />
                    )}
                </div>
            </Col>
        </Row>
        <Divider type="solid" />
    </>
);

export default PackageRow;
