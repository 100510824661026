import React from 'react';

const BusinessRowInfo = ({ title, icone, value, variant }) => (
    <>
        <i className={`business__icon la ${icone} text-${variant} la-2x mr-2`} />
        <div className="d-flex flex-column">
            <span>{title}</span>
            <span className="font-weight-bold">{value}</span>
        </div>
    </>
);

export default BusinessRowInfo;
