import {ReactComponent as Settings} from '@src/_metronic/_assets/media/svg/Code/Settings4.svg';
import {ReactComponent as Shield} from '@src/_metronic/_assets/media/svg/Code/Shield.svg';
import {ReactComponent as Addessbook2} from '@src/_metronic/_assets/media/svg/Communication/Adress-book2.svg';
import {ReactComponent as Customer} from '@src/_metronic/_assets/media/svg/Communication/Group.svg';
import {ReactComponent as Home} from '@src/_metronic/_assets/media/svg/Home/Home.svg';
import {ReactComponent as Position} from '@src/_metronic/_assets/media/svg/Map/Position.svg';
import {ReactComponent as Repeat} from '@src/_metronic/_assets/media/svg/Media/Repeat.svg';
import {ReactComponent as Box3} from '@src/_metronic/_assets/media/svg/Shopping/Box3.svg';
import {ReactComponent as Wallet} from '@src/_metronic/_assets/media/svg/Shopping/Wallet.svg';
import {ReactComponent as Tools} from '@src/_metronic/_assets/media/svg/Tools/Tools.svg';
import {ReactComponent as Campaign} from '@src/_metronic/_assets/media/svg/Weather/marketing-campaign.svg';

import {routeTo} from '@app/helpers/RoutesHelper';

import { getOrderCompleteCounter, getOrdersApvInStoreCounter, getQuotesApvInStoreCounter } from '@app/crud/apv/order.crud';
import {
  getAchatCashActionToComeCounter,
  getAchatCashActionToDoCounter,
  getAchatCashAllCounter,
  getAchatCashCloseCounter,
  getAchatCashNewCounter,
  getAchatCashStockCounter,
  getCustomerCounters,
  getFluidityCloseCounter,
  getFluidityInProgressCounter,
  getLeadActionToComeCounter,
  getLeadActionToDoCounter,
  getLeadAllCounter,
  getLeadCloseCounter,
  getLeadNewCounter
} from "@app/crud/customers/customer.crud";
import { getDealershipOrdersCounter } from '@app/crud/manufacturer/order.crud';
import { getNotesCounters } from '@app/crud/note/note.crud';
import {
  getQuotationAppointmentInProgressCounter,
  getQuotationToBeAppraisedCounter
} from '@app/crud/smartRepair/smart-repair.crud';
import { getStockActionToDoCounters, getStockPricingToDoCounters } from "@app/crud/stock/stock.crud";

import ROUTES from '@app/router/Routes';
import { leadTypes } from '@app/store/customers/customers.store';

const {REACT_APP_COUNTER_LEAD_DELAY, REACT_APP_COUNTER_CUSTOMER_DELAY, REACT_APP_COUNTER_QUOTATION_DELAY, REACT_APP_COUNTER_AFTERSALES_DELAY, REACT_APP_COUNTER_DEALERSHIP_DELAY, REACT_APP_COUNTER_STOCK_DELAY} = process.env;

export default {
  header: {
    self: {},
    items: []
  },
  aside: {
    self: {},
    items: [
      {
        root: true,
        svg: Home,
        page: routeTo(ROUTES.DASHBOARD.PATH),
        translate: "MENU.DASHBOARD",
        bullet: "dot",
        mobile: {
          translate: "MENU.DASHBOARD.MOBILE"
        },
        permissions: ['PROFILE_VIEW_DASHBOARD']
      },
      {
        root: true,
        separator: true,
        svg: Addessbook2,
        page: routeTo(ROUTES.AGENDA.PATH),
        id: "menu-agenda",
        hasAllPermissions: true,
        permissions: ['LEAD_VIEW_CALENDAR'],
        translate: "MENU.AGENDA",
        bullet: "dot"
      },
      {
        svg: Customer,
        separator: true,
        translate: "MENU.CUSTOMERS",
        id: "menu-customers",
        permissions: ['LEAD_SEARCH_CONTACT', 'LEAD_LIST_ACTION_TODO_CONTACT', 'LEAD_VIEW_PORTFOLIO'],
        hasAnyPermissions: true,
        bullet: "dot",
        mobile: {
          translate: "MENU.CUSTOMERS",
          page: routeTo(ROUTES.CUSTOMERS.PATH)
        },
        submenu: [
          {
            permissions: ['LEAD_SEARCH_CONTACT'],
            translate: "MENU.CUSTOMERS.SEARCH",
            page: routeTo(ROUTES.CUSTOMERS.PATH),
          },
          {
            permissions: ['LEAD_LIST_ACTION_TODO_CONTACT'],
            translate: "MENU.CUSTOMERS.ACTIONS.SEARCH",
            // counter: {
            //   id: 'client_action_to_do',
            //   fetcher: getLeadCounters,
            //   permission: '',
            //   delay: REACT_APP_COUNTER_LEAD_DELAY
            // },
            page: routeTo(ROUTES.ACTIONS.PATH),
          },
          {
            permissions: ['LEAD_VIEW_PORTFOLIO'],
            translate: "MENU.CUSTOMERS.PORTFOLIO",
            counter: {
              id: 'customers',
              fetcher: getCustomerCounters,
              permission: 'LEAD_VIEW_PORTFOLIO',
              delay: REACT_APP_COUNTER_CUSTOMER_DELAY
            },
            page: routeTo(ROUTES.PORTFOLIO.PATH),
          },
          {
            permissions: ['LEAD_LIST_CUSTOMER_NOTE'],
            translate: "MENU.CUSTOMERS.NOTES",
            counter: {
              id: 'client_unread_notes',
              fetcher: getNotesCounters,
              permission: 'LEAD_LIST_CUSTOMER_NOTE',
              delay: REACT_APP_COUNTER_LEAD_DELAY
            },
            page: routeTo(ROUTES.NOTES.PATH.ARCHIVE,  {
                status: 'toTake'
            }),
          }
        ]
      },
      {
        permissions: ['MARKETING_CAMPAIGN_VIEW'],
        svg: Campaign,
        separator: true,
        translate: "MENU.CAMPAIGNS",
        id: "menu-campaigns",
        bullet: "dot",
        page: routeTo(ROUTES.CAMPAIGNS.PATH)
      },
      {
        svg: Position,
        separator: true,
        translate: "MENU.LEAD_ONLINE",
        id: "menu-lead-online",
        permissions: [
          'LEAD_LIST_NEW_LEAD',
          'LEAD_LIST_ACTION_TODO_LEAD',
          'LEAD_LIST_ACTION_TO_COME_LEAD',
          'LEAD_LIST_ALL_LEAD',
          'LEAD_LIST_CLOSE_LEAD'
        ],
        hasAnyPermissions: true,
        bullet: "dot",
        mobile: {
          translate: "MENU.LEAD_ONLINE",
          page: routeTo(ROUTES.LEADS_ONLINE.PATH, {
            statusType: 'all'
          })
        },
        submenu: [
          {
            permissions: ['LEAD_LIST_NEW_LEAD'],
            translate: "MENU.LEAD_ONLINE.NEW",
            counter: {
              id: 'lead_new',
              fetcher: getLeadNewCounter,
              delay: REACT_APP_COUNTER_LEAD_DELAY
            },
            page: routeTo(ROUTES.LEADS_ONLINE.PATH, {
              statusType: 'new'
            })
          },
          {
            permissions: ['LEAD_LIST_ACTION_TODO_LEAD'],
            translate: "MENU.LEAD_ONLINE.ACTION_TO_DO",
            counter: {
              id: 'lead_action_to_do',
              fetcher: getLeadActionToDoCounter,
              delay: REACT_APP_COUNTER_LEAD_DELAY
            },
            page: routeTo(ROUTES.LEADS_ONLINE.PATH, {
              statusType: 'action-to-do'
            })
          },
          {
            permissions: ['LEAD_LIST_ACTION_TO_COME_LEAD'],
            translate: "MENU.LEAD_ONLINE.ACTION_TO_COME",
            counter: {
              id: 'lead_action_to_come',
              fetcher: getLeadActionToComeCounter,
              delay: REACT_APP_COUNTER_LEAD_DELAY
            },
            page: routeTo(ROUTES.LEADS_ONLINE.PATH, {
              statusType: 'action-to-come'
            })
          },
          {
            permissions: ['LEAD_LIST_ALL_LEAD'],
            translate: "MENU.LEAD_ONLINE.ALL",
            counter: {
              id: 'lead_all',
              fetcher: getLeadAllCounter,
              delay: REACT_APP_COUNTER_LEAD_DELAY
            },
            page: routeTo(ROUTES.LEADS_ONLINE.PATH, {
              statusType: 'all'
            })
          },
          {
            permissions: ['LEAD_LIST_CLOSE_LEAD'],
            translate: "MENU.LEAD_ONLINE.CLOSE",
            counter: {
              id: 'lead_close',
              fetcher: getLeadCloseCounter,
              delay: REACT_APP_COUNTER_LEAD_DELAY
            },
            page: routeTo(ROUTES.LEADS_ONLINE.PATH, {
              statusType: 'close'
            })
          }
        ]
      },
      {
        svg: Wallet,
        separator: true,
        translate: "MENU.LEAD_ACHAT_CASH",
        id: "menu-lead-achat-cash",
        permissions: [
          'LEAD_ACHAT_CASH_LIST_NEW',
          'LEAD_ACHAT_CASH_LIST_ACTION_TO_DO',
          'LEAD_ACHAT_CASH_LIST_ACTION_TO_COME',
          'LEAD_ACHAT_CASH_LIST_ALL',
          'LEAD_ACHAT_CASH_LIST_CLOSE',
          'LEAD_ACHAT_CASH_LIST_STOCK'
        ],
        hasAnyPermissions: true,
        bullet: "dot",
        mobile: {
          translate: "MENU.LEAD_ACHAT_CASH",
          page: routeTo(ROUTES.LEADS_ACHAT_CASH.PATH.MAIN, {
            statusType: 'all'
          })
        },
        submenu: [
          {
            permissions: ['LEAD_ACHAT_CASH_LIST_NEW'],
            translate: "MENU.LEAD_ACHAT_CASH.NEW",
            counter: {
              id: 'achat_cash_new',
              fetcher: getAchatCashNewCounter,
              delay: REACT_APP_COUNTER_LEAD_DELAY
            },
            page: routeTo(ROUTES.LEADS_ACHAT_CASH.PATH.MAIN, {
              statusType: 'new'
            })
          },
          {
            permissions: ['LEAD_ACHAT_CASH_LIST_ACTION_TO_DO'],
            translate: "MENU.LEAD_ACHAT_CASH.ACTION_TO_DO",
              counter: {
                id: 'achat_cash_action_to_do',
                fetcher: getAchatCashActionToDoCounter,
                delay: REACT_APP_COUNTER_LEAD_DELAY
              },
            page: routeTo(ROUTES.LEADS_ACHAT_CASH.PATH.MAIN, {
              statusType: 'action-to-do'
            })
          },
          {
            permissions: ['LEAD_ACHAT_CASH_LIST_ACTION_TO_COME'],
            translate: "MENU.LEAD_ACHAT_CASH.ACTION_TO_COME",
            counter: {
              id: 'achat_cash_action_to_come',
              fetcher: getAchatCashActionToComeCounter,
              delay: REACT_APP_COUNTER_LEAD_DELAY
            },
            page: routeTo(ROUTES.LEADS_ACHAT_CASH.PATH.MAIN, {
              statusType: 'action-to-come'
            })
          },
          {
            permissions: ['LEAD_ACHAT_CASH_LIST_ALL'],
            translate: "MENU.LEAD_ACHAT_CASH.ALL",
            counter: {
              id: 'achat_cash_all',
              fetcher: getAchatCashAllCounter,
              delay: REACT_APP_COUNTER_LEAD_DELAY
            },
            page: routeTo(ROUTES.LEADS_ACHAT_CASH.PATH.MAIN, {
              statusType: 'all'
            })
          },
          {
            permissions: ['LEAD_ACHAT_CASH_LIST_CLOSE'],
            translate: "MENU.LEAD_ACHAT_CASH.CLOSE",
            counter: {
              id: 'achat_cash_close',
              fetcher: getAchatCashCloseCounter,
              delay: REACT_APP_COUNTER_LEAD_DELAY
            },
            page: routeTo(ROUTES.LEADS_ACHAT_CASH.PATH.MAIN, {
              statusType: 'close'
            })
          },
          {
            permissions: ['LEAD_ACHAT_CASH_LIST_STOCK'],
            translate: "MENU.LEAD_ACHAT_CASH.STOCK",
            counter: {
              id: 'achat_cash_stock',
              fetcher: getAchatCashStockCounter,
              delay: REACT_APP_COUNTER_LEAD_DELAY
            },
            page: routeTo(ROUTES.LEADS_ACHAT_CASH.PATH.ABANDON, {
              statusType: 'new'
            })
          }
        ]
      },
      {
        svg: Repeat,
        separator: true,
        translate: "MENU.FLUIDITY",
        id: "menu-fluidity",
        permissions: ['LEAD_LIST_FLUIDITE'],
        hasAnyPermissions: true,
        bullet: "dot",
        submenu: [
          {
            translate: "MENU.FLUIDITY.IN_PROGRESS.COUNTER",
            counter: {
              id: 'fluidity_in_progress',
              fetcher: getFluidityInProgressCounter,
              delay: REACT_APP_COUNTER_LEAD_DELAY
            },
            page: routeTo(ROUTES.FLUIDITIES.PATH, {
              status: 'in_progress'
            })
          },
          {
            translate: "MENU.FLUIDITY.CLOSED.COUNTER",
            counter: {
              id: 'fluidity_close',
              fetcher: getFluidityCloseCounter,
              delay: REACT_APP_COUNTER_LEAD_DELAY
            },
            page: routeTo(ROUTES.FLUIDITIES.PATH, {
              status: 'close'
            })
          }
        ]
      },
      {
        svg: Box3,
        translate: "MENU.STOCK",
        id: "menu-stock",
        permissions: ['STOCK_LIST_VEHICLES', 'STOCK_LIST_ACTION'],
        hasAnyPermissions: true,
        bullet: "dot",
        submenu: [
          {
            permissions: ['STOCK_LIST_VEHICLES'],
            translate: "MENU.STOCK.VEHICLES",
            page: routeTo(ROUTES.STOCK_VEHICLES.PATH)
          },
          {
            permissions: ['STOCK_LIST_ACTION'],
            translate: "MENU.STOCK.ACTION_TO_DO.COUNTER",
            bullet: "dot",
            submenu: [
              {
                translate: "MENU.STOCK.ACTION_TO_DO.VEHICLES.COUNTER",
                permissions: ['STOCK_LIST_ACTION'],
                counter: {
                  id: 'stock_action_to_do',
                  fetcher: getStockActionToDoCounters,
                  delay: REACT_APP_COUNTER_STOCK_DELAY
                },
                page: routeTo(ROUTES.STOCK_ACTION_TO_DO.PATH, {page: 'list'})
              },
              {
                translate: "MENU.STOCK.ACTION_TO_DO.PRICINGS.COUNTER",
                permissions: ['STOCK_VIEW_PRICE_MANAGER'],
                counter: {
                  id: 'stock_pricing_to_do',
                  fetcher: getStockPricingToDoCounters,
                  delay: REACT_APP_COUNTER_STOCK_DELAY
                },
                page: routeTo(ROUTES.STOCK_PRICING_TO_DO.PATH, {page: 'list'})
              },
            ]
          }
        ]
      },
      {
        svg: Tools,
        separator: true,
        translate: "MENU.APV",
        permissions: ['APV_LIST_ORDER', 'SR_LIST_ORDER', 'APV_LOCAL_LIST_LEAD_APV', 'DEV_LIST_ORDER'],
        hasAnyPermissions: true,
        bullet: "dot",
        submenu: [
          {
            translate: "MENU.APV.ONLINE_APPOINTEMENT",
            permissions: ['APV_LIST_ORDER', 'SR_LIST_ORDER'],
            hasAnyPermissions: true,
            bullet: "dot",
            submenu: [
              {
                translate: "MENU.APV.ORDERS.COUNTER",
                permissions: ['APV_LIST_ORDER'],
                counter: {
                  id: 'autosphere_apv_order',
                  fetcher: getOrderCompleteCounter,
                  permission: 'APV_LIST_ORDER',
                  delay: REACT_APP_COUNTER_AFTERSALES_DELAY
                },
                page: routeTo(ROUTES.ORDERS.PATH.ALL)
              },
              {
                translate: "MENU.SMARTREPAIR.APPOINTMENT.COUNTER",
                permissions: ['SR_LIST_ORDER'],
                counter: {
                  id: 'quotation_appointment_inprogress',
                  fetcher: getQuotationAppointmentInProgressCounter,
                  permission: 'SR_LIST_ORDER',
                  delay: REACT_APP_COUNTER_QUOTATION_DELAY
                },
                page: routeTo(ROUTES.SMARTREPAIR_ORDERS.PATH.ALL)
              },
              {
                translate: "MENU.MANUFACTURER.ORDERS.COUNTER",
                permissions: ['APV_LIST_ORDER'],
                counter: {
                  id: 'dealership_apv_order',
                  fetcher: getDealershipOrdersCounter,
                  permission: 'APV_LIST_ORDER',
                  delay: REACT_APP_COUNTER_DEALERSHIP_DELAY
                },
                page: routeTo(ROUTES.MANUFACTURER_ORDERS.PATH.ALL)
              }
            ]
          },
          {
            translate: "MENU.APVINSTORE.ORDERS.COUNTER",
            permissions: ['APV_INSTORE_LIST_ORDER'],
            hasAnyPermissions: true,
            bullet: "dot",
            counter: {
              id: 'apvinstore_orders',
              fetcher: getOrdersApvInStoreCounter,
              permission: 'APV_INSTORE_LIST_ORDER',
              delay: REACT_APP_COUNTER_AFTERSALES_DELAY
            },
            page: routeTo(ROUTES.APV_INSTORE_ORDERS.PATH)
          },
          {
            translate: "MENU.QUOTATION",
            permissions: [
              'DEV_LIST_ORDER', 'APV_INSTORE_LIST_ORDER'
            ],
            hasAnyPermissions: true,
            bullet: "dot",
            submenu: [
              {
                translate: "MENU.QUOTATION.SMART.REPAIR.COUNTER",
                permissions: ['DEV_LIST_ORDER', 'APV_INSTORE_LIST_ORDER'],
                hasAnyPermissions: true,
                bullet: "dot",
                counter: {
                  id: 'quotation_to_appraise',
                  fetcher: getQuotationToBeAppraisedCounter,
                  permission: 'DEV_LIST_ORDER',
                  delay: REACT_APP_COUNTER_QUOTATION_DELAY
                },
                page: routeTo(ROUTES.QUOTATIONS.PATH.ALL)
              },
              {
                translate: "MENU.APVINSTORE.ORDERS.COUNTER",
                permissions: ['APV_INSTORE_LIST_ORDER'],
                hasAnyPermissions: true,
                bullet: "dot",
                counter: {
                  id: 'apvinstore_quotations',
                  fetcher: getQuotesApvInStoreCounter,
                  permission: 'APV_INSTORE_LIST_ORDER',
                  delay: REACT_APP_COUNTER_AFTERSALES_DELAY
                },
                page: routeTo(ROUTES.APV_INSTORE_QUOTATIONS.PATH.ALL)
              },
            ]
          },
          {
            translate: "MENU.APV.LEADS",
            permissions: ['APV_LOCAL_LIST_LEAD_APV'],
            hasAnyPermissions: true,
            bullet: "dot",
            page: routeTo(ROUTES.APV_LEADS.PATH, {
              statusType: 'all'
            })
          }
        ]
      },
      {
        separator: true
      },
      {
        svg: Shield,
        translate: "MENU.SECURYWEB",
        hasAnyPermissions: true,
        typologies: [leadTypes.securycar],
        permissions: ['SECURYCAR_SEARCH_CONTRACT', 'SECURYCAR_EXPORT_REPORT'],
        bullet: "dot",
        submenu: [
          {
            permissions: ['SECURYCAR_SEARCH_CONTRACT'],
            translate: "MENU.SECURYWEB.CONTRACT",
            page:  routeTo(ROUTES.SECURYWEB_CONTRACT.PATH)
          },
          {
            permissions: ['SECURYCAR_EXPORT_REPORT'],
            translate: "MENU.SECURYWEB.REPORTING",
            page:  routeTo(ROUTES.SECURYWEB_REPORTING.PATH)
          }
        ]
      },
      {
        svg: Settings,
        translate: "MENU.SETTINGS",
        permissions: ['PARAMETER_ACCESS_MENU'],
        bullet: "dot",
        submenu: [
          {
            permissions: ['PARAMETER_ACCESS_MENU'],
            translate: "MENU.SETTINGS.GLOBAL",
            page:  routeTo(ROUTES.SETTINGS.PATH,{
                slug: 'global'
            })
          },
          {
            permissions: ['PARAMETER_ACCESS_MENU'],
            translate: "MENU.SETTINGS.APV",
            page:  routeTo(ROUTES.SETTINGS.PATH,{
                slug: 'apv'
            })
          },
        ]
      }
    ]
  }
};
