import ApiGateway, { CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import type { ApiResponse } from '../apv/order.type';

import type { CustomerNoteType, CustomerNoteTypeInListing, NotesSearchParams } from './note.type';

const { REACT_APP_LEAD_URL } = process.env;

export interface NoteApiResponse extends ApiResponse<CustomerNoteTypeInListing[]> {
    count: number;
    facets: {
        [key: string]: number;
    };
}

export function getCustomersNotes(params: NotesSearchParams) {
    return ApiGateway.get<NoteApiResponse, CustomAxiosResponse<NoteApiResponse>>(
        `${REACT_APP_LEAD_URL}/contact-note/listing`,
        {
            params,
        },
    );
}

export function getCustomerNote(id: number) {
    return ApiGateway.get<ApiResponse<CustomerNoteType>, CustomAxiosResponse<ApiResponse<CustomerNoteType>>>(
        `${REACT_APP_LEAD_URL}/contact-note/get/${id}`,
    );
}

export async function getNotesCounters() {
    const { result: count } = await ApiGateway.get<ApiResponse<[]>, CustomAxiosResponse<ApiResponse<[]>>>(
        `${REACT_APP_LEAD_URL}/contact-note/counters`,
    );
    return count;
}
