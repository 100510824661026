import { AnyAction } from 'redux';

import { AutoVistaPricing, VehicleResponse } from '@app/crud/stock/stock.crud';

import { Vehicle, VehicleHistoricalPrice } from './stock.type';

export const actionTypes = {
    FetchVehicleHistoricalPrice: 'FETCH_VEHICLE_HISTORICAL_PRICE',
    FetchedVehicleHistoricalPrice: 'FETCHED_VEHICLE_HISTORICAL_PRICE',
    FetchVehicleHistoricalPriceFailed: 'FETCH_VEHICLE_HISTORICAL_PRICE_FAILED',
    FetchVehiclePricing: 'FETCH_VEHICLE_PRICING',
    FetchedVehiclePricing: 'FETCHED_VEHICLE_PRICING',
    FetchVehiclePricingFailed: 'FETCHED_VEHICLE_PRICING_FAILED',
    FetchVehicle: 'FETCH_VEHICLE',
    FetchedVehicle: 'FETCHED_VEHICLE',
    FetchVehicleFailed: 'FETCH_VEHICLE_FAILED',
    FetchVehicles: 'FETCH_VEHICLES',
    FetchedVehicles: 'FETCHED_VEHICLES',
    FetchVehiclesFailed: 'FETCH_VEHICLES_FAILED',
    UpdateVehicleSelected: 'UPDATE_VEHICLES_SELECTED',
    DeleteVehicleSelectedItem: 'DELETE_VEHICLE_SELECTED',
    UpdateVehiclePrice: 'UPDATE_VEHICLE_PRICE',
};

export interface StockState {
    vehicles: {
        data: object;
        total: number;
        loading: boolean;
        error: null;
    };
    vehicle: {
        data: object;
        loading: boolean;
        error: null;
    };
    vehicleHistoricalPrice: {
        data: VehicleHistoricalPrice[];
        loading: boolean;
        error: null;
    };
    vehiclePricing: {
        data: AutoVistaPricing;
        loading: boolean;
        error: null;
    };
    vehiclesSelected: {
        [key: string]: Vehicle;
    };
}

const initialState: StockState = {
    vehicles: {
        data: {},
        total: 0,
        loading: false,
        error: null,
    },
    vehicle: {
        data: {} as Vehicle,
        loading: false,
        error: null,
    },
    vehicleHistoricalPrice: {
        data: [],
        loading: false,
        error: null,
    },
    vehiclePricing: {
        data: {} as AutoVistaPricing,
        loading: false,
        error: null,
    },
    vehiclesSelected: {},
};

export function stockReducer(state = initialState, action: AnyAction): StockState {
    switch (action.type) {
        case actionTypes.FetchVehicleHistoricalPrice: {
            return {
                ...state,
                vehicleHistoricalPrice: {
                    data: [],
                    error: null,
                    loading: true,
                },
            };
        }
        case actionTypes.FetchVehiclePricing: {
            return {
                ...state,
                vehiclePricing: {
                    data: {} as AutoVistaPricing,
                    error: null,
                    loading: true,
                },
            };
        }
        case actionTypes.FetchVehicle: {
            return {
                ...state,
                vehicle: {
                    data: {},
                    error: null,
                    loading: true,
                },
            };
        }
        case actionTypes.FetchVehicles: {
            return {
                ...state,
                vehicles: {
                    data: {},
                    total: 0,
                    error: null,
                    loading: true,
                },
            };
        }
        case actionTypes.FetchedVehicleHistoricalPrice: {
            return {
                ...state,
                vehicleHistoricalPrice: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };
        }
        case actionTypes.FetchedVehiclePricing: {
            return {
                ...state,
                vehiclePricing: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };
        }
        case actionTypes.FetchedVehicle: {
            return {
                ...state,
                vehicle: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };
        }
        case actionTypes.FetchedVehicles: {
            return {
                ...state,
                vehicles: {
                    data: action.payload,
                    total: action.payload.total,
                    loading: false,
                    error: null,
                },
            };
        }
        case actionTypes.FetchVehicleHistoricalPriceFailed: {
            return {
                ...state,
                vehicleHistoricalPrice: {
                    data: [],
                    error: action.payload,
                    loading: false,
                },
            };
        }
        case actionTypes.FetchVehiclePricingFailed: {
            return {
                ...state,
                vehiclePricing: {
                    data: {} as AutoVistaPricing,
                    error: action.payload,
                    loading: false,
                },
            };
        }
        case actionTypes.FetchVehicleFailed: {
            return {
                ...state,
                vehicle: {
                    data: {},
                    error: action.payload,
                    loading: false,
                },
            };
        }
        case actionTypes.FetchVehiclesFailed: {
            return {
                ...state,
                vehicles: {
                    data: {},
                    total: 0,
                    error: action.payload,
                    loading: false,
                },
            };
        }
        case actionTypes.UpdateVehicleSelected: {
            return {
                ...state,
                vehiclesSelected: action.payload,
            };
        }
        case actionTypes.DeleteVehicleSelectedItem: {
            const vehicles = state.vehiclesSelected;
            // @ts-ignore
            delete vehicles[action.payload];
            return {
                ...state,
                vehiclesSelected: vehicles,
            };
        }
        case actionTypes.UpdateVehiclePrice: {
            return {
                ...state,
                vehicle: {
                    ...state.vehicle,
                    data: {
                        ...state.vehicle.data,
                        price: action.payload,
                        pricing: null,
                    },
                },
            };
        }
        default:
            return state;
    }
}

export const actions = {
    fetchVehicleHistoricalPrice: () => ({ type: actionTypes.FetchVehicleHistoricalPrice }),
    fetchedVehicleHistoricalPrice: (vehicleHistoricalPrice: VehicleHistoricalPrice[]) => ({
        type: actionTypes.FetchedVehicleHistoricalPrice,
        payload: vehicleHistoricalPrice,
    }),
    fetchVehicleHistoricalPriceFailed: (error: object) => ({
        type: actionTypes.FetchVehicleHistoricalPriceFailed,
        payload: error,
    }),
    fetchVehiclePricing: () => ({ type: actionTypes.FetchVehiclePricing }),
    fetchedVehiclePricing: (vehiclePricing: AutoVistaPricing) => ({
        type: actionTypes.FetchedVehiclePricing,
        payload: vehiclePricing,
    }),
    fetchVehiclePricingFailed: (error: object) => ({ type: actionTypes.FetchVehiclePricingFailed, payload: error }),
    fetchVehicle: () => ({ type: actionTypes.FetchVehicle }),
    fetchedVehicle: (vehicle: VehicleResponse) => ({ type: actionTypes.FetchedVehicle, payload: vehicle }),
    fetchVehicleFailed: (error: object) => ({ type: actionTypes.FetchVehicleFailed, payload: error }),
    fetchVehicles: () => ({ type: actionTypes.FetchVehicles }),
    fetchedVehicles: (vehicles: any) => ({ type: actionTypes.FetchedVehicles, payload: vehicles }),
    fetchVehiclesFailed: (error: object) => ({ type: actionTypes.FetchVehiclesFailed, payload: error }),
    UpdateVehicleSelected: (vehicles: { [key: string]: Vehicle } | {}) => ({
        type: actionTypes.UpdateVehicleSelected,
        payload: vehicles,
    }),
    DeleteVehicleSelectedItem: (index: string) => ({ type: actionTypes.DeleteVehicleSelectedItem, payload: index }),
    updateVehiclePrice: (price: number) => ({ type: actionTypes.UpdateVehiclePrice, payload: price }),
};
