import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import {
    Cities,
    Contract,
    LawCodeType,
    Optins,
    Product,
    StatusContractSlug,
    Vehicle,
} from '@app/crud/securyweb/securyweb.types';

const { REACT_APP_SECURYWEB_URL } = process.env;

export function getVehicles(params: { immat?: string; vin?: string }) {
    return ApiGateway.get<Vehicle[], CustomAxiosResponse<Vehicle[]>>(`${REACT_APP_SECURYWEB_URL}/vehicle`, { params });
}

export function getProducts(dealershipId: string) {
    return ApiGateway.get<ApiResponse<Product[]>, CustomAxiosResponse<ApiResponse<Product[]>>>(
        `${REACT_APP_SECURYWEB_URL}/products/${dealershipId}`,
    );
}

export function getCities(zipcode: string) {
    return ApiGateway.get<Cities[], CustomAxiosResponse<Cities[]>>(`${REACT_APP_SECURYWEB_URL}/cities`, {
        params: {
            codePostal: zipcode,
        },
    });
}

export function checkCoyote({ vin, productCode }: { vin: string; productCode: string }) {
    return ApiGateway.get<ApiResponse<boolean>, CustomAxiosResponse<ApiResponse<boolean>>>(
        `${REACT_APP_SECURYWEB_URL}/coyote/check/${vin}/${productCode}`,
    );
}

export function getDealershipLaw(dealership: string) {
    return ApiGateway.get<ApiResponse<LawCodeType>, CustomAxiosResponse<ApiResponse<LawCodeType>>>(
        `${REACT_APP_SECURYWEB_URL}/dealer/law/${dealership}`,
    );
}

export function getSecurywebContracts(params: { statuts: StatusContractSlug }) {
    return ApiGateway.get<Contract[], CustomAxiosResponse<ApiResponse<Contract[]>>>(
        `${REACT_APP_SECURYWEB_URL}/contracts`,
        {
            params,
        },
    );
}

export function getContractsByCustomerId(id: string) {
    return ApiGateway.get<ApiResponse<Contract[]>, CustomAxiosResponse<ApiResponse<Contract[]>>>(
        `${REACT_APP_SECURYWEB_URL}/${id}/contracts`,
    );
}

export function getContractPrint(id: string) {
    return ApiGateway.get<Blob, CustomAxiosResponse<Blob>>(`${REACT_APP_SECURYWEB_URL}/contracts/${id}/print`, {
        responseType: 'blob',
    });
}

export function createDraft(data: Contract, customerId: number) {
    return ApiGateway.post<ApiResponse<Contract>, CustomAxiosResponse<ApiResponse<Contract>>>(
        `${REACT_APP_SECURYWEB_URL}/${customerId}/contracts/draft`,
        data,
    );
}

export function deleteDraft(draftId: string) {
    return ApiGateway.delete(`${REACT_APP_SECURYWEB_URL}/draft/${draftId}`);
}

export function createContract(data: Contract, customerId: number) {
    return ApiGateway.post<ApiResponse<Contract>, CustomAxiosResponse<ApiResponse<Contract>>>(
        `${REACT_APP_SECURYWEB_URL}/${customerId}/contracts`,
        data,
    );
}

export function getContractById(id: string) {
    return ApiGateway.get<ApiResponse<Contract>, CustomAxiosResponse<ApiResponse<Contract>>>(
        `${REACT_APP_SECURYWEB_URL}/contract/${id}`,
    );
}

export function getOptins(id: string) {
    return ApiGateway.get<ApiResponse<Optins>, CustomAxiosResponse<ApiResponse<Optins>>>(
        `${REACT_APP_SECURYWEB_URL}/optins/${id}`,
    );
}

export function exportContractPdf(id: string) {
    return ApiGateway.get<string, BlobPart>(`${REACT_APP_SECURYWEB_URL}/contracts/pdf/${id}`, {
        responseType: 'arraybuffer',
    }).then((response) => {
        const blob = new Blob([response], {
            type: 'application/pdf',
        });
        saveAs(blob, `contract-${id}.pdf`);
    });
}

export function signContract(data: object) {
    type ResultDataType = {
        ok: boolean;
        url: string;
    };
    return ApiGateway.post<ApiResponse<ResultDataType>, CustomAxiosResponse<ApiResponse<ResultDataType>>>(
        `${REACT_APP_SECURYWEB_URL}/contracts/sign`,
        data,
    );
}

export function exportStatisticsReport(startDate?: string, endDate?: string) {
    return ApiGateway.post<string, BlobPart>(
        `${REACT_APP_SECURYWEB_URL}/report/statistics`,
        {
            dateDebut: startDate,
            dateFin: endDate,
        },
        {
            responseType: 'arraybuffer',
        },
    ).then((response) => {
        const blob = new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, `statistics-${dayjs().format('YYYY-MM-DD')}.xlsx`);
    });
}

export function exportCompletionReport(startDate?: string, endDate?: string) {
    return ApiGateway.post<string, BlobPart>(
        `${REACT_APP_SECURYWEB_URL}/report/completion`,
        {
            dateDebut: startDate,
            dateFin: endDate,
        },
        {
            responseType: 'arraybuffer',
        },
    ).then((response) => {
        const blob = new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
        });
        saveAs(blob, `completude-${dayjs().format('YYYY-MM-DD')}.xlsx`);
    });
}

export function getContactId(subscriberId: string) {
    return ApiGateway.get<ApiResponse<number>, CustomAxiosResponse<ApiResponse<number>>>(
        `${REACT_APP_SECURYWEB_URL}/contact/${subscriberId}`,
    );
}
