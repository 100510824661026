import { createContext } from 'react';

import { ModalStepperType } from '@app/partials/content/modals/ModalStepper';

interface APVInStoreContextInterface {
    stepperState: ModalStepperType;
}

const APVInStoreContext = createContext({} as APVInStoreContextInterface);

export const APVInStoreProvider = APVInStoreContext.Provider;
export const APVInStoreConsumer = APVInStoreContext.Consumer;

export default APVInStoreContext;
