import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import RadioButtonsFilter from '@app/partials/content/Filters/RadioButtonsFilter';
import SliderFilter from '@app/partials/content/Filters/SliderFilter';
import SwitchInputFilter from '@app/partials/content/Filters/SwitchInputFilter';
import InputFilter from '@app/partials/content/InputFilter';
import MultiSelectFilter from '@app/partials/content/MultiSelectFilter';
import Permission from '@app/partials/content/Permission';

type Value = {
    [name: string]: { value: string };
};

type StockVehiclesFilterVehicleBlockProps = {
    facets: any;
    updatePendingFilters: (value: Value) => void;
};

const StockVehiclesFilterVehicleBlock = ({ facets, updatePendingFilters }: StockVehiclesFilterVehicleBlockProps) => {
    const Intl = useIntl();

    return (
        <Col>
            <Card>
                <Card.Header>
                    <i className="card__icon la-2x las la-car text-dark" />
                    {Intl.formatMessage({ id: 'STOCK_VEHICLES.FILTERS.CARD.TITLE.VEHICLE_INFORMATIONS' })}
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <Row>
                                <Col lg={6}>
                                    <div className="d-flex justify-content-between">
                                        <SwitchInputFilter
                                            name="internal_type"
                                            className="switch-input--active-label-right-secondary switch-input--inactive-toggle-lighten switch-input--active-toggle-primary d-flex flex-column flex-column-reverse align-items-start"
                                            format="string"
                                            separator=","
                                            values={[
                                                {
                                                    value: 'vo',
                                                    labelSecond: Intl.formatMessage({ id: 'TRANSLATOR.VO' }),
                                                },
                                                {
                                                    value: 'vd',
                                                    labelSecond: Intl.formatMessage({ id: 'TRANSLATOR.VD' }),
                                                },
                                                {
                                                    value: 'vn',
                                                    labelSecond: Intl.formatMessage({ id: 'TRANSLATOR.VN' }),
                                                },
                                            ]}
                                            pending
                                            onPendingChange={updatePendingFilters}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <label htmlFor="police_number">
                                        {Intl.formatMessage({
                                            id: 'STOCK_VEHICLES.FILTERS.LABEL.VEHICLE.POLICE_NUMBER',
                                        })}
                                    </label>
                                    <InputFilter
                                        name="police_number"
                                        pending
                                        onPendingChange={updatePendingFilters}
                                        placeholder="123456"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col lg={6}>
                                    <label htmlFor="license_number">
                                        {Intl.formatMessage({
                                            id: 'STOCK_VEHICLES.FILTERS.LABEL.VEHICLE.LICENSE_NUMBER',
                                        })}
                                    </label>
                                    <InputFilter
                                        name="license_number"
                                        pending
                                        onPendingChange={updatePendingFilters}
                                        placeholder="AA-111-BB"
                                    />
                                </Col>
                                <Col lg={6}>
                                    <label htmlFor="serial_number">
                                        {Intl.formatMessage({
                                            id: 'STOCK_VEHICLES.FILTERS.LABEL.VEHICLE.SERIAL_NUMBER',
                                        })}
                                    </label>
                                    <InputFilter
                                        name="serial_number"
                                        pending
                                        onPendingChange={updatePendingFilters}
                                        placeholder="4AUTO59H3RE444444"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col lg={12}>
                                    <label htmlFor="brand">
                                        {Intl.formatMessage({ id: 'STOCK_VEHICLES.FILTERS.LABEL.VEHICLE.BRAND' })}
                                    </label>
                                    <MultiSelectFilter
                                        name="brand"
                                        display="comma"
                                        optionLabel="name"
                                        optionValue="key"
                                        placeholder={Intl.formatMessage({ id: 'TRANSLATOR.SELECT' })}
                                        options={
                                            facets?.brands &&
                                            facets.brands.map((item: { name: string; key: string }) => ({
                                                name: item.key,
                                                key: item.key,
                                            }))
                                        }
                                        resultType="string"
                                        filter
                                        onPendingChange={updatePendingFilters}
                                        labelPanelFooterTemplate="STOCK_VEHICLES.FILTERS.VEHICLE.SELECT.FOOTER.BACK"
                                        selectedItemsLabel="STOCK_VEHICLES.FILTERS.VEHICLE.SELECT.BRAND.ITEMS"
                                        clearButton
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col lg={12}>
                                    <label htmlFor="model">
                                        {Intl.formatMessage({ id: 'STOCK_VEHICLES.FILTERS.LABEL.VEHICLE.MODEL' })}
                                    </label>
                                    <MultiSelectFilter
                                        name="model"
                                        display="comma"
                                        optionLabel="name"
                                        optionValue="key"
                                        placeholder={Intl.formatMessage({ id: 'TRANSLATOR.SELECT' })}
                                        options={
                                            facets?.models &&
                                            facets.models.map((item: { name: string; key: string }) => ({
                                                name: item.key,
                                                key: item.key,
                                            }))
                                        }
                                        resultType="string"
                                        filter
                                        onPendingChange={updatePendingFilters}
                                        labelPanelFooterTemplate="STOCK_VEHICLES.FILTERS.VEHICLE.SELECT.FOOTER.BACK"
                                        selectedItemsLabel="STOCK_VEHICLES.FILTERS.VEHICLE.SELECT.MODEL.ITEMS"
                                        clearButton
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col lg={12}>
                                    <label htmlFor="warranty">
                                        {Intl.formatMessage({ id: 'STOCK_VEHICLES.FILTERS.LABEL.VEHICLE.WARRANTY' })}
                                    </label>
                                    <InputFilter
                                        name="warranty"
                                        pending
                                        onPendingChange={updatePendingFilters}
                                        placeholder="Garantie Constructeur..."
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col lg={12}>
                                    <label htmlFor="body_color">
                                        {Intl.formatMessage({ id: 'STOCK_VEHICLES.FILTERS.LABEL.VEHICLE.COLOR' })}
                                    </label>
                                    <MultiSelectFilter
                                        name="body_color"
                                        display="comma"
                                        optionLabel="name"
                                        optionValue="key"
                                        placeholder={Intl.formatMessage({ id: 'TRANSLATOR.SELECT' })}
                                        options={
                                            facets?.body_colors &&
                                            facets.body_colors.map((item: { name: string; key: string }) => ({
                                                name: item.key,
                                                key: item.key,
                                            }))
                                        }
                                        resultType="string"
                                        filter
                                        onPendingChange={updatePendingFilters}
                                        labelPanelFooterTemplate="STOCK_VEHICLES.FILTERS.VEHICLE.SELECT.FOOTER.BACK"
                                        selectedItemsLabel="STOCK_VEHICLES.FILTERS.VEHICLE.SELECT.COLOR.ITEMS"
                                        clearButton
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col lg={12}>
                                    <label htmlFor="postcode_city">
                                        {Intl.formatMessage({ id: 'STOCK_VEHICLES.FILTERS.LABEL.VEHICLE.CITY' })}
                                    </label>
                                    <InputFilter
                                        name="postcode_city"
                                        pending
                                        onPendingChange={updatePendingFilters}
                                        placeholder="Code postal ou ville"
                                    />
                                </Col>
                            </Row>
                            <Permission permissions={['STOCK_VIEW_CRVO', 'STOCK_VIEW_PRICE_MANAGER']} hasAny>
                                <Row className="mt-3">
                                    <Col lg={8}>
                                        <Table className="table-borderless table-condensed table-hover">
                                            <thead>
                                                <tr>
                                                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                                    <th />
                                                    <th>
                                                        <div className="d-flex justify-content-around">
                                                            <span>
                                                                {Intl.formatMessage({
                                                                    id: 'STOCK_VEHICLES.FILTERS.LABEL.BUTTON.RADIO.YES',
                                                                })}
                                                            </span>
                                                            <span>
                                                                {Intl.formatMessage({
                                                                    id: 'STOCK_VEHICLES.FILTERS.LABEL.BUTTON.RADIO.NO',
                                                                })}
                                                            </span>
                                                            <span>
                                                                {Intl.formatMessage({
                                                                    id: 'STOCK_VEHICLES.FILTERS.LABEL.BUTTON.RADIO.ALL',
                                                                })}
                                                            </span>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <Permission permissions={['STOCK_VIEW_CRVO']}>
                                                    <tr>
                                                        <td>
                                                            {Intl.formatMessage({
                                                                id: 'STOCK_VEHICLES.FILTERS.VEHICLE.SWITCH.CRVO',
                                                            })}
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <RadioButtonsFilter
                                                                    name="is_crvo"
                                                                    className="d-flex justify-content-around"
                                                                    filterName={Intl.formatMessage({
                                                                        id: 'STOCK_VEHICLES.FILTERS.VEHICLE.SWITCH.CRVO',
                                                                    })}
                                                                    values={[
                                                                        {
                                                                            key: 'oui',
                                                                            value: true,
                                                                        },
                                                                        {
                                                                            key: 'non',
                                                                            value: false,
                                                                        },
                                                                        {
                                                                            key: 'tous',
                                                                            value: '',
                                                                        },
                                                                    ]}
                                                                    defaultValue=""
                                                                    pending
                                                                    onPendingChange={updatePendingFilters}
                                                                    displayLabel={false}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </Permission>
                                                <Permission permissions={['STOCK_VIEW_PRICE_MANAGER']}>
                                                    <tr>
                                                        <td className="text-dark-orange">
                                                            {Intl.formatMessage({
                                                                id: 'STOCK_VEHICLES.FILTERS.VEHICLE.SWITCH.PRICING',
                                                            })}
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <RadioButtonsFilter
                                                                    name="has_pricing_reco"
                                                                    className="d-flex justify-content-around"
                                                                    filterName={Intl.formatMessage({
                                                                        id: 'STOCK_VEHICLES.FILTERS.VEHICLE.SWITCH.PRICING',
                                                                    })}
                                                                    values={[
                                                                        {
                                                                            key: 'oui',
                                                                            value: true,
                                                                        },
                                                                        {
                                                                            key: 'non',
                                                                            value: false,
                                                                        },
                                                                        {
                                                                            key: 'tous',
                                                                            value: '',
                                                                        },
                                                                    ]}
                                                                    defaultValue=""
                                                                    pending
                                                                    onPendingChange={updatePendingFilters}
                                                                    displayLabel={false}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </Permission>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Permission>
                        </Col>
                        <Col className="d-flex flex-column justify-content-around">
                            <Row>
                                <Col lg={12}>
                                    <label htmlFor="mileage">
                                        {Intl.formatMessage({ id: 'STOCK_VEHICLES.FILTERS.LABEL.VEHICLE.MILEAGE' })}
                                    </label>
                                    <SliderFilter
                                        name="mileage"
                                        className="w-100"
                                        min={0}
                                        max={650000}
                                        suffix=" km"
                                        pending
                                        onPendingChange={updatePendingFilters}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col lg={12}>
                                    <label htmlFor="age">
                                        {Intl.formatMessage({ id: 'STOCK_VEHICLES.FILTERS.LABEL.VEHICLE.AGE' })}
                                    </label>
                                    <SliderFilter
                                        name="age"
                                        className="w-100"
                                        min={0}
                                        max={240}
                                        suffix=" mois"
                                        pending
                                        onPendingChange={updatePendingFilters}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <label htmlFor="price">
                                        {Intl.formatMessage({ id: 'STOCK_VEHICLES.FILTERS.LABEL.VEHICLE.PRICE' })}
                                    </label>
                                    <SliderFilter
                                        name="price"
                                        className="w-100"
                                        min={3500}
                                        max={465000}
                                        suffix=" €"
                                        pending
                                        onPendingChange={updatePendingFilters}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <label htmlFor="in_stock">
                                        {Intl.formatMessage({ id: 'STOCK_VEHICLES.FILTERS.LABEL.VEHICLE.STORAGE' })}
                                    </label>
                                    <SliderFilter
                                        name="in_stock"
                                        className="w-100"
                                        min={0}
                                        max={500}
                                        suffix=" jours"
                                        pending
                                        onPendingChange={updatePendingFilters}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default StockVehiclesFilterVehicleBlock;
