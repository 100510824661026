// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from '@hookform/devtools';
import { startCase } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';

import useModal from '@app/hooks/useModal';

import { isMobile, isPhone, parsedPhoneNumber, phoneFormatter } from '@app/helpers/FormHelper';
import { routeTo } from '@app/helpers/RoutesHelper';
import { isAMail } from '@app/helpers/ToolsHelper';

import { Customer } from '@app/crud/customers/customer.type';
import { ContactFusion } from '@app/crud/customers/fusion.crud';
import type { FusionPayload } from '@app/crud/customers/FusionPayload.type';

import { useAppSelector } from '@app/hooks';
import FusionField from '@app/pages/customers/Modals/Fusion/FusionField';
import ROUTES from '@app/router/Routes';

import { getHistory } from '@app/partials/content/GlobalHistory';
import ConfirmModal from '@app/partials/content/modals/ConfirmModal';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

type FusionProps = {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    refresh: () => void;
};

const Fusion = ({ showModal, setShowModal, refresh }: FusionProps) => {
    const Intl = useIntl();
    const { userData } = useAppSelector((state) => state.auth);
    const methods = useForm({ mode: 'onChange' });
    const [loading, setLoading] = useState(false);
    const {
        handleSubmit,
        register,
        errors,
        setValue,
        control,
        trigger,
        formState: { isValid },
    } = methods;
    const { selectedCustomers } = useAppSelector((state) => state.customer);
    const [data, setData] = useState<FusionPayload>();
    const [showConfirmModal, toggleConfirmModal] = useModal(false, 'confirm');
    const locale = userData.context === 'efb' ? 'BE' : 'FR';

    useEffect(() => {
        const contacts: number[] = [];
        const vcuIds: string[] = [];

        selectedCustomers.forEach((customer) => {
            if (customer.id) {
                contacts.push(customer.id);
            } else if (customer.vcuId) {
                vcuIds.push(customer.vcuId);
            }
        });

        const civilities = [
            ...new Set(
                selectedCustomers
                    .map((customer: Customer) => {
                        const civility = customer?.civility?.toLowerCase();
                        if (['mr', 'm'].includes(civility)) {
                            return 'mr';
                        } else if (['mme'].includes(civility)) {
                            return 'mme';
                        }
                        return null;
                    })
                    .filter((value) => !!value),
            ),
        ];
        setData({
            contacts,
            vcuIds,
            civility: civilities.length === 1 ? civilities[0] : null,
        });
    }, [selectedCustomers]);

    const sendFusion = () => {
        setLoading(true);
        ContactFusion(data)
            .then((response) => {
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'CUSTOMER.MODAL.FUSION.SUCCESS' }),
                });

                refresh();

                setShowModal(false);
                toggleConfirmModal(false);
                if (response?.result?.contactId) {
                    getHistory().push(
                        routeTo(ROUTES.CUSTOMER.PATH, {
                            id: response.result.contactId,
                        }),
                    );
                }
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'CUSTOMER.MODAL.FUSION.FORM.ERROR' }),
                });
                toggleConfirmModal(false);
                setShowModal(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onSubmit = () => {
        toggleConfirmModal();
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    useEffect(() => {
        trigger().then((r) => r);
    }, [data]);

    return (
        <>
            <ModalDefault
                size="lg"
                show={showModal}
                loading={loading}
                hideModal={() => {
                    setShowModal(false);
                }}
                icon={<i className="las la-2x la-user-friends" />}
                title={
                    <FormattedHTMLMessage
                        id="CUSTOMER.MODAL.FUSION.TITLE"
                        values={{
                            count: selectedCustomers.length,
                        }}
                    />
                }
                body={
                    <>
                        <p className="text-center">
                            <FormattedHTMLMessage id="CUSTOMER.MODAL.FUSION.DESCRIPTION" />
                        </p>
                        {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
                        <FormProvider {...methods}>
                            <Form.Group className="mb-0">
                                <Form.Label>
                                    <FormattedMessage id="CUSTOMER.MODAL.FUSION.FORM.CIVILITY" />
                                </Form.Label>
                                <div className="d-flex flex-wrap m-0 p-0">
                                    {[
                                        { value: 'mme', labelId: 'TRANSLATOR.MRS' },
                                        { value: 'mr', labelId: 'TRANSLATOR.MR' },
                                    ].map((option) => {
                                        const checked = data?.civility === option.value;
                                        return (
                                            <Form.Check
                                                name="civility"
                                                inline
                                                key={option.value}
                                                type="radio"
                                                id={`civility_${option.value}`}
                                                defaultChecked={checked}
                                                checked={checked}
                                                label={Intl.formatMessage({ id: option.labelId })}
                                                onClick={() => {
                                                    setData({ ...data, civility: option.value });
                                                    setValue('civility', option.value);
                                                }}
                                                value={option.value}
                                                ref={register({
                                                    required: Intl.formatMessage({
                                                        id: 'FORM.ERROR.REQUIRED',
                                                    }),
                                                })}
                                                className={`rollbackUpdateForm__${
                                                    checked ? 'selected' : 'unSelected'
                                                } rounded-pill py-1 pl-2 pr-3 mb-2`}
                                            />
                                        );
                                    })}
                                </div>

                                <ErrorForm errors={errors} name="civility" />
                            </Form.Group>
                            <FusionField
                                data={data}
                                setData={setData}
                                field="firstname"
                                customerKey="firstname"
                                formatter={(value: string) => startCase(value.toLowerCase())}
                            />
                            <FusionField
                                data={data}
                                setData={setData}
                                field="lastname"
                                customerKey="lastname"
                                formatter={(value: string) => value.toUpperCase()}
                            />
                            <FusionField
                                data={data}
                                setData={setData}
                                field="mobile"
                                customerKey="mobile"
                                validation={(value: string | number) => {
                                    if (value && typeof value === 'string') {
                                        const contact = parsedPhoneNumber(value, locale);
                                        if (!contact?.isValid() || !isMobile(value)) {
                                            return Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.CREATE.MOBILE.FORMAT',
                                            });
                                        }
                                    }

                                    return true;
                                }}
                                formatter={(value: string) => phoneFormatter(value.toUpperCase())}
                            />
                            <FusionField
                                data={data}
                                setData={setData}
                                field="phone"
                                customerKey="phone"
                                validation={(value: string | number) => {
                                    if (value && typeof value === 'string') {
                                        const contact = parsedPhoneNumber(value, locale);
                                        const isValidPhoneNumber =
                                            userData.context === 'efb' ? value.length > 0 : contact?.isValid();
                                        if (!isValidPhoneNumber || !isPhone(value)) {
                                            return Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.CREATE.PHONE.FORMAT',
                                            });
                                        }
                                    }

                                    return true;
                                }}
                                formatter={(value: string) => phoneFormatter(value.toUpperCase(), locale)}
                            />
                            <FusionField
                                data={data}
                                setData={setData}
                                field="email"
                                customerKey="email"
                                validation={(value: string | number) => {
                                    if (value && typeof value === 'string' && !isAMail(value)) {
                                        return Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.CREATE.EMAIL.FORMAT',
                                        });
                                    }
                                    return true;
                                }}
                            />
                            <FusionField data={data} setData={setData} field="address" customerKey="address" />
                            <FusionField data={data} setData={setData} field="zipcode" customerKey="zipcode" />
                            <FusionField data={data} setData={setData} field="city" customerKey="city" />
                        </FormProvider>
                    </>
                }
                footer={
                    <div className="d-flex justify-content-between">
                        <Button variant="outline-primary" onClick={() => setShowModal(false)} className="mr-5">
                            <FormattedMessage id="TRANSLATOR.CANCEL" />
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleSubmit(onSubmit, onError)}
                            disabled={isValid === false}
                        >
                            <FormattedMessage id="TRANSLATOR.VALIDATE" />
                        </Button>
                    </div>
                }
            />
            <ConfirmModal
                icon={<i className="las la-2x la-user-friends" />}
                title={
                    <FormattedHTMLMessage
                        id="CUSTOMER.MODAL.FUSION.CONFIRMATION.TITLE"
                        values={{
                            count: selectedCustomers.length,
                        }}
                    />
                }
                description={
                    <FormattedHTMLMessage
                        id="CUSTOMER.MODAL.FUSION.CONFIRMATION.DESCRIPTION"
                        values={{
                            count: selectedCustomers.length,
                        }}
                    />
                }
                showModal={showConfirmModal}
                setShowModal={toggleConfirmModal}
                onConfirm={sendFusion}
                onCancel={() => setShowModal(true)}
                validationCheckbox
                loading={loading}
                checkboxLabel={
                    <FormattedMessage
                        id="CUSTOMER.MODAL.FUSION.CONFIRMATION.CHECKBOX"
                        values={{
                            count: selectedCustomers.length,
                        }}
                    />
                }
                loadingTitle={
                    <FormattedMessage
                        id="CUSTOMER.MODAL.FUSION.CONFIRMATION.LOADING"
                        values={{
                            count: selectedCustomers.length,
                        }}
                    />
                }
                loadingDescription={
                    <FormattedHTMLMessage id="CUSTOMER.MODAL.FUSION.CONFIRMATION.LOADING_DESCRIPTION" />
                }
            />
        </>
    );
};

export default Fusion;
