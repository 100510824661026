import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Button, Image } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { mapSecurywebToFieldForm } from '@app/crud/mapping/securyweb.map';
import { getProducts } from '@app/crud/securyweb/securyweb.crud';
import { Contract } from '@app/crud/securyweb/securyweb.types';

import { useFetch } from '@app/hooks';
import { getStatusClassName, getStatusLabelId } from '@app/pages/securyweb/Helpers/SecurywebHelper';
import { actions } from '@app/store/modal/modal.store';

import Label from '@app/partials/content/Label';

interface ContractRowProps {
    contract: Contract;
}

const ContractRow = ({ contract }: ContractRowProps) => {
    const dispatch = useDispatch();
    const statusClassName = getStatusClassName(contract.statut);
    const statusLabelId = getStatusLabelId(contract.statut);

    const { fetch: fetchProducts, data: products } = useFetch({
        fetchAction: getProducts,
        resultInterceptor: (response) => response.result,
    });

    useEffect(() => {
        if (products && products.length > 0) {
            const mappedData = mapSecurywebToFieldForm({ ...contract });

            dispatch(
                actions.modalChange('contract_securycar_steps', {
                    allProducts: products,
                    formValues: {
                        product: { ...contract.produit },
                        ...mappedData,
                    },
                }),
            );
        }
    }, [products, dispatch]);

    return (
        <div className={classNames('lead-row')} role="presentation">
            <div className="lead-row__type lead-row__type--local" />
            <div className="lead-row__wrapper">
                <div className="lead-row__picture-wrapper">
                    <Image className="lead-row__picture" src="/media/pages/customers/bonhomme-securycar.svg" />
                </div>
                <div className="lead-row__content py-4">
                    <div className="d-lg-flex mb-lg-0 mb-2 d-block align-items-center">
                        <span className="font-size-h3 font-weight-bold text-nowrap">
                            <FormattedMessage id="CUSTOMERS.CONTRACT_ROW.SECURYCAR.TITLE" />
                        </span>
                    </div>
                    <span className={classNames('flex-wrap font-size-sm')}>
                        <Label
                            className={classNames('font-weight-bold label-lg', {
                                [`label-light-${statusClassName}`]: statusClassName,
                            })}
                        >
                            <FormattedMessage id={statusLabelId} />
                        </Label>
                    </span>
                    <div>{contract?.concession?.denomination ?? ''}</div>
                    <div>
                        <FormattedMessage id="CUSTOMERS.LEAD_ROW.LAST_UPDATE" />{' '}
                        {dayjs(contract?.dateModification || contract?.dateCreation).format('DD/MM/YYYY [à] HH [h] mm')}
                    </div>
                    {(contract?.detailsVendeur?.nom || contract?.detailsVendeur?.prenom) && (
                        <div>
                            <FormattedMessage id="CUSTOMERS.LEAD_ROW.REFERENT" />
                            {' : '}
                            <span>
                                {contract?.detailsVendeur?.nom || ''} {contract?.detailsVendeur?.prenom || ''}
                            </span>
                        </div>
                    )}
                </div>
                <div className="lead-row__actions py-lg-4 pt-0 pb-4">
                    <Button variant="danger" onClick={() => fetchProducts(contract?.concession.code)}>
                        <FormattedMessage id="CUSTOMERS.CONTRACT_ROW.SECURYCAR.ACTION" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ContractRow;
