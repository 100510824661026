import React, { useContext, useEffect } from 'react';

import useFetch from '@app/hooks/useFetch';

import { getDashboardCrewActivityForDirector } from '@app/crud/dashboard/dashboard.crud';

import Permission from '@app/partials/content/Permission';

import { DashboardContext } from '../../DashboardProvider';
import DashboardActivityDirector from '../../partials/Activity/DashboardActivityDirector';
import DashboardActivityStock from '../../partials/Activity/DashboardActivityStock';
import DashboardLead from '../../partials/DashboardLead';
import DashboardQlik from '../../partials/Stats/DashboardQlik';

import './_dashboardDirector.scss';

const DashboardDirector = () => {
    const { overriddenParams } = useContext(DashboardContext);
    const { data, loading, fetch } = useFetch({
        fetchAction: getDashboardCrewActivityForDirector,
    });

    useEffect(() => {
        if (overriddenParams) {
            fetch({ params: overriddenParams });
        }
    }, [overriddenParams]);

    return (
        <div className="dashboard-role d-flex flex-column">
            <div className="dashboard-actions h-100 d-flex flex-column">
                {loading ? (
                    <>
                        <DashboardLead.Skeleton />
                        <DashboardActivityDirector.Skeleton />
                    </>
                ) : (
                    <>
                        <DashboardLead data={data} className="w-100 dashboard-lead--row" />
                        <DashboardActivityDirector data={data} className="w-100 mt-8 flex-grow-1" />
                    </>
                )}
            </div>
            <Permission permissions={['STOCK_LIST_VEHICLES']}>
                <DashboardActivityStock />
            </Permission>
            <DashboardQlik className="w-100 mt-8" />
        </div>
    );
};

export default DashboardDirector;
