import React from 'react';
import { Image } from 'react-bootstrap';

type LeadRowImageProps = {
    type: string;
};

type LeadTypeImage = {
    [key: string]: {
        src: string;
        className?: string;
    };
};

const leadTypeImage: LeadTypeImage = {
    constructeur: {
        src: '/media/pages/customers/fiche-client-manufacturer.svg',
    },
    local: {
        src: '/media/pages/customers/fiche-client-lead.svg',
    },
    local_home_charge: {
        src: '/media/pages/customers/bonhomme-borne.svg',
    },
    fidelisation: {
        className: 'lead-row__picture--marketing-campaign',
        src: '/media/pages/customers/bonhomme-lead-fidelisation.svg',
    },
    local_apv: {
        src: '/media/pages/customers/fiche-client-lead-apv.svg',
    },
    marketing_campaign: {
        className: 'lead-row__picture--marketing-campaign',
        src: '/media/pages/customers/fiche-client-marketing-campaign.svg',
    },
    default: {
        className: 'lead-row__picture--online',
        src: '/media/pages/customers/fiche-client-lead-online-mettre-photo-voiture-dedans.svg',
    },
};

const getLeadImage = (type: string) => {
    if (Object.prototype.hasOwnProperty.call(leadTypeImage, type)) {
        return leadTypeImage[type];
    }
    return leadTypeImage.default;
};

const LeadRowImage = ({ type }: LeadRowImageProps) => {
    const leadImage = getLeadImage(type);

    return <Image className={`lead-row__picture ${leadImage.className && leadImage.className}`} src={leadImage.src} />;
};

export default LeadRowImage;
