import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import useFetch from '@app/hooks/useFetch';
import useWindowFocus from '@app/hooks/useWindowFocus';

import { getAlerts } from '@app/crud/administration/alerts';

import { useAppDispatch } from '@app/hooks';
import { actions as modalActions } from '@app/store/modal/modal.store';

import AlertsModal from './components/AlertsModal';
import { getSeenAlerts } from './helpers/AlertsOngoingHelper';

import './Alerts.scss';

const refreshDelay = 5 * 60 * 1000; // 5 minutes

/**
 * {@link https://pga-digital.atlassian.net/wiki/spaces/AF/pages/181371069/Alertes+dans+Automanager See documentation on Confluence}
 * @returns
 */
const Alerts = () => {
    const Intl = useIntl();
    const dispatch = useAppDispatch();
    const seenAlerts = getSeenAlerts();
    const focused = useWindowFocus();

    const { fetch, data: alerts } = useFetch({
        fetchAction: getAlerts,
    });

    const displayAlertsModal = () => {
        dispatch(modalActions.modalChange('alerts'));
    };

    const fetchData = () => {
        if (focused) fetch().then().catch();
    };

    useEffect(() => {
        fetchData();

        const intervalId = setInterval(fetchData, refreshDelay);

        return () => clearInterval(intervalId);
    }, [focused]);

    useEffect(() => {
        const unseenAlerts = alerts?.filter((item) => !seenAlerts.includes(item.id.toString()));

        if (unseenAlerts.length > 0) displayAlertsModal();
    }, [alerts]);

    return (
        alerts.length > 0 && (
            <>
                <button
                    type="button"
                    className="btn-alerts"
                    onClick={() => displayAlertsModal()}
                    title={Intl.formatMessage({ id: 'ALERTS.VIEW_ALL' })}
                >
                    <span
                        className={classNames('btn-alerts__counter', {
                            'btn-alerts__counter--no-padding': alerts.length < 10,
                        })}
                    >
                        {alerts.length === 1 ? '!' : alerts.length}
                    </span>
                    <i className="btn-alerts__icon la la-bell pr-0" />
                </button>
                <AlertsModal alerts={alerts} />
            </>
        )
    );
};

export default Alerts;
