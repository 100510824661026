import React, { useEffect, useState } from 'react';

import useModal from '@app/hooks/useModal';

import { useAppSelector } from '@app/hooks';

import Modal from '@app/partials/HoC/Modal';

import DashboardHeader from './DashboardHeader';
import DashboardProvider from './DashboardProvider';
import AgendaDetails from './modals/AgendaDetails';
import RankingDetails from './modals/RankingDetails';
import DashboardAchatCash from './partials/Stats/DashboardAchatCash';
import DashboardStatApv from './partials/Stats/DashboardStatApv';
import DashboardStatManagerAfrContact from './partials/Stats/DashboardStatManagerAfrContact';
import DashboardStats from './partials/Stats/DashboardStats';
import DashboardApv from './roles/apv/DashboardApv';
import DefaultWipTemplate from './roles/defaultWip/DefaultWipTemplate';
import DashboardDirector from './roles/director/DashboardDirector';
import DashboardManager from './roles/manager/DashboardManager';
import DashboardManagerAutopshereContact from './roles/managerAfrContact/DashboardManagerAutosphereContact';
import DashboardSeller from './roles/seller/DashboardSeller';

import './_dashboard.scss';

export default function Dashboard() {
    const [showAgendaDetails, setShowAgendaDetails] = useModal(false, 'agenda_details');
    const [showRankingDetails, setShowRankingDetails] = useModal(false, 'ranking_details');
    const { userData } = useAppSelector((state) => state.auth);
    const [params, setParams] = useState<any>({ dealership: userData?.default_dealership });

    const [overriddenParams, setOverriddenParams] = useState();

    let main = null;
    let role = null;
    let hideFilterCustomers = false;
    let hideFilters = false;
    switch (userData?.role?.slug) {
        case 'conseiller-commercial':
        case 'conseiller-achat-cash-concession':
        case 'conseiller-achat-cash-distance':
            main = <DashboardSeller displayProgressCircle />;
            role = 'conseiller-commercial';
            break;
        case 'manager':
        case 'manager-achats-cash':
        case 'planificateur':
            main = <DashboardManager />;
            role = 'manager';
            break;
        case 'responsable-apv':
            main = <DashboardManagerAutopshereContact />;
            role = 'responsable-apv';
            break;
        case 'conseiller-commercial-services':
            main = <DashboardApv />;
            role = 'ccs-apv';
            break;
        case 'manager-autosphere-contact':
            main = <DashboardManagerAutopshereContact />;
            role = 'manager-autosphere-contact';
            break;
        case 'agent-autosphere-contact':
            main = <DashboardSeller displayProgressCircle={false} />;
            role = 'agent-autosphere-contact';
            break;
        case 'agent-fid':
            main = <DefaultWipTemplate name="Fidélisation" image="dashboard-fidelisation-coming-soon.svg" />;
            role = 'agent-fid';
            hideFilters = true;
            break;
        default:
            main = <DashboardDirector />;
            role = 'directeur';
    }

    let stats = null;
    let calendar = false;
    switch (userData?.role?.slug) {
        case 'manager-achats-cash':
        case 'planificateur':
        case 'conseiller-achat-cash-concession':
        case 'conseiller-achat-cash-distance':
            stats = <DashboardAchatCash className="mt-xl-0 mt-8" />;
            calendar = true;
            break;
        case 'responsable-apv':
            stats = <DashboardStatApv />;
            break;
        case 'conseiller-commercial-services':
            stats = <DashboardStatApv />;
            hideFilterCustomers = true;
            break;
        case 'agent-autosphere-contact':
            stats = <DashboardStatManagerAfrContact />;
            hideFilterCustomers = true;
            break;
        case 'manager-autosphere-contact':
            stats = <DashboardStatManagerAfrContact />;
            break;
        case 'conseiller-commercial':
            hideFilterCustomers = true;
            stats = <DashboardStats className="mt-xl-0 mt-8" />;
            break;
        case 'agent-fid':
            stats = null;
            calendar = false;
            break;
        default:
            stats = <DashboardStats className="mt-xl-0 mt-8" />;
    }

    useEffect(() => {
        const newParams = { ...params };

        const dealership = newParams?.dealership;
        delete newParams?.dealership;

        const dealer = newParams?.dealer;
        delete newParams?.dealer;

        if (dealership) {
            newParams.dealershipId = dealership;
        }

        if (dealer) {
            newParams.dealerId = dealer?.id;
        }

        setOverriddenParams(newParams);
    }, [role, hideFilterCustomers, params]);

    return (
        <DashboardProvider value={{ params, setParams, role, overriddenParams }}>
            <div className="d-flex flex-column h-100">
                <DashboardHeader
                    calendar={calendar}
                    hideFilterCustomers={hideFilterCustomers}
                    hideFilters={hideFilters}
                />
                <div className={`dashboard dashboard-${role} h-100 d-flex flex-xl-row flex-column`}>
                    {main}
                    {stats}
                </div>
            </div>
            <AgendaDetails showModal={showAgendaDetails} setShowModal={setShowAgendaDetails} />
            <Modal
                As={RankingDetails}
                showModal={showRankingDetails}
                setShowModal={setShowRankingDetails}
                params={params}
            />
        </DashboardProvider>
    );
}
