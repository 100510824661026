import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Label from '@app/partials/content/Label';

import { DashboardContext } from '../../DashboardProvider';
import { CURRENT_MONTH, PREVIOUS_MONTH } from '../../helpers/DashboardHelper';

const DashboardMonth = () => {
    const { setParams } = useContext(DashboardContext);
    const [activeMonth, setActiveMonth] = useState(CURRENT_MONTH);

    const handleUpdatedActiveMonth = (period) => {
        setActiveMonth(period);
        setParams((params) => ({
            ...params,
            currentPeriod: period,
        }));
    };

    return (
        <div className="dashboard-month d-flex text-nowrap">
            <Label
                className={classNames('font-weight-semibold cursor-pointer label-square', {
                    'label-light-closed': activeMonth === CURRENT_MONTH,
                    'label-disabled-dark-light': activeMonth !== CURRENT_MONTH,
                })}
                onClick={() => {
                    handleUpdatedActiveMonth(CURRENT_MONTH);
                }}
            >
                <FormattedMessage id="DASHBOARD.MONTH.CURRENT" />
            </Label>
            <Label
                className={classNames('font-weight-semibold ml-2 cursor-pointer label-square', {
                    'label-light-closed': activeMonth === PREVIOUS_MONTH,
                    'label-disabled-dark-light': activeMonth !== PREVIOUS_MONTH,
                })}
                onClick={() => {
                    handleUpdatedActiveMonth(PREVIOUS_MONTH);
                }}
            >
                <FormattedMessage id="DASHBOARD.MONTH.PREVIOUS" />
            </Label>
        </div>
    );
};

export default DashboardMonth;
