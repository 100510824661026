import classNames from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';

import { Dealerships, PendingLeads } from '@app/crud/dashboard/dashboard.crud';

import ROUTES from '@app/router/Routes';

import { getTotalPendingLeads } from '../helpers/DashboardHelper';

interface DashboardLeadProps {
    data?: Dealerships;
    pendingLeads?: PendingLeads[];
    className?: string;
    displayApv?: boolean;
}

const DashboardLead = ({ data, pendingLeads, className, displayApv }: DashboardLeadProps) => {
    const [state, setState] = useState<{
        pendingLeads: PendingLeads[] | null;
        total: number | null;
    }>({
        pendingLeads: pendingLeads ?? null,
        total: null,
    });

    useEffect(() => {
        setState({
            pendingLeads: [],
            total: null,
        });

        if (pendingLeads || data) {
            const leads =
                pendingLeads ??
                Object.values(
                    Object.values(data || {}).reduce((acc: any, curr: any) => {
                        curr?.pendingLeads?.forEach((pendingLead: PendingLeads) => {
                            if (!acc[pendingLead.slug]) {
                                acc[pendingLead.slug] = pendingLead;
                            } else {
                                acc[pendingLead.slug].count += pendingLead.count;
                            }
                        });

                        return acc;
                    }, {}),
                );

            setState({
                pendingLeads: leads,
                total: getTotalPendingLeads(leads),
            });
        }
    }, [data, pendingLeads]);

    if (state?.total === null) {
        return <DashboardLead.Skeleton />;
    }

    return (
        <Link
            className={classNames('dashboard-lead', className)}
            to={{
                pathname: routeTo(ROUTES.LEADS_ONLINE.PATH, {
                    statusType: 'new',
                }),
            }}
        >
            <div className="font-size-lg font-weight-bold d-flex text-white align-items-center">
                <i className="text-white las la-2x la-bullseye mr-2" />
                {!displayApv ? (
                    <FormattedMessage id="DASHBOARD.LEAD.TITLE" />
                ) : (
                    <FormattedMessage id="DASHBOARD.APV.TITLE" />
                )}
            </div>
            <div className="dashboard-lead__counters text-white d-flex align-items-center">
                <div className="dashboard-lead__total font-weight-bold">{state?.total}</div>
                <div className="dashboard-lead__scroll">
                    {!displayApv && (
                        <div className="dashboard-lead__list">
                            {state?.pendingLeads &&
                                state.pendingLeads
                                    .filter((pendingLead) => pendingLead?.count)
                                    .map((pendingLead) => (
                                        <div key={pendingLead.slug} className="font-size-h5 line-height-lg">
                                            {pendingLead?.name} : <strong>{pendingLead?.count}</strong>
                                        </div>
                                    ))}
                        </div>
                    )}
                    {displayApv && (
                        <p className="mt-9 font-size-h5 line-height-lg">
                            <FormattedHTMLMessage id="DASHBOARD.LEAD.APV.TITLE" />
                        </p>
                    )}
                </div>
            </div>
        </Link>
    );
};

DashboardLead.defaultProps = {
    data: '',
    pendingLeads: '',
    className: '',
    displayApv: false,
};

DashboardLead.Skeleton = () => <Skeleton borderRadius="10px" className="w-100 p-20" />;

export default DashboardLead;
