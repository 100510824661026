import dayjs from 'dayjs';

import { stripAccents } from '@app/helpers/StringHelper';

import { StatusContractSlug } from '@app/crud/securyweb/securyweb.types';

const mappingFieldsRequired = {
    id: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    offer: {
        [StatusContractSlug.DRAFT]: true,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    dealership: {
        [StatusContractSlug.DRAFT]: true,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    dealer: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    product: {
        [StatusContractSlug.DRAFT]: true,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    initialDuration: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    subscriptionDate: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    priceWithTaxes: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    priceWithoutTaxe: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    iban: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    bic: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    secretaryId: {
        [StatusContractSlug.DRAFT]: true,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    status: {
        [StatusContractSlug.DRAFT]: true,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.civility': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.language': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.lastname': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.firstname': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.addressFloor': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.addressBuilding': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.address': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.addressPlaceCalled': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.zipcode': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.city': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.localityComplement': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.phone': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.mobile': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.email': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.birthDate': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.maritalStatus': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.proCategory': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.siren': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.companyName': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.civility': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.lastname': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.firstname': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.addressFloor': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.addressBuilding': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.address': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.addressPlaceCalled': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.zipcode': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.city': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.localityComplement': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.phone': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.mobile': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.email': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.birthDate': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.maritalStatus': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.proCategory': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.registrationNumber': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.registrationNumberDate': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.vin': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.registrationDocumentDate': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.mine': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.brand': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.vehicleCategory': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.model': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.fundingPrice': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.fundingMode': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
};

const mappingFieldsDisabled = {
    dealership: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    dealer: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    securywebId: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    product: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    initialDuration: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    subscriptionDate: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    priceWithTaxes: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    priceWithoutTaxe: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    iban: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    bic: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.civility': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.language': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.lastname': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.firstname': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.addressFloor': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.addressBuilding': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.address': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.addressPlaceCalled': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.zipcode': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.city': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.localityComplement': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.phone': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.mobile': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.email': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.birthDate': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.maritalStatus': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.proCategory': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.siren': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.companyName': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.civility': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.lastname': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.firstname': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.addressFloor': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.addressBuilding': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.address': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.addressPlaceCalled': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.zipcode': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.city': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.localityComplement': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.phone': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.mobile': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.email': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.birthDate': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.maritalStatus': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.proCategory': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.registrationNumber': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.registrationNumberDate': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.vin': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.registrationDocumentDate': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.mine': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.brand': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.vehicleCategory': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.model': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.fundingPrice': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.fundingMode': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
};

export const isFieldRequired = (
    fieldName: keyof typeof mappingFieldsRequired,
    contractStatus: StatusContractSlug.DRAFT | StatusContractSlug.TO_SIGN | StatusContractSlug.VALID,
) => mappingFieldsRequired[fieldName][contractStatus] ?? true;

export const isFieldDisabled = (
    fieldName: keyof typeof mappingFieldsDisabled,
    contractStatus: StatusContractSlug.DRAFT | StatusContractSlug.TO_SIGN | StatusContractSlug.VALID,
) => mappingFieldsDisabled[fieldName][contractStatus] ?? true;

export const formatFieldBeforeSend = (data: any) => {
    data.subscriber.firstname = stripAccents(data?.subscriber?.firstname?.toUpperCase?.());
    data.subscriber.lastname = stripAccents(data?.subscriber?.lastname?.toUpperCase?.());
    data.beneficiary.firstname = stripAccents(data?.beneficiary?.firstname?.toUpperCase?.());
    data.beneficiary.lastname = stripAccents(data?.beneficiary?.lastname?.toUpperCase?.());
    data.offerFamily = data.offer === 'FAMILY';
    data.offerCollab = data.offer === 'COLLAB';
    if (data.subscriptionDate) {
        data.subscriptionDate = dayjs(data.subscriptionDate).format('YYYY-MM-DD');
    }
    return data;
};
