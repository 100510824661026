import { Skeleton } from 'primereact/skeleton';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { empty } from '@app/helpers/ToolsHelper';

import { createCustomerNote, getCustomerNotes } from '@app/crud/customers/customer.crud';
import { NotesResult } from '@app/crud/note/note.type';

import { useAppSelector, useFetch } from '@app/hooks';
import CreateNoteForm from '@app/pages/customers/Modals/Note/CreateNoteForm';
import NoteCreateButtons from '@app/pages/customers/Modals/Note/NoteCreateButtons';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

import { ChatItemProps } from '@app/components/ChatTimeline/ChatItem';
import ChatTimeline from '@app/components/ChatTimeline/ChatTimeline';

type noteParamsProps = {
    [key in keyof NotesResult['count']]: {
        color: 'primary' | 'success';
    };
};

const noteParams: Omit<noteParamsProps, 'total'> = {
    sales: {
        color: 'primary',
    },
    apv: {
        color: 'success',
    },
};

type NotesProps = {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
};

const Notes = ({ showModal, setShowModal }: NotesProps) => {
    const Intl = useIntl();
    const form = useForm();
    const [activeTab, setActiveTab] = useState('all');
    const [editorMode, setEditorMode] = useState(false);
    const { data: customer } = useAppSelector((state) => state.customer.customer);
    const [formSubmitting, setFormSubmitting] = useState(false);

    const {
        fetch: fetchNotes,
        data: notesData,
        loading: fetchNotesLoading,
    } = useFetch({
        fetchAction: getCustomerNotes,
    });

    useEffect(() => {
        if (showModal === true && customer?.id) {
            fetchNotes(customer.id);
        }
    }, [customer.id, fetchNotes, showModal]);

    const createNoteAction = (type: string) => {
        setActiveTab(type);
        setEditorMode(true);
    };

    const onSubmitMessage = async ({ message }: { message: string }) => {
        setFormSubmitting(true);
        try {
            await createCustomerNote({
                message,
                type: activeTab,
                contactId: customer.id,
            });

            setEditorMode(false);
            toast({
                variant: 'success',
                message: Intl.formatMessage({ id: 'USER.PROFILE.SETTINGS.UPDATE.SUCCESS' }),
            });

            fetchNotes(customer.id);
        } catch (error) {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CONTACT.ERROR' }),
            });
        } finally {
            setFormSubmitting(false);
        }
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const isEditMode = useMemo(() => {
        if (activeTab === 'all' || empty(notesData) || fetchNotesLoading) {
            return false;
        }

        return editorMode || notesData?.count[activeTab as keyof typeof notesData.count] === 0;
    }, [activeTab, editorMode, fetchNotesLoading, notesData]);

    const formattedNotes = useMemo(
        () =>
            !empty(notesData)
                ? notesData?.notes.map(
                      (note) =>
                          ({
                              id: note.id,
                              author: `${note.ownerFirstname !== null ? note.ownerFirstname : ''} ${
                                  note.ownerLastname !== null ? note.ownerLastname : ''
                              }`,
                              job: Intl.formatMessage({
                                  id: `CUSTOMERS.MODAL.NOTES.TYPE_MESSAGE.${note.type.toUpperCase()}`,
                              }),
                              date: note.created,
                              position: note.type === 'sales' ? 'right' : 'left',
                              color: note.type === 'sales' ? 'primary' : 'success',
                              noteType: note.noteType || null,
                              message: note.message,
                              takeable: note.takeable,
                              reader: note.reader,
                              dateRead: note.dateRead,
                          } as ChatItemProps),
                  )
                : [],
        [Intl, notesData],
    );

    return (
        <ModalDefault
            show={showModal}
            loading={fetchNotesLoading || formSubmitting}
            icon={<i className="la la-2x text-primary la-edit" />}
            hideModal={() => setShowModal(false)}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.NOTES.TITLE' })}
            body={
                <div>
                    <div className="text-center">
                        {fetchNotesLoading || empty(notesData) ? (
                            <Skeleton className="bg-white" height="200px" />
                        ) : (
                            <>
                                <Tabs
                                    defaultActiveKey={activeTab}
                                    activeKey={activeTab}
                                    className="d-inline-flex nav-tabs-line nav-tabs-line-primary nav-tabs-line-2x"
                                    onSelect={(e) => setActiveTab(e)}
                                >
                                    <Tab
                                        eventKey="all"
                                        tabClassName="font-size-h3 font-weight-semibold"
                                        title={`${Intl.formatMessage({ id: 'CUSTOMERS.MODAL.NOTES.TAB.ALL' })} (${
                                            notesData?.count?.total ?? 0
                                        })`}
                                    >
                                        {notesData?.count?.total === 0 ? (
                                            <div className="text-center">
                                                <div className="mb-5 mt-6">
                                                    {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.NOTES.EMPTY' })}
                                                </div>
                                                <NoteCreateButtons handleCreate={createNoteAction} />
                                            </div>
                                        ) : (
                                            <div>
                                                <NoteCreateButtons handleCreate={createNoteAction} />
                                                <ChatTimeline messages={formattedNotes} singleMode />
                                            </div>
                                        )}
                                    </Tab>
                                    {Object.keys(noteParams).map((type) => {
                                        const counter = notesData.count[type as keyof typeof notesData.count];
                                        const position = type === 'sales' ? 'right' : 'left';
                                        return (
                                            <Tab
                                                key={type}
                                                eventKey={type}
                                                tabClassName="font-size-h3 font-weight-semibold"
                                                title={`${Intl.formatMessage({
                                                    id: `CUSTOMERS.MODAL.NOTES.TAB.${type.toUpperCase()}`,
                                                })} (${counter ?? 0})`}
                                            >
                                                {counter > 0 && !editorMode && (
                                                    <>
                                                        <Button
                                                            variant={type === 'sales' ? 'primary' : 'success'}
                                                            className="mt-3"
                                                            onClick={() => createNoteAction(type)}
                                                        >
                                                            {Intl.formatMessage({
                                                                id: 'CUSTOMERS.MODAL.NOTES.ACTION.CREATE',
                                                            })}
                                                        </Button>
                                                        <ChatTimeline
                                                            messages={formattedNotes.filter(
                                                                (r) => r.position === position,
                                                            )}
                                                            singleMode
                                                        />
                                                    </>
                                                )}
                                            </Tab>
                                        );
                                    })}
                                </Tabs>
                                {isEditMode && (
                                    <CreateNoteForm
                                        form={form}
                                        title={`${Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.NOTES.ACTION.CREATE.TITLE',
                                        })} ${Intl.formatMessage({
                                            id: `CUSTOMERS.MODAL.NOTES.TAB.${activeTab.toUpperCase()}`,
                                        })}`}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
            }
            footer={
                <div className="text-center">
                    {isEditMode ? (
                        <>
                            <Button
                                variant="outline-secondary"
                                onClick={() => {
                                    setEditorMode(false);
                                    setActiveTab('all');
                                }}
                            >
                                {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                            </Button>
                            <Button
                                variant="primary"
                                className="ml-3"
                                onClick={form.handleSubmit(onSubmitMessage, onError)}
                            >
                                {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.NOTES.ACTION.SAVE' })}
                            </Button>
                        </>
                    ) : (
                        <Button variant="outline-secondary" onClick={() => setShowModal(false)}>
                            {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                        </Button>
                    )}
                </div>
            }
        />
    );
};

export default Notes;
