import classNames from 'classnames';
import { motion } from 'framer-motion';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';

import useFetch from '@app/hooks/useFetch';
import useFilters from '@app/hooks/useFilters';

import {
    CrewActivity,
    CrewActivityResponse,
    Dealership,
    getDashboardCrewActivity,
} from '@app/crud/dashboard/dashboard.crud';

import Expander from '@app/partials/content/Expander';
import Loader from '@app/partials/content/Loader';

import { linkToLeadWithFilters } from '../../helpers/DashboardHelper';

import DashboardActivityTimeline from './DashboardActivityTimeline';

const variants = {
    shown: {
        opacity: 1,
        height: 'auto',
        overflow: 'hidden',
        transition: {
            ease: 'easeOut',
        },
    },
    hidden: {
        opacity: 0,
        height: 0,
        overflow: 'hidden',
        transition: {
            ease: 'easeOut',
        },
    },
};

type DashboardActivityDealershipProps = {
    dealership: Dealership;
};

const DashboardActivityDealership = ({ dealership }: DashboardActivityDealershipProps) => {
    const [collapsed, setCollapsed] = useState(true);
    const history = useHistory();

    const {
        fetch: fetchDealers,
        data: dealers,
        loading: loadingDealers,
        setData: setDealers,
    } = useFetch<string, CrewActivityResponse, CrewActivity[]>({
        fetchAction: getDashboardCrewActivity,
        resultInterceptor: (response) => response?.result?.[dealership.dealershipId]?.dealers,
    });

    const { updateFilters } = useFilters({
        name: `leads_online_action-to-do`,
    });

    const { updateFilters: updateActiveLeadsFilters } = useFilters({
        name: `leads_online_all`,
    });

    useEffect(() => {
        if (!collapsed) {
            fetchDealers(dealership.dealershipId);
        }
    }, [fetchDealers, dealership, collapsed]);

    return (
        <div>
            <Divider className="my-2" type="dashed" />
            <div
                role="presentation"
                className="d-flex justify-content-between align-items-center cursor-pointer"
                onClick={() => setCollapsed(!collapsed)}
            >
                <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                    <span className="font-size-lg">&bull;&nbsp;{dealership.usualName}</span>
                    <Link className="ml-3" onClick={(e) => e.preventDefault()} to="/#">
                        <u>
                            <FormattedHTMLMessage id="DASHBOARD.SEE.DETAILS" />
                        </u>
                    </Link>
                </div>
                <div
                    role="presentation"
                    className="d-inline-flex flex-grow-1 justify-content-end px-5"
                    onClick={() =>
                        linkToLeadWithFilters(history, true, updateActiveLeadsFilters, dealership?.usualName, '', 'all')
                    }
                >
                    <strong>{dealership.leadInProgress}</strong>&nbsp;
                    <FormattedHTMLMessage
                        id="DASHBOARD.ACTIVITY.LEADS"
                        values={{ number: dealership.leadInProgress }}
                    />
                </div>
                <div className="d-flex flex-column mr-15">
                    <div className="dashboard-lead__counters text-white d-flex align-items-center">
                        <Button
                            className="d-flex align-items-center py-1 px-3"
                            variant={`${dealership.actionsToDo ? 'outline-danger' : 'outline-secondary'}`}
                            size="sm"
                            onClick={() =>
                                linkToLeadWithFilters(history, true, updateFilters, dealership?.usualName, '')
                            }
                        >
                            <i className="las la-hand-pointer" />
                            <FormattedHTMLMessage
                                id="DASHBOARD.ACTIVITY.ACTIONS"
                                values={{
                                    number: dealership.actionsToDo,
                                }}
                            />
                        </Button>
                    </div>
                    <Button
                        className="d-flex align-items-center py-1 px-3 mt-1"
                        variant={`${dealership.appointment ? 'outline-primary' : 'outline-secondary'}`}
                        size="sm"
                    >
                        <i className="las la-calendar" />
                        <FormattedHTMLMessage
                            id="DASHBOARD.ACTIVITY.APPOINTMENTS"
                            values={{
                                number: dealership.appointment,
                            }}
                        />
                    </Button>
                </div>
                <Expander
                    className="cursor-pointer align-self-start mt-4"
                    variant="gray"
                    size="la-2xl"
                    collapsed={collapsed}
                    setCollapsed={(value) => {
                        setDealers([]);
                        setCollapsed(value);
                    }}
                />
            </div>
            {!collapsed && <Divider className="my-2" type="dashed" />}
            <div className="position-relative">
                {loadingDealers && <Loader style={{ width: '5rem', height: '5rem' }} block />}
                <motion.div variants={variants} initial="hidden" animate={collapsed ? 'hidden' : 'shown'}>
                    {dealers && !collapsed && !loadingDealers && (
                        <DashboardActivityTimeline
                            data={dealers}
                            dealership={dealership}
                            timelineClassName={classNames('mt-0', {
                                'mt-4': collapsed,
                            })}
                            displayTransformationRate
                            displayOverlayInfo
                        />
                    )}
                    {(!dealers || dealers.length === 0) && !collapsed && !loadingDealers && (
                        <div className="my-2 ml-2 text-dark-light">
                            <i className="font-size-base">
                                <FormattedMessage id="DASHBOARD.NO_DATA" />
                            </i>
                        </div>
                    )}
                </motion.div>
            </div>
        </div>
    );
};

export default DashboardActivityDealership;
