import React, { ReactNode } from "react";
import classNames from "classnames";

interface LabelProps {
    variant?: string,
    size?: string,
    fontSize?: string,
    className?: string,
    children?: ReactNode
}

function Label({variant, size, fontSize, className, children, ...rest}: LabelProps) {
    return (
        <div className={classNames('label label-inline', {
            [size]: size,
            [fontSize]: fontSize,
            [`label-light-${variant}`]: variant,
            [className]: className,
        })} {...rest}>
            {children}
        </div>
    );
}

Label.defaultProps = {
    size: 'label-lg',
    fontSize: ''
};

export default Label;
