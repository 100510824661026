import dayjs from 'dayjs';
import { Dropdown } from 'primereact/dropdown';
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';

import { getTags, Tag } from '@app/crud/autouser/tags.crud';
import { DashboardDealerRanking } from '@app/crud/dashboard/dashboard.crud';

import ModalDefault from '@app/partials/content/modals/Modal.default';

import { DashboardContext } from '../DashboardProvider';
import {
    getRankingColor,
    getTransformation,
    isAvailableDealershipsRanking,
    PREVIOUS_MONTH,
    sortRanking,
} from '../helpers/DashboardHelper';
import DashboardRankingIcon from '../partials/Ranking/DashboardRankingIcon';

type RankingDetailProps = {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    params: {
        dealership: string;
        currentPeriod?: 'previousMonth' | 'currentMonth';
    };
};

const RankingDetail = ({ showModal, setShowModal, params }: RankingDetailProps) => {
    const { data: tags } = useFetch({
        fetchAction: getTags,
        resultInterceptor: (response) => response?.result?.filter?.((tag) => tag?.category === 'typologie_lead'),
        autoFetch: true,
    });
    const [typologie, setTypologie] = useState(null);
    const { data, role, params: contextParams } = useContext(DashboardContext);
    let day = dayjs();

    if (params?.currentPeriod === PREVIOUS_MONTH) {
        day = day.subtract(1, 'month');
    }

    const filteredTags = useMemo(() => {
        const dealerTypologies = data?.ranking
            ?.reduce?.((acc: string[], dealer: DashboardDealerRanking) => {
                const newTags: string[] = [];

                dealer?.typologieLead?.forEach((typologieItem) => {
                    if (!acc?.includes?.(typologieItem)) {
                        newTags?.push(typologieItem);
                    }
                });

                return [...acc, ...newTags];
            }, [])
            .filter((typologieItem: string) => !['fluidite'].includes(typologieItem));

        // @ts-ignore
        return tags?.filter?.((tag: Tag) => dealerTypologies?.includes?.(tag?.slug));
    }, [tags, data]);

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-trophy" />}
            title={
                isAvailableDealershipsRanking(role, contextParams) ? (
                    <FormattedMessage id="DASHBOARD.MODAL.RANKING.TITLE.DEALERSHIPS" />
                ) : (
                    <FormattedMessage id="DASHBOARD.MODAL.RANKING.TITLE.DEALERS" />
                )
            }
            subtitle={
                <FormattedHTMLMessage
                    id="DASHBOARD.MODAL.RANKING.SUBTITLE"
                    values={{
                        date: day.format('MMMM YYYY'),
                    }}
                />
            }
            body={
                <div className="dashboard-ranking__details d-flex flex-column pr-5">
                    <div className="mb-8 d-flex flex-wrap align-items-center justify-content-end">
                        <Dropdown
                            value={typologie}
                            onChange={(e) => {
                                setTypologie(e?.value);
                            }}
                            valueTemplate={(value) =>
                                value?.name || <FormattedMessage id="DASHBOARD.MODAL.RANKING.PLACEHOLDER" />
                            }
                            itemTemplate={(value) => value?.name || ''}
                            showClear
                            optionLabel="name"
                            options={filteredTags}
                        />
                    </div>
                    {data?.ranking
                        ?.filter?.((dealer: DashboardDealerRanking) =>
                            typologie ? dealer?.typologieLead?.includes?.(typologie?.slug) : true,
                        )
                        ?.sort?.(sortRanking)
                        ?.map?.((dealer: DashboardDealerRanking, idx: number) => {
                            /* @ts-ignore TODO : check if still used */
                            if (dealer?.usualName) return false;

                            const color = getRankingColor(idx);
                            const percentage = getTransformation(
                                dealer?.leads?.won,
                                dealer?.leads?.lost,
                                dealer?.leads?.total,
                            );

                            return (
                                <div
                                    key={`${dealer?.firstname}_${dealer?.lastname}`}
                                    className="d-flex align-items-center mb-5 line-height-md"
                                >
                                    <DashboardRankingIcon color={color} idx={idx + 1} />
                                    <div className="flex-grow-1">
                                        {isAvailableDealershipsRanking(role, contextParams) ? (
                                            <>
                                                {/* @ts-ignore TODO : check if still used */}
                                                <div className="font-weight-bold">{dealer.name}</div>
                                            </>
                                        ) : (
                                            <>
                                                <div>
                                                    {dealer?.firstname} {dealer?.lastname}
                                                </div>
                                                <div>{dealer?.dealership}</div>
                                            </>
                                        )}
                                    </div>
                                    <div>
                                        <div>
                                            {Intl.formatMessage({ id: 'DASHBOARD.MODAL.RANKING.TRANSFORMATION' })}
                                            <strong>&nbsp;{percentage}&nbsp;%</strong>
                                        </div>
                                        <div>
                                            {Intl.formatMessage({ id: 'DASHBOARD.MODAL.RANKING.LEADS_COUNT' })}
                                            <strong>&nbsp;{dealer?.leads?.total}</strong>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            }
            footer={
                <div className="w-100 d-flex justify-content-center">
                    <Button variant="primary" onClick={() => setShowModal(false)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default RankingDetail;
