import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { publishableIndicesAutosphere } from '@app/pages/stock/helpers/StockRules';

import RadioButtonsFilter from '@app/partials/content/Filters/RadioButtonsFilter';

const StockVehiclesFilterCanalsBlock = ({ updatePendingFilters }) => {
    const Intl = useIntl();
    const isContextEFB = useSelector((state) => state.auth.userData.context === 'efb');
    const publishableIndices = publishableIndicesAutosphere(isContextEFB);

    return (
        <Card>
            <Card.Header>
                <i className="card__icon la-2x las la-globe-europe text-dark" />
                <FormattedMessage id="STOCK_VEHICLES.FILTERS.CARD.TITLE.CANALS" />
            </Card.Header>
            <Card.Body className="p-0 pl-30 pr-30">
                <Table className="table-borderless table-condensed table-hover">
                    <thead>
                        <tr>
                            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                            <th />
                            {!isContextEFB && (
                                <th className="text-center">
                                    <FormattedMessage id="STOCK_VEHICLES.FILTERS.CARD.SUBTITLE.CANALS.ELIGIBILITY" />
                                </th>
                            )}
                            <th className="text-center">
                                <FormattedMessage id="STOCK_VEHICLES.FILTERS.CARD.SUBTITLE.CANALS.PUBLICATION" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                            <td />
                            {!isContextEFB && (
                                <td>
                                    <div className="d-flex justify-content-around">
                                        <span>
                                            <FormattedMessage id="STOCK_VEHICLES.FILTERS.LABEL.BUTTON.RADIO.YES" />
                                        </span>
                                        <span>
                                            <FormattedMessage id="STOCK_VEHICLES.FILTERS.LABEL.BUTTON.RADIO.NO" />
                                        </span>
                                        <span>
                                            <FormattedMessage id="STOCK_VEHICLES.FILTERS.LABEL.BUTTON.RADIO.ALL" />
                                        </span>
                                    </div>
                                </td>
                            )}
                            <td>
                                <div className="d-flex justify-content-around">
                                    <span>
                                        <FormattedMessage id="STOCK_VEHICLES.FILTERS.LABEL.BUTTON.RADIO.YES" />
                                    </span>
                                    <span>
                                        <FormattedMessage id="STOCK_VEHICLES.FILTERS.LABEL.BUTTON.RADIO.NO" />
                                    </span>
                                    <span>
                                        <FormattedMessage id="STOCK_VEHICLES.FILTERS.LABEL.BUTTON.RADIO.ALL" />
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <FormattedMessage
                                    id={`STOCK_VEHICLES.FILTERS.LABEL.CANALS.AUTOSPHERE_${isContextEFB ? 'BE' : 'FR'}`}
                                />
                            </td>
                            {!isContextEFB && (
                                <td>
                                    <div>
                                        <RadioButtonsFilter
                                            name="is_eligible_afr"
                                            className="d-flex justify-content-around"
                                            filterName={Intl.formatMessage({
                                                id: 'STOCK_VEHICLES.FILTERS.LABEL.CANALS.AUTOSPHERE_FR',
                                            })}
                                            values={[
                                                {
                                                    key: 'oui',
                                                    value: true,
                                                },
                                                {
                                                    key: 'non',
                                                    value: false,
                                                },
                                                {
                                                    key: 'tous',
                                                    value: '',
                                                },
                                            ]}
                                            defaultValue=""
                                            pending
                                            onPendingChange={updatePendingFilters}
                                            displayLabel={false}
                                        />
                                    </div>
                                </td>
                            )}
                            <td>
                                <div>
                                    <RadioButtonsFilter
                                        name="publishable_in_indices"
                                        className="d-flex justify-content-around"
                                        filterName={`${Intl.formatMessage({
                                            id: 'STOCK_VEHICLES.FILTERS.CARD.SUBTITLE.CANALS.PUBLICATION',
                                        })}
                                        ${Intl.formatMessage({
                                            id: `STOCK_VEHICLES.FILTERS.LABEL.CANALS.AUTOSPHERE_${
                                                isContextEFB ? 'BE' : 'FR'
                                            }`,
                                        })}`}
                                        values={[
                                            {
                                                key: 'oui',
                                                value: `${publishableIndices}:true`,
                                            },
                                            {
                                                key: 'non',
                                                value: `${publishableIndices}:false`,
                                            },
                                            {
                                                key: 'tous',
                                                value: '',
                                            },
                                        ]}
                                        defaultValue=""
                                        pending
                                        onPendingChange={updatePendingFilters}
                                        displayLabel={false}
                                    />
                                </div>
                            </td>
                        </tr>
                        {!isContextEFB && (
                            <>
                                <tr>
                                    <td>
                                        <FormattedMessage id="STOCK_VEHICLES.FILTERS.LABEL.CANALS.FULL_WEB" />
                                    </td>
                                    <td>
                                        <div>
                                            <RadioButtonsFilter
                                                name="is_eligible_fw"
                                                className="d-flex justify-content-around"
                                                filterName={Intl.formatMessage({
                                                    id: 'STOCK_VEHICLES.FILTERS.LABEL.CANALS.FULL_WEB',
                                                })}
                                                values={[
                                                    {
                                                        key: 'oui',
                                                        value: true,
                                                    },
                                                    {
                                                        key: 'non',
                                                        value: false,
                                                    },
                                                    {
                                                        key: 'tous',
                                                        value: '',
                                                    },
                                                ]}
                                                defaultValue=""
                                                pending
                                                onPendingChange={updatePendingFilters}
                                                displayLabel={false}
                                            />
                                        </div>
                                    </td>
                                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                    <td />
                                </tr>
                                <tr>
                                    <td>
                                        <FormattedMessage id="STOCK_VEHICLES.FILTERS.LABEL.CANALS.FLUIDITY" />
                                    </td>
                                    <td>
                                        <div>
                                            <RadioButtonsFilter
                                                name="is_eligible_fluidite"
                                                className="d-flex justify-content-around"
                                                filterName={Intl.formatMessage({
                                                    id: 'STOCK_VEHICLES.FILTERS.LABEL.CANALS.FLUIDITY',
                                                })}
                                                values={[
                                                    {
                                                        key: 'oui',
                                                        value: true,
                                                    },
                                                    {
                                                        key: 'non',
                                                        value: false,
                                                    },
                                                    {
                                                        key: 'tous',
                                                        value: '',
                                                    },
                                                ]}
                                                defaultValue=""
                                                pending
                                                onPendingChange={updatePendingFilters}
                                                displayLabel={false}
                                            />
                                        </div>
                                    </td>
                                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                    <td />
                                </tr>
                            </>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};

export default StockVehiclesFilterCanalsBlock;
