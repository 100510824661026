import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';

import { SettingsItem } from '../Settings.type';

const globalItems: SettingsItem[] = [
    {
        permissions: ['AUTOUSER_LIST_ROLE'],
        route: () => routeTo(ROUTES.ROLES.PATH),
        color: 'yellow',
        icon: 'la-sitemap',
        messageId: 'SETTINGS.ROLE',
    },
    {
        permissions: ['PARAMETER_MANAGE_DEALERSHIP'],
        route: () => routeTo(ROUTES.DEALERSHIPS_PERIMETER.PATH),
        color: 'primary',
        icon: 'la-building',
        messageId: 'SETTINGS.DEALERSHIP',
    },
    {
        permissions: ['AUTOUSER_MENU_USER'],
        route: () => routeTo(ROUTES.USERS.PATH),
        color: 'success',
        icon: 'la-user',
        messageId: 'SETTINGS.USER',
    },
    {
        permissions: ['PARAMETER_MANAGE_MAILTEMPLATE'],
        route: () => routeTo(ROUTES.EMAIL_TEMPLATES.PATH),
        color: 'orange',
        icon: 'la-envelope',
        messageId: 'SETTINGS.MAIL',
    },
    {
        permissions: ['PARAMETER_MANAGE_SIGNATURE'],
        route: () => routeTo(ROUTES.SIGNATURE_TEMPLATES.PATH),
        color: 'purple',
        icon: 'la-pencil-alt',
        messageId: 'SETTINGS.SIGNATURES',
    },
    {
        permissions: ['AUTOUSER_LIST_APP'],
        route: () => routeTo(ROUTES.APPLICATIONS.PATH),
        color: 'info',
        icon: 'la-desktop',
        messageId: 'SETTINGS.APPLICATION',
    },
    {
        permissions: ['PARAMETER_ACCESS_CONSOLE'],
        route: () => routeTo(ROUTES.CONSOLE_LEADS.PATH),
        color: 'info',
        icon: 'la-terminal',
        messageId: 'SETTINGS.CONSOLE_LEADS',
    },
    {
        permissions: ['AUTOUSER_LIST_HISTORY'],
        route: () => routeTo(ROUTES.HISTORY.PATH),
        color: 'info',
        icon: 'la-history',
        messageId: 'SETTINGS.HISTORY',
    },
    {
        permissions: ['PARAMETER_MANAGE_FASTNOTES'],
        route: () => routeTo(ROUTES.LEAD_TAGS.PATH),
        color: 'warning',
        icon: 'la-tag',
        messageId: 'SETTINGS.LEAD_TAGS',
    },
    {
        permissions: ['PARAMETER_MANAGE_ALERTS'],
        route: () => routeTo(ROUTES.ALERTS.PATH.CURRENT),
        color: 'pink',
        icon: 'la-bell',
        messageId: 'SETTINGS.ALERTS',
    },
];

export default globalItems;
