import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { Order } from '@app/crud/apv/order.type';

import { Package } from '@app/pages/apv/orders/Order';

interface DetailsOrderProps {
    order: Order;
}
const isFree = (price: number) => {
    if (price === 0) return true;
    return false;
};
const DetailsOrder = ({ order }: DetailsOrderProps) => {
    const Intl = useIntl();

    return (
        <Card>
            <Card.Header className="border-bottom-0">
                <i className="card__icon fas fa-tasks" />
                {Intl.formatMessage({ id: 'APV.ORDER.VIEW.DETAILS' })}
            </Card.Header>
            <Card.Body className="p-0">
                <Table className="m-0" striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{ width: '10%' }}>
                                {Intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.REFERENCE' })}
                            </th>
                            <th style={{ width: '50%' }}>
                                {Intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.PRODUCT' })}
                            </th>
                            <th style={{ width: '20%' }}>
                                {Intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.INITIAL_PRICE' })}
                            </th>
                            <th style={{ width: '20%' }}>
                                {Intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.FINAL_PRICE' })}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {order?.orderPackages &&
                            order.orderPackages.map((_package: Package) => (
                                <tr key={_package.code}>
                                    <td>{_package.code}</td>
                                    <td>{_package.name}</td>
                                    <td>
                                        {!isFree(_package.price) ? (
                                            <NumberFormat
                                                value={_package.price}
                                                thousandSeparator=" "
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale
                                                displayType="text"
                                                suffix=" €"
                                            />
                                        ) : (
                                            Intl.formatMessage({ id: 'APV.ORDER.VIEW.FREE' })
                                        )}
                                    </td>
                                    <td>
                                        {!isFree(_package.totalPrice) ? (
                                            <NumberFormat
                                                value={_package.totalPrice}
                                                thousandSeparator=" "
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale
                                                displayType="text"
                                                suffix=" €"
                                            />
                                        ) : (
                                            Intl.formatMessage({ id: 'APV.ORDER.VIEW.FREE' })
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};

export default DetailsOrder;
