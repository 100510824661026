import { Action, combineReducers } from 'redux';

import { metronic } from '@src/_metronic';

import { additionalServiceReducer } from './apv/additional-service/additional-service.store';
import { apvInStoreReducer } from './apv/apvinstore/order/order.store';
import { categoryReducer } from './apv/category/category.store';
import { commercialOperationReducer } from './apv/commercial-operation/commercial-operation.store';
import { dealershipReducer } from './apv/dealership/dealership.store';
import { orderReducer } from './apv/order/order.store';
import { packageReducer } from './apv/package/package.store';
import { autoUserApplicationReducer } from './autouser/application/application.store';
import { permissionReducer } from './autouser/permission/permission.store';
import { autoUserRoleReducer } from './autouser/role/role.store';
import { autoUserUserReducer } from './autouser/user/user.store';
import { customerReducer } from './customers/customers.store';
import { filtersReducer } from './filters/filters.store';
import { leadReducer } from './lead/lead.store';
import { loginReducer } from './login/login.store';
import { orderManufacturerReducer } from './manufacturer/order/order.store';
import { packageManufacturerReducer } from './manufacturer/package/package.store';
import { modalReducer } from './modal/modal.store';
import { routesReducer } from './route/route.store';
import { scheduleReducer } from './schedule/schedule.store';
import { orderSmartRepairReducer } from './smart-repair/order/order.store';
import { packageSmartRepairReducer } from './smart-repair/package/package.store';
import { quotationReducer } from './smart-repair/quotations/smart-repair.store';
import { stockReducer } from './stock/stock.store';

const appReducer = combineReducers({
    auth: loginReducer,
    i18n: metronic.i18n.i18nReducer,
    builder: metronic.builder.reducer,
    filters: filtersReducer,
    package: packageReducer,
    order: orderReducer,
    smartRepair: combineReducers({
        package: packageSmartRepairReducer,
        order: orderSmartRepairReducer,
        quotation: quotationReducer,
    }),
    manufacturer: combineReducers({
        package: packageManufacturerReducer,
        order: orderManufacturerReducer,
    }),
    commercialOperation: commercialOperationReducer,
    dealership: dealershipReducer,
    additionalService: additionalServiceReducer,
    category: categoryReducer,
    customer: customerReducer,
    modal: modalReducer,
    schedule: scheduleReducer,
    role: autoUserRoleReducer,
    user: autoUserUserReducer,
    permission: permissionReducer,
    application: autoUserApplicationReducer,
    stock: stockReducer,
    route: routesReducer,
    lead: leadReducer,
    apvinstore: combineReducers({
        order: apvInStoreReducer,
    }),
});

export type RootState = ReturnType<typeof appReducer>;

/**
 * Root reducer with unique action to reset store to initial values
 * Except for persisted reducer
 *
 * @param state
 * @param action
 * @returns {*}
 */
export const rootReducer = (state: RootState, action: Action) => {
    switch (action.type) {
        case 'RESET_STATE':
            {
                const { auth, i18n, builder, filters, route } = state;

                // @ts-ignore à réactiver lorsque tous les states seront typés
                state = {
                    auth,
                    i18n,
                    builder,
                    filters,
                    route,
                };
            }
            break;
        case 'RESET_MODAL':
            {
                const newState = { ...state };
                delete newState.modal;

                state = newState;
            }
            break;
        default:
            break;
    }

    return appReducer(state, action);
};
