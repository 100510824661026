import classNames from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import useFetch from '@app/hooks/useFetch';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getDashboardPendingLeads } from '@app/crud/dashboard/dashboard.crud';

import { getTotalPendingLeads } from '@app/pages/dashboard/helpers/DashboardHelper';
import ROUTES from '@app/router/Routes';

import { DashboardContext } from '../../DashboardProvider';

import DashboardActivityTimeline from './DashboardActivityTimeline';

interface Props {
    className?: string;
}

const DashboardActivityManagerAutosphereContact = ({ className }: Props) => {
    const [loading, setLoading] = useState(true);
    const { overriddenParams } = useContext(DashboardContext);

    const { data: pendingLeads, fetch: fetchPendingLeads } = useFetch({
        fetchAction: getDashboardPendingLeads,
    });
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (overriddenParams) {
            fetchPendingLeads(overriddenParams);
        }
    }, [overriddenParams]);

    useEffect(() => {
        setLoading(true);
        if (pendingLeads.length > 0) {
            const totalPendingLeads = getTotalPendingLeads(pendingLeads);
            if (totalPendingLeads) {
                setTotal(totalPendingLeads);
            }
            setLoading(false);
        }
    }, [pendingLeads]);

    return (
        <div className={classNames('dashboard-activity mt-xxl-0', className)}>
            <div className="d-flex align-items-center">
                <i className="dashboard-activity__icon las la-2x la-sitemap mr-2" />
                <span className="font-size-lg font-weight-bold">
                    <FormattedMessage id="DASHBOARD.ACTIVITY.TITLE" />
                </span>
            </div>
            {loading ? (
                <Skeleton
                    borderRadius="10px"
                    className="dashboard-lead__total d-flex flex-row align-items-end mt-5 p-15"
                />
            ) : (
                <Link
                    className="dashboard-lead d-flex flex-row align-items-end mt-5"
                    to={{
                        pathname: routeTo(ROUTES.LEADS_ONLINE.PATH, {
                            statusType: 'new',
                        }),
                    }}
                >
                    <div className="dashboard-lead__total font-weight-bold">{total}</div>
                    <div className="dashboard-lead__title font-size-h4 line-height-md">
                        <FormattedHTMLMessage id="DASHBOARD.ACTIVITY.LEAD.APV.NOT.TAKEN" values={{ number: total }} />
                    </div>
                </Link>
            )}
            <DashboardActivityTimeline linkApvLead />
        </div>
    );
};

DashboardActivityManagerAutosphereContact.defaultProps = {
    className: '',
};

export default DashboardActivityManagerAutosphereContact;
