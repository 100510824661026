import classNames from 'classnames';
import dayjs from 'dayjs';
import { Column } from 'primereact/column';
import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getContactId } from '@app/crud/securyweb/securyweb.crud';
import { Contract, StatusContractSlug } from '@app/crud/securyweb/securyweb.types';

import { useFetch } from '@app/hooks';
import { getStatusClassName, getStatusLabelId } from '@app/pages/securyweb/Helpers/SecurywebHelper';
import ROUTES from '@app/router/Routes';

// import { CalendarFilter } from '@app/partials/content/CalendarFilter';
import DropdownFilter from '@app/partials/content/DropdownFilter';
import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
// import InputFilter from '@app/partials/content/InputFilter';
import Label from '@app/partials/content/Label';
import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';

type Filter = {
    contractStatus: StatusContractSlug;
};

type SecurywebContractsDataTableProps = {
    items: number;
    start: number;
    filters: Filter;
    data: {
        contracts: Contract[];
        counter: {
            key: string;
            label: string;
        }[];
    } | null;
    loading: boolean;
    handleFilter: (value: Filter) => void;
    onStatusChange: (newStatus: StatusContractSlug) => void;
};

const SecurywebContractsDataTable = ({
    items,
    start,
    filters,
    data,
    loading,
    handleFilter,
    onStatusChange,
}: SecurywebContractsDataTableProps) => {
    const Intl = useIntl();
    const history = useHistory();
    const { REACT_APP_SECURYWEB_AUTOSPHERE_URL } = process.env;

    const {
        fetch: fetchContactId,
        data: contactId,
        loading: loadingContactId,
    } = useFetch({
        fetchAction: getContactId,
        resultInterceptor: (response) => response.result,
    });

    // const inputFilter = (name: string) => <InputFilter name={name} onChange={handleFilter} />;
    // const calendarFilter = (name: string) => <CalendarFilter name={name} onChange={handleFilter} />;

    const dealershipBodyTemplate = (contract: Contract) => {
        const { code: id, denomination: usualName } = contract?.concession || {};

        if (!id && !usualName) return '-';
        return (
            <>
                {id && <div>{id}</div>}
                {usualName && <div>{usualName}</div>}
            </>
        );
    };

    const contractNumberBodyTemplate = (contract: Contract) => <span>{contract?.numero || '-'}</span>;

    const registrationBody = (contract: Contract) => {
        if (!contract?.vehicule) return '-';

        const { immatriculation: license_number, codeVIN: serial_number } = contract.vehicule;

        if (!license_number && !serial_number) return '-';
        return (
            <>
                {license_number && <div>{license_number}</div>}
                {serial_number && <div>{serial_number}</div>}
            </>
        );
    };

    const productBodyTemplate = (contract: Contract) => <span>{contract?.produit.libelle || '-'}</span>;

    const priceBodyTemplate = (contract: Contract) => (
        <span>{contract?.prixVenteTtc ? `${contract.prixVenteTtc} €` : '-'}</span>
    );

    const clientBodyTemplate = (contract: Contract) => {
        const { nom: clientFirstname, prenom: clientLastname } = contract.souscripteur;

        if (!clientFirstname && !clientLastname) return '-';
        return <span>{`${clientFirstname || ''} ${clientLastname || ''}`}</span>;
    };

    const referentBodyTemplate = (contract: Contract) => {
        if (contract.concession.employes.length === 0) return <span>-</span>;
        const { prenom: ownerFirstname, nom: ownerLastname } = contract.concession.employes[0];

        if (!ownerFirstname && !ownerLastname) return '-';
        return <span>{`${ownerFirstname || ''} ${ownerLastname || ''}`}</span>;
    };

    const statusBodyTemplate = (contract: { statut?: StatusContractSlug }) => {
        const statusSlug = contract?.statut ?? StatusContractSlug.DRAFT;
        const statusClassName = getStatusClassName(statusSlug);
        const labelId = getStatusLabelId(statusSlug);
        const statusLabel = Intl.formatMessage({ id: labelId });

        return (
            <Label
                className={classNames('font-weight-bold label-lg', {
                    [`label-light-${statusClassName}`]: statusClassName,
                })}
            >
                {statusLabel}
            </Label>
        );
    };

    const dateCreatedBodyTemplate = (contract: Contract) => (
        <span>{contract?.dateSouscription ? dayjs(contract.dateSouscription).format('DD/MM/YYYY') : '-'}</span>
    );

    const toCustomerRoute = (id: number) => {
        history.push(
            routeTo(ROUTES.CUSTOMER.PATH, {
                id,
            }),
        );
    };

    const toSecuryWebRoute = () => {
        window.open(REACT_APP_SECURYWEB_AUTOSPHERE_URL, '_blank');
    };

    const handleShowButtonClick = (contract: Contract) => {
        // TODO : v1 add CreateAlreadyExist if multiple client (f827388adfe578c752577e2312636a4d9d7e9bab)

        const { id: idSubscriber } = contract?.souscripteur || {};

        if (idSubscriber) {
            fetchContactId(idSubscriber).then((result) => {
                if (!result) toSecuryWebRoute();
            });
        } else {
            toSecuryWebRoute();
        }
    };

    useEffect(() => {
        if (Array.isArray(contactId)) return;
        toCustomerRoute(contactId);
    }, [contactId]);

    const actionsBodyTemplate = (contract: Contract) => (
        <Permission permissions={['SECURYCAR_READ_CONTRACT']}>
            <Button className="p-2 px-3" variant="primary" onClick={() => handleShowButtonClick(contract)}>
                {Intl.formatMessage({ id: 'TRANSLATOR.SHOW' })}
            </Button>
        </Permission>
    );

    const selectStatusDropdown = () => {
        const contractStatusOptions = data?.counter
            ? data?.counter.map((status) => ({
                  name: status.label,
                  key: status.key,
              }))
            : [];

        return (
            <DropdownFilter
                optionLabel="name"
                filterBy="name"
                name="contractStatus"
                options={contractStatusOptions}
                onChange={(event: { contractStatus: { value: StatusContractSlug } }) => {
                    onStatusChange(event.contractStatus.value);
                }}
            />
        );
    };

    // TODO : filters are planned for v1
    const cols = [
        {
            field: 'dealership',
            header: Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.DEALERSHIP' }),
            body: dealershipBodyTemplate,
            // filterElement: inputFilter('dealership'),
            // filter: true,
            style: { width: '15%' },
        },
        {
            field: 'contractNumber',
            header: Intl.formatMessage({ id: 'SECURYCAR.CONTRACT.DATATABLE.CONTRACT_NUMBER' }),
            body: contractNumberBodyTemplate,
            // filterElement: inputFilter('contractNumber'),
            // filter: true,
            style: { width: '10%' },
        },
        {
            field: 'registration',
            header: Intl.formatMessage({ id: 'CUSTOMERS.VEHICLE.TABLE.HEAD.REGISTRATION' }),
            body: registrationBody,
            // filterElement: inputFilter('registration'),
            // filter: true,
            style: { width: '15%' },
        },
        {
            field: 'contact',
            header: Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.CUSTOMER' }),
            body: clientBodyTemplate,
            // filterElement: inputFilter('contact'),
            // filter: true,
            style: { width: '12%' },
        },
        {
            field: 'product',
            header: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.CATEGORY.PRODUCT.TITLE' }),
            body: productBodyTemplate,
            style: { width: '10%' },
        },
        {
            field: 'price',
            header: Intl.formatMessage({ id: 'SECURYCAR.CONTRACT.DATATABLE.PRICE' }),
            body: priceBodyTemplate,
            style: { width: '10%' },
        },
        {
            field: 'user',
            header: Intl.formatMessage({ id: 'CUSTOMER.MODAL.ASSIGN.REFERENT.SELECT' }),
            body: referentBodyTemplate,
            // filterElement: inputFilter('user'),
            style: { width: '10%' },
        },
        {
            field: 'contractStatus',
            header: Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.TABLE.STATUS' }),
            body: statusBodyTemplate,
            filterElement: selectStatusDropdown(),
            filter: true,
            style: { width: '10%' },
        },
        {
            field: 'created',
            header: Intl.formatMessage({ id: 'SECURYCAR.CONTRACT.DATATABLE.SUBSCRIPTION' }),
            body: dateCreatedBodyTemplate,
            // filterElement: calendarFilter('created'),
            // filter: true,
            style: { width: '15%' },
        },
        {
            field: 'actions',
            header: Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.ACTIONS' }),
            body: actionsBodyTemplate,
            bodyClassName: 'text-center',
            style: { width: '5%' },
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <>
            {(loading || loadingContactId) && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <FiltersProvider value={filters}>
                {/* @ts-ignore */}
                <HoCDataTable
                    value={data.contracts}
                    totalRecords={data?.contracts.length}
                    lazy
                    rows={items}
                    start={start}
                    first={start}
                    loading={loading}
                    onFilter={handleFilter}
                    filterDisplay="row"
                    emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                >
                    {dynamicColumns}
                </HoCDataTable>
            </FiltersProvider>
        </>
    );
};

export default SecurywebContractsDataTable;
