import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppSelector } from '@app/hooks';
import { User } from '@app/store/login/login.store';

import { DashboardContext } from './DashboardProvider';
import DashboardDealershipVendors from './partials/DashboardDealershipVendors';
import DashboardUser from './partials/DashboardUser';
import DashboardCalendar from './partials/Stats/DashboardCalendar';
import DashboardMonth from './partials/Stats/DashboardMonth';

interface Params {
    dealership: string;
    dealer: User | null;
}
export interface Props {
    calendar: boolean;
    hideFilters: boolean;
    hideFilterCustomers: boolean;
}

const DashboardHeader = ({ calendar, hideFilters, hideFilterCustomers }: Props) => {
    const Intl = useIntl();
    const { setParams } = useContext(DashboardContext);
    const [referent, setReferent] = useState<User | null>(null);
    const { userData } = useAppSelector((state) => state.auth);

    const selectDealershipHandler = (dealership: string) => {
        setParams((params: Params) => ({
            ...params,
            dealership,
            dealer: null as User,
        }));
    };

    const selectReferentHandler = (referentItem: User) => {
        setReferent(referentItem);
        setParams((params: Params) => ({
            ...params,
            dealer: referentItem,
        }));
    };

    const vendorsFilter = () => {
        switch (userData?.role?.slug) {
            case 'responsable-apv':
                return ['conseiller-commercial-services'];
            case 'manager-autosphere-contact':
                return ['agent-autosphere-contact'];
            default:
                return ['manager', 'conseiller-commercial'];
        }
    };

    return (
        <div className="d-flex flex-column flex-xl-row mt-4">
            <DashboardUser className={`font-size-lg text-white d-flex align-items-end ${hideFilters ? 'w-100' : ''}`} />
            {!hideFilters && (
                <div className="dashboard-filters d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center mt-xl-0 mt-8">
                    <div className="dashboard-filters__dealership d-flex zi flex-grow-1 flex-wrap flex-lg-nowrap align-items-center mr-10">
                        <div className="flex-grow-0 mr-7 font-weight-bold">
                            <FormattedMessage id="TRANSLATOR.FILTER" />
                            &nbsp;:
                        </div>
                        <DashboardDealershipVendors
                            className="my-0 mr-7 d-flex w-100"
                            dealershipClassName="react-dropdown-tree-select--dashboard"
                            vendorsClassName="ml-4 my-1 h-auto label label-inline label-light-initial select__custom"
                            dealershipEmptyLabel={Intl.formatMessage({ id: 'DASHBOARD.ALL.DEALERSHIP' })}
                            dealership={null}
                            vendorsEmptyLabel={
                                userData?.role?.slug === ('manager-autosphere-contact' || 'responsable-apv')
                                    ? Intl.formatMessage({ id: 'DASHBOARD.ALL.ADVISOR' })
                                    : Intl.formatMessage({ id: 'DASHBOARD.ALL.VENDORS' })
                            }
                            referent={referent}
                            setReferent={selectReferentHandler}
                            labels={false}
                            showClear
                            page="dashboard"
                            showReferent={!hideFilterCustomers}
                            setDealership={selectDealershipHandler}
                            referentsInterceptor={(vendors: User[]) =>
                                vendors
                                    ?.sort?.((a, b) => b?.role?.slug?.localeCompare?.(a?.role?.slug))
                                    ?.filter?.((vendor) => vendorsFilter().includes(vendor?.role?.slug))
                            }
                        />
                    </div>
                    {calendar ? <DashboardCalendar /> : <DashboardMonth />}
                </div>
            )}
        </div>
    );
};

export default DashboardHeader;
