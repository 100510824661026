import React, { useEffect, useState } from 'react';
import { Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getQuotations } from '@app/crud/smartRepair/smart-repair.crud';

import ROUTES from '@app/router/Routes';
import { actions } from '@app/store/smart-repair/quotations/smart-repair.store';

import PortletTab from '@app/partials/layout/PortletTab';

import { Intl } from '../../../../../_metronic/i18n/I18nProvider';

import QuotationTable from './QuotationTable';

const Quotations = () => {
    const { type } = useParams();
    const [activeKey, setActiveKey] = useState('to_be_appraised');
    const { totalRecords } = useSelector((state) => state.smartRepair.quotation);
    const dispatch = useDispatch();

    useEffect(() => {
        getQuotations().then((value) => {
            const to_be_appraised =
                value?.result?.filters?.orderStatusCount?.find((item) => item.groupSlug === 'to_be_appraised')?.count ||
                0;
            const waiting_for_customer =
                value?.result?.filters?.orderStatusCount?.find((item) => item.groupSlug === 'waiting_for_customer')
                    ?.count || 0;
            const closed =
                value?.result?.filters?.orderStatusCount?.find((item) => item.groupSlug === 'closed')?.count || 0;

            dispatch(
                actions.setQuotationsTotalRecords({
                    to_be_appraised,
                    waiting_for_customer,
                    closed,
                }),
            );
        });
    }, [dispatch]);

    useEffect(() => {
        setActiveKey(type || 'to_be_appraised');
    }, [setActiveKey, type]);

    return (
        <PortletTab
            defaultActiveKey={activeKey}
            activeKey={activeKey}
            onClick={setActiveKey}
            redirects={{
                to_be_appraised: routeTo(ROUTES.QUOTATIONS.PATH.TYPE, {
                    orderType: 'to_be_appraised',
                }),
                waiting_for_customer: routeTo(ROUTES.QUOTATIONS.PATH.TYPE, {
                    orderType: 'waiting_for_customer',
                }),
                closed: routeTo(ROUTES.QUOTATIONS.PATH.TYPE, {
                    orderType: 'closed',
                }),
            }}
            id="quotationlist-portlet"
        >
            <Tab
                eventKey="to_be_appraised"
                title={
                    <>
                        {Intl.formatMessage({ id: 'SMART.REPAIR.TABS.TO_BE_APPRAISED' })}
                        <span className="ml-5 label label-light-primary font-weight-bold">
                            {totalRecords.to_be_appraised}
                        </span>
                    </>
                }
            >
                {activeKey === 'to_be_appraised' && <QuotationTable orderType="to_be_appraised" />}
            </Tab>
            <Tab
                eventKey="waiting_for_customer"
                title={
                    <>
                        {Intl.formatMessage({ id: 'SMART.REPAIR.TABS.WAITING_FOR_CUSTOMER' })}
                        <span className="ml-5 label label-light-primary font-weight-bold">
                            {totalRecords.waiting_for_customer}
                        </span>
                    </>
                }
            >
                {activeKey === 'waiting_for_customer' && <QuotationTable orderType="waiting_for_customer" />}
            </Tab>
            <Tab
                eventKey="closed"
                title={
                    <>
                        {Intl.formatMessage({ id: 'SMART.REPAIR.TABS.CLOSED' })}
                        <span className="ml-5 label label-light-primary font-weight-bold">{totalRecords.closed}</span>
                    </>
                }
            >
                {activeKey === 'closed' && <QuotationTable orderType="closed" />}
            </Tab>
        </PortletTab>
    );
};

export default Quotations;
