import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getCustomersNotes } from '@app/crud/note/note.crud';
import { CustomerNoteType } from '@app/crud/note/note.type';

import { useModal } from '@app/hooks';
import ROUTES from '@app/router/Routes';
import { actions as modalActions } from '@app/store/modal/modal.store';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import PortletTab from '@app/partials/layout/PortletTab';

import CustomerNotesDatatable from './CustomerNotesDatatable';
import Notes from './Modals/Note';

const tabs = [
    {
        status: 'toTake',
        title: 'Nouvelles',
        counter: 'toTake',
    },
    {
        status: 'read',
        title: 'Lues',
        counter: 'read',
    },
    {
        status: 'all',
        title: 'Toutes',
        counter: 'total',
    },
];

const CustomersNotes = () => {
    const Intl = useIntl();
    const dispatch = useDispatch();
    const [noteModalOpen, setNoteModalOpen] = useModal(false, 'note');
    const { status } = useParams<{ status: string }>();
    const [activeKey, setActiveKey] = useState(status);

    const { filters, resetFilter } = useFilters({
        name: 'customers_notes_search',
    });

    const { data, loading, handleFilter, handlePagination, refresh } = useTableHandlers({
        filterStore: 'customers_notes_search',
        fetchAction: getCustomersNotes,
        withStore: false,
        paramsInterceptor: (params, interceptorFilters) => ({
            ...params,
            page: interceptorFilters.page,
            limit: interceptorFilters.items,
            isRead: status ? status === 'read' : null,
        }),
    });

    useEffect(() => {
        if (!status) resetFilter('isRead');
    }, [status]);

    const columns = {
        actions: {
            body: (note: CustomerNoteType) => (
                <Button
                    className="p-2 px-3 w-100"
                    onClick={() => dispatch(modalActions.modalChange('note', note))}
                    variant={`${note.takeable ? 'success' : 'primary'}`}
                >
                    {note.takeable ? (
                        <>
                            <i className="la la-hand-pointer mr-2 pr-0" />
                            {Intl.formatMessage({ id: 'LEADS_ONLINE.ACTION.TAKE' })}
                        </>
                    ) : (
                        Intl.formatMessage({ id: 'TRANSLATOR.SHOW' })
                    )}
                </Button>
            ),
        },
    };

    const getCurrentCounter = useCallback(
        (noteStatus: string) => {
            switch (noteStatus) {
                case 'toTake':
                    return data?.facets?.toTake;
                case 'read':
                    return data?.facets?.read;
                default:
                    return data?.facets?.total;
            }
        },
        [data],
    );

    return (
        <Card>
            <Card.Body className="text-center">
                <FiltersProvider value={filters}>
                    <PortletTab
                        defaultActiveKey={activeKey}
                        activeKey={activeKey}
                        id={activeKey}
                        onClick={setActiveKey}
                        redirects={{
                            all: routeTo(ROUTES.NOTES.PATH.CURRENT),
                            toTake: routeTo(ROUTES.NOTES.PATH.ARCHIVE, {
                                status: 'toTake',
                            }),
                            read: routeTo(ROUTES.NOTES.PATH.ARCHIVE, {
                                status: 'read',
                            }),
                        }}
                    >
                        {Object.values(tabs).map((item) => (
                            <Tab
                                key={item.status}
                                eventKey={item.status}
                                title={`${item.title} (${(data.facets && data?.facets[item.counter]) || 0})`}
                            >
                                <CustomerNotesDatatable
                                    notes={data.result}
                                    totalRecords={getCurrentCounter(status)}
                                    handleFilter={handleFilter}
                                    handlePagination={handlePagination}
                                    status={status}
                                    loading={loading}
                                    columns={columns}
                                />
                            </Tab>
                        ))}
                    </PortletTab>
                </FiltersProvider>
                <Notes showModal={noteModalOpen} setShowModal={setNoteModalOpen} refresh={refresh} />
            </Card.Body>
        </Card>
    );
};

export default CustomersNotes;
