import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';
import usePermissions from '@app/hooks/usePermissions';

import { getJobs } from '@app/crud/autouser/job.crud';
import { UpdateUserParams, updateUser } from '@app/crud/autouser/user.crud';

import { User } from '@app/store/login/login.store';

import Loader from '@app/partials/content/Loader';
import toast from '@app/partials/content/Toast';

const container = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
    },
};

const AccountSettings = ({ userData }: { userData: User }) => {
    const { register, reset, handleSubmit } = useForm();
    const { hasAnyPermissions } = usePermissions();
    const [updating, setUpdating] = useState(false);
    const {
        fetch,
        data: jobs,
        loading,
    } = useFetch({
        fetchAction: getJobs,
    });

    useEffect(() => {
        const fetchJobs = async () => {
            await fetch();
            reset({
                ...userData,
                job: userData?.job?.slug || '',
            });
        };

        fetchJobs();
    }, [fetch]);

    const onSubmit = async (form_values: UpdateUserParams) => {
        setUpdating(true);
        await updateUser(userData?.id, form_values);
        setUpdating(false);
        toast({
            variant: 'success',
            message: Intl.formatMessage({ id: 'USER.PROFILE.SETTINGS.UPDATE.SUCCESS' }),
        });
    };

    const canUpdateField = () =>
        userData?.external && hasAnyPermissions(['LEAD_UPDATE_EXTERNAL_PROFIL', 'LEAD_UPDATE_INTERNAL_PROFIL']);

    return (
        <motion.div className="account-settings" variants={container} initial="hidden" animate="visible">
            {(loading || updating) && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <div className="mb-7 font-size-h5 font-weight-bold">
                <FormattedMessage id="USER.PROFILE.SETTINGS.CONTACT_INFORMATION" />
            </div>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="USER.PROFILE.SETTINGS.LASTNAME" /> :
                </Form.Label>
                <Form.Control type="text" name="last_name" ref={register} disabled={!canUpdateField()} />
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="USER.PROFILE.SETTINGS.FIRSTNAME" /> :
                </Form.Label>
                <Form.Control type="text" name="first_name" ref={register} disabled={!canUpdateField()} />
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="USER.PROFILE.SETTINGS.EMAIL" /> :
                </Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <i className="las la-at" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control name="email" ref={register} disabled={!canUpdateField()} />
                </InputGroup>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="USER.PROFILE.SETTINGS.PHONE" /> :
                </Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <i className="las la-phone" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control name="phone" ref={register} disabled />
                </InputGroup>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="USER.PROFILE.SETTINGS.MOBILE" /> :
                </Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <i className="las la-mobile" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control name="cell_phone" ref={register} disabled />
                </InputGroup>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="USER.PROFILE.SETTINGS.JOB" /> :
                </Form.Label>
                <Form.Control as="select" name="job" ref={register} disabled>
                    {jobs?.length > 0 &&
                        jobs.map((job) => (
                            <option key={job.id} value={job.slug}>
                                {job.name}
                            </option>
                        ))}
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="USER.PROFILE.SETTINGS.DEFAULT_DEALERSHIP" />
                </Form.Label>
                <Form.Control as="select" ref={register} defaultValue={userData?.default_dealership} disabled>
                    {userData?.dealerships &&
                        Object.entries(userData.dealerships).map(([refDealershipId, dealership]) => (
                            <option key={refDealershipId} value={refDealershipId}>
                                {dealership.title} - {refDealershipId}
                                {dealership?.codePvo && ` - ${dealership.codePvo}`}
                            </option>
                        ))}
                </Form.Control>
            </Form.Group>
            <div className="mt-15 d-flex justify-content-end">
                <Button variant="primary" disabled={!canUpdateField()} onClick={handleSubmit(onSubmit)}>
                    <FormattedMessage id="TRANSLATOR.VALIDATE" />
                </Button>
            </div>
        </motion.div>
    );
};

export default AccountSettings;
