import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Customer } from '@app/crud/customers/customer.type';
import type { FusionPayload } from '@app/crud/customers/FusionPayload.type';

import { useAppSelector } from '@app/hooks';

import ErrorForm from '@app/partials/layout/ErrorForm';

type FusionFieldProps = {
    data: FusionPayload;
    setData: (value: FusionPayload) => void;
    field: keyof FusionPayload;
    customerKey: keyof Customer;
    validation?: (value: string | number) => string | true;
    formatter?: (value: string) => string | number;
};

const FusionField = ({ data, setData, field, customerKey, validation, formatter }: FusionFieldProps) => {
    const Intl = useIntl();
    const methods = useFormContext();
    const { errors, register, setValue, trigger } = methods;
    const { selectedCustomers } = useAppSelector((state) => state.customer);
    const customInputRef = useRef<HTMLInputElement>(null);

    const valueValidator = (value: string | number) => {
        if (typeof value === 'string') {
            return value !== '' && value !== '.' && value.toLowerCase() !== 'null';
        }
        return !!value;
    };

    const values = [
        ...new Set(
            selectedCustomers
                .map((customer: Customer) => {
                    let customerValue = customer[customerKey] as string | number;

                    if (typeof customerValue === 'string') {
                        customerValue = customerValue.trim();

                        if (formatter) {
                            customerValue = formatter(customerValue);
                        }
                    }
                    return customerValue;
                })
                .filter((value) => valueValidator(value)),
        ),
    ];

    const customDefaultValue = () => {
        const customVal = (data[field] as string | number) ?? '';
        if (values.includes(customVal)) {
            return '';
        }

        if (valueValidator(customVal) === false) {
            return '';
        }

        if (validation && validation(customVal) !== true) {
            return '';
        }

        return customVal;
    };

    const [customValue, setCustomValue] = useState<string | number>(customDefaultValue());

    const updateValue = (value: string | number) => {
        setData({ ...data, [field]: value });
        setValue(field, value);
    };

    if (values.length === 1 && valueValidator(data?.[field] as string | number) === false) {
        updateValue(values[0]);
    }

    const handleCustomValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCustomValue(e.target.value);
        if (valueValidator(e.target.value) && (!validation || validation(e.target.value))) {
            updateValue(e.target.value);
        }
    };
    const customValueChecked = data?.[field] === customValue && valueValidator(customValue);

    useEffect(() => {
        if (!valueValidator(customValue)) {
            trigger().then((r) => r);
        }
    }, [customValue]);

    return (
        <Form.Group className="mb-0">
            <Form.Label>
                <FormattedMessage id={`CUSTOMER.MODAL.FUSION.FORM.${field.toUpperCase()}`} />
            </Form.Label>
            <div className="d-flex flex-wrap m-0 p-0">
                {values.map((value) => {
                    const checked = data?.[field] === value;
                    return (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                        <span onClick={() => updateValue(value)}>
                            <Form.Check
                                id={`${field}_${value}`}
                                name={field}
                                inline
                                type="radio"
                                value={value}
                                defaultChecked={checked}
                                checked={checked}
                                label={value}
                                ref={register({
                                    required: Intl.formatMessage({
                                        id: 'FORM.ERROR.REQUIRED',
                                    }),
                                })}
                                className={`rollbackUpdateForm__${
                                    checked ? 'selected' : 'unSelected'
                                } rounded-pill py-1 pl-2 pr-3 mb-2`}
                            />
                        </span>
                    );
                })}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <span
                    onClick={() => {
                        if (valueValidator(customValue)) {
                            updateValue(customValue);
                        }
                        customInputRef.current?.focus();
                    }}
                >
                    <Form.Check
                        id={`${field}_custom`}
                        name={field}
                        inline
                        type="radio"
                        value={customValue}
                        checked={customValueChecked}
                        readOnly={valueValidator(customValue) === false}
                        onClick={() => {
                            customInputRef.current?.focus();
                        }}
                        label={
                            <Form.Control
                                type="text"
                                className="py-0 px-1 m-0 h-100"
                                value={customValue}
                                placeholder={Intl.formatMessage({ id: 'CUSTOMER.MODAL.FUSION.FORM.PLACEHOLDER' })}
                                onFocus={handleCustomValueChange}
                                onChange={handleCustomValueChange}
                                ref={customInputRef}
                            />
                        }
                        ref={register({
                            required:
                                values.length > 0
                                    ? Intl.formatMessage({
                                          id: 'FORM.ERROR.REQUIRED',
                                      })
                                    : false,
                            ...(validation ? { validate: validation } : {}),
                        })}
                        className={`rollbackUpdateForm__${
                            customValueChecked ? 'selected' : 'unSelected'
                        } rounded-pill py-1 pl-2 pr-3 mb-2`}
                    />
                </span>
            </div>

            <ErrorForm errors={errors} name={field} />
        </Form.Group>
    );
};
FusionField.defaultProps = {
    validation: undefined,
    formatter: undefined,
};

export default FusionField;
