import classNames from "classnames";
import React from 'react';
import {Button, Image} from "react-bootstrap";
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl';
import NumberFormat from "react-number-format";
import {useDispatch} from "react-redux";

import {Intl} from "@src/_metronic/i18n/I18nProvider";

import {userIsAssociateToDealership} from "@app/helpers/UserHelper";

import { PartExchange } from '@app/crud/customers/customer.type';

import {useAppSelector} from "@app/hooks";
import {isPublishAutosphere} from "@app/pages/stock/helpers/StockRules";
import {actions} from "@app/store/modal/modal.store";
import { Vehicle } from '@app/store/stock/stock.type';

import Label from "../Label";
import {
    getPictureTemplate as getPartExchangePictureTemplate
} from '../PartExchange/Helpers';

import {
    getBrandTemplate,
    getFuelTypeTemplate,
    getGearsTypeTemplate,
    getIsFluidity,
    getPictureTemplate,
    getProductionYearTemplate,
    getRefDealershipId,
    getUrl
} from "./VehicleHelpers";

type VehicleInfoProps = {
    vehicle?: Vehicle,
    partExchange?: PartExchange,
    dealership: string,
    setShowVehicle: (v: boolean) => void,
    reprise?: boolean,
    className?: string,
    canUpdate?: boolean,
    canView?: boolean
    viewDetails?: boolean
    ribbons?: React.JSX.Element,
    isVN?: boolean,
    event: any,
}

const VehicleInfo = (
    {
        vehicle,
        partExchange,
        dealership,
        setShowVehicle,
        reprise,
        className,
        canUpdate,
        canView,
        viewDetails,
        ribbons,
        isVN,
        event
    }: VehicleInfoProps) => {
        const dispatch = useDispatch();
        const isContextEFB = useAppSelector((state) => state.auth.userData.context === 'efb');

        const priceHandler = () => {

        if (vehicle?.price) {

            let displayPrice =
                      <NumberFormat className="price-content"
                                    value={vehicle?.price > 0 ? vehicle?.price : '-'}
                                    decimalSeparator="."
                                    thousandSeparator=" "
                                    displayType="text"
                                    suffix=" €"/>

            if (vehicle?.type &&
                vehicle.type.toUpperCase() === 'VN'
            ) {

                displayPrice =
                    <div className="price-published">
                            {Intl.formatMessage({ id: "VEHICLE_INFO.VN.PRICE.PUBLISHED_PRICE" })}
                            &nbsp;
                            <NumberFormat className="price-content"
                                          value={vehicle.price}
                                          decimalSeparator="."
                                          thousandSeparator=" "
                                          displayType="text"
                                          suffix=" €"/>
                        </div>;

                if (vehicle?.catalog_price &&
                    vehicle.catalog_price > 0 &&
                    vehicle.catalog_price !== vehicle.price
                ) {
                    displayPrice =
                        <>
                            <Label variant="secondary" className="text-dark">
                                <div>{Intl.formatMessage({ id: "VEHICLE_INFO.VN.PRICE.CATALOG_PRICE" })}</div>
                                &nbsp;
                                <NumberFormat value={vehicle.catalog_price}
                                              decimalSeparator="."
                                              thousandSeparator=" "
                                              displayType="text"
                                              suffix=" €"/>
                            </Label>
                            {displayPrice}
                        </>
                }
            }

            return (
                <span className="price pt-4 pb-4">
                    {displayPrice}
                </span>
            );
        }

        return null;
    }


    const showFluidity = () => {
        if(!canUpdate) {
            return false;
        } else if (!dealership) {
            return true;
        }

        const isFluidity = getIsFluidity(vehicle);
        const isUserDealershipVehicle = userIsAssociateToDealership(getRefDealershipId(vehicle));

        return isFluidity && !isUserDealershipVehicle;
    }

    return (
      <div className={classNames('d-flex flex-column vehicle-container h-100 kt-ribbon kt-ribbon--round kt-ribbon--right', className)}>
          { (vehicle || partExchange) && (
            <img src={vehicle ? getPictureTemplate(vehicle) : getPartExchangePictureTemplate(partExchange)} width="100%" alt="véhicule" />
          )}
          {ribbons}
          <div className="position-relative">
              <div className=" d-flex flex-column text-center px-5 py-3 m-auto position-absolute marque-modele">
                  {showFluidity() && !reprise && <Label className="fluidity-label bg-closed text-white font-weight-bold">{Intl.formatMessage({ id: 'FLUIDITY.NAME' })}</Label>}
                  <span className="marque text-nowrap text-truncate">{getBrandTemplate(vehicle)} {vehicle?.model || ''}</span>
                  <span className="version">{vehicle ? vehicle?.version : 'Version'}</span>
              </div>
              <div className="d-flex flex-wrap justify-content-evenly mt-15 pb-4">
                  {getFuelTypeTemplate(vehicle) &&
                      <div className="d-flex w-lg-50 w-xxl-25 mt-5 flex-column align-items-center text-center">
                          <img src="/media/icons/fuel.svg" alt="fuel" className="mb-2"/>
                          <span className="text-wrap">{getFuelTypeTemplate(vehicle) === 'ND' ? '-' : getFuelTypeTemplate(vehicle) }</span>
                      </div>
                  }
                  <div className="d-flex w-lg-50 w-xxl-25 mt-5 flex-column align-items-center text-center">
                      <img src="/media/icons/mileage.svg" alt="mileage" className="mb-2"/>
                      <span>
                          {vehicle?.mileage ? <NumberFormat value={vehicle?.mileage}
                                                   thousandSeparator=" "
                                                   displayType="text"
                                                   suffix=" km"/>
                              : '-'}
                      </span>
                  </div>
                  {getProductionYearTemplate(vehicle) &&
                      <div className="d-flex w-lg-50 w-xxl-25 mt-5 flex-column align-items-center text-center">
                          <img src="/media/icons/date.svg" alt="date" className="mb-2"/>
                          <span>{getProductionYearTemplate(vehicle)}</span>
                      </div>
                  }
                  {getGearsTypeTemplate(vehicle) !== '-' && getGearsTypeTemplate(vehicle) &&
                      <div className="d-flex w-lg-50 w-xxl-25 mt-5 flex-column align-items-center text-center">
                          <img src="/media/icons/gearbox.svg" alt="gearbox" className="mb-2"/>
                          <span>{getGearsTypeTemplate(vehicle)}</span>
                      </div>
                  }
              </div>
              {
                  reprise ? (
                      <div className="d-flex flex-column align-items-center">
                          <div className={classNames("d-flex w-100 p-2 text-center justify-content-center estimate", {
                              'flex-column': partExchange?.isVehicleModified
                          })}>
                              <span>{Intl.formatMessage({ id: `VEHICLE_INFO.VIEW.LIFE.DETAIL.RECOVERY.ESTIMATE${partExchange?.isVehicleModified ? '_INITIAL' : ''}` })}</span>
                              <strong className="ml-2">
                                  {partExchange?.estimatedPrice ?
                                      <NumberFormat value={partExchange?.estimatedPrice}
                                                    decimalSeparator="."
                                                    thousandSeparator=" "
                                                    displayType="text"
                                                    suffix=" €"/>
                                      : '-'}
                              </strong>
                              {
                                  partExchange?.isVehicleModified &&
                                      <span className="estimate__modified text-danger">
                                          <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.MODIFIED"/>
                                      </span>
                              }
                          </div>
                          {
                              partExchange?.partExchangeReference &&
                              <div className="text-center p-4 button-container w-100">
                                  <span className="vehicle-recovery">
                                      <FormattedHTMLMessage id="VEHICLE_INFO.VIEW.LIFE.DETAIL.RECOVERY.WATCH_DETAIL"/>
                                  </span><br/>
                                  <Button variant="primary" className="mt-3" onClick={() => dispatch(actions.modalChange('afr_part_exchange_detail', {
                                      partExchange
                                  }))}>
                                      <i className="la la-mouse-pointer"/>
                                      {Intl.formatMessage({id: "VEHICLE_INFO.VIEW.LIFE.DETAIL.RECOVERY.RECOVERY_DETAIL"})}
                                  </Button>
                              </div>
                          }
                      </div>
                  ) : (
                      <div className="d-flex flex-column align-items-center">
                        { event && event?.fullWeb &&
                            <div className="d-flex w-100 p-2 text-center justify-content-center estimate">
                                <span>{Intl.formatMessage({ id: "VEHICLE_INFO.VIEW.LIFE.DETAIL.RECOVERY.ESTIMATE" })}</span>
                                <strong className="ml-2">
                                    {event?.fullWeb.orderwebPrice ?
                                        <NumberFormat value={event?.fullWeb.orderwebPrice}
                                                    decimalSeparator="."
                                                    thousandSeparator=" "
                                                    displayType="text"
                                                    suffix=" €"/>
                                        : '-'}
                                </strong>
                            </div>
                        }
                          {priceHandler()}
                          {viewDetails &&
                          <div className={classNames('vehicle-detail pt-4', {
                              'mb-4': viewDetails && !isPublishAutosphere(vehicle)
                          })}>
                              {!isVN && vehicle?.license_number &&
                                  <div className="vehicle-detail_content">
                                      <FormattedMessage id="VEHICLE_INFO.LICENSE_NUMBER"/>
                                      <b> {vehicle?.license_number}</b>
                                  </div>
                              }
                              {isVN && vehicle?.serial_number &&
                                  <div className="vehicle-detail_content ml-4">
                                      <FormattedMessage id="VEHICLE_INFO.SERIAL_NUMBER"/>
                                      <b> {vehicle?.serial_number}</b>
                                  </div>
                              }
                              {vehicle?.body_color &&
                                  <div className="vehicle-detail_content ml-4">
                                      <FormattedMessage id="VEHICLE_INFO.COLOR"/>
                                      <b> {vehicle?.body_color}</b>
                                  </div>
                              }
                              {vehicle?.warranty_label &&
                                  <div className="vehicle-detail_content ml-4">
                                      <FormattedMessage id="VEHICLE_INFO.WARRANTY"/>
                                      <b> {vehicle?.warranty_label}</b>
                                  </div>
                              }
                              {event?.fullWeb && event?.fullWeb.orderwebVNumpoli &&
                                  <div className="vehicle-detail_content">
                                      <FormattedMessage id="VEHICLE_INFO.LICENSE_NUMBER"/>
                                      <b> {event?.fullWeb.orderwebVNumpoli}</b>
                                  </div>
                              }
                          </div>
                          }
                          {((getUrl(vehicle, null) && !canView) || canUpdate) &&
                              <div className="d-flex w-100 p-4 justify-content-around button-container">
                                  {getUrl(vehicle, null) && !canView &&
                                      <a target="_blank" href={getUrl(vehicle, null, isContextEFB)} rel="noreferrer" className="px-1">
                                          <Button variant="outline-primary h-100">
                                              <i className="la la-eye"/>{Intl.formatMessage({id: "VEHICLE_INFO.WATCH_AD"})}
                                          </Button>
                                      </a>
                                  }
                                  {canUpdate &&
                                      <div className="px-1">
                                          <Button variant="outline-primary h-100" onClick={() => setShowVehicle(true)}>
                                              <i className="la la-undo-alt"/>
                                              {Intl.formatMessage({id: "VEHICLE_INFO.CHANGE_VEHICLE"})}
                                          </Button>
                                      </div>
                                  }
                              </div>
                          }
                          {showFluidity() &&
                              <div className="fluidity-banner d-flex label-light-primary justify-content-around" style={{width: '100%'}}>
                                  <div className="fluidity-banner_image">
                                      <Image src="/media/pages/customers/reservation-fluidite.svg"/>
                                  </div>
                                  <div className="fluidity-banner_text">
                                      <FormattedHTMLMessage id="CUSTOMER.FLUIDITY.TEXT" />
                                  </div>
                                  <div className="fluidity-banner_label">
                                      <Label
                                          className="text-dark font-weight-bold"
                                          size=""
                                          fontSize="font-size-lg">
                                          {Intl.formatMessage({id: "CUSTOMER.FLUIDITY.NUMBER"})}
                                      </Label>
                                  </div>
                              </div>
                          }
                          {canView && (!viewDetails || (viewDetails && isPublishAutosphere(vehicle))) &&
                              <a target="_blank" href={getUrl(vehicle)} className="d-flex p-4 justify-content-center mt-4" style={{width: '100%'}} rel="noreferrer">
                                  <Button variant="outline-primary">
                                      <i className="la la-eye"/>{Intl.formatMessage({id: "VEHICLE_INFO.WATCH_AD"})}
                                  </Button>
                              </a>
                          }
                      </div>
                  )
              }
          </div>
      </div>
    );
}

VehicleInfo.defaultProps = {
    canUpdate: true,
    canView: false,
    viewDetails: false,
    ribbons: null,
    isVN: false
}

export default VehicleInfo;
