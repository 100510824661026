import dayjs from 'dayjs';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { DashboardContext } from '../../DashboardProvider';

const DashboardCalendar = () => {
    const { setParams } = useContext(DashboardContext);
    const [start, setStart] = useState(dayjs().date(1).toDate());
    const [end, setEnd] = useState(undefined);
    const overlayRef = useRef<OverlayPanel>(null);

    const onChangeHandler = (dates: CalendarChangeEvent) => {
        setStart(Array.isArray(dates.value) && dates.value[0]);
        setEnd(Array.isArray(dates.value) && dates.value[1]);
    };

    const toggleHandler = (event: React.MouseEvent) => {
        if (overlayRef !== undefined && overlayRef.current !== undefined) {
            overlayRef.current.toggle(event, null);
        }
    };

    const selectCurrentMonth = () => {
        setStart(dayjs().date(1).toDate());
        setEnd(undefined);
    };

    const selectPreviousMonth = () => {
        setStart(dayjs().date(1).add(-1, 'month').toDate());
        setEnd(dayjs().date(1).add(-1, 'day').toDate());
    };

    useEffect(() => {
        setParams((params: any) => ({
            ...params,
            currentPeriod: null,
            start: dayjs(start).format('DD/MM/YYYY'),
            end: end ? dayjs(end).format('DD/MM/YYYY') : null,
        }));
    }, [start, end]);

    return (
        <>
            <button type="button" className="p-dropdown h-auto label label-inline" onClick={toggleHandler}>
                <span className="p-dropdown-label p-inputtext">
                    {!start && <FormattedHTMLMessage id="DASHBOARD.CALENDAR.EMPTY" />}
                    {start && !end && (
                        <FormattedHTMLMessage
                            id="DASHBOARD.CALENDAR.FROM"
                            values={{ start: dayjs(start).format('DD/MM/YYYY') }}
                        />
                    )}
                    {start && end && (
                        <FormattedHTMLMessage
                            id="DASHBOARD.CALENDAR.RANGE"
                            values={{ start: dayjs(start).format('DD/MM/YYYY'), end: dayjs(end).format('DD/MM/YYYY') }}
                        />
                    )}
                </span>
                <i className="ml-5 pi pi-chevron-down" />
            </button>
            {/* @ts-ignore */}
            <OverlayPanel ref={overlayRef} className="p-datepicker-overlay">
                <div className="p-datepicker-group-container">
                    <Calendar
                        inline
                        selectionMode="range"
                        readOnlyInput
                        dateFormat="yy/mm/dd"
                        value={[start, end]}
                        onChange={onChangeHandler}
                    />
                </div>
                <div className="d-flex justify-content-between">
                    <button type="button" className="btn btn-secondary btn-sm" onClick={selectCurrentMonth}>
                        <FormattedMessage id="DASHBOARD.CALENDAR.CURRENT_MONTH" />
                    </button>
                    <button type="button" className="btn btn-secondary btn-sm" onClick={selectPreviousMonth}>
                        <FormattedMessage id="DASHBOARD.CALENDAR.PREVIOUS_MONTH" />
                    </button>
                </div>
            </OverlayPanel>
        </>
    );
};

export default DashboardCalendar;
