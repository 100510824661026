import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

import { empty } from '@app/helpers/ToolsHelper';
import { getCurrentDealership } from '@app/helpers/UserHelper';

import { getBrandTemplate, getUrl } from '@app/partials/content/Vehicle/VehicleHelpers';

import { getCivility } from '../../../customers/Helpers/Customer';

const EMAIL_TAGS = {
    '{{CONTACT_CIVILITY}}': {
        label: 'Civilité du contact',
        value: (data) => getCivility(data?.customer?.civility),
    },
    '{{CONTACT_NAME}}': {
        label: 'Nom du contact',
        value: (data) => `${data?.customer?.lastname || ''} ${data?.customer?.firstname || ''}`,
    },
    '{{CONTACT_PHONE}}': {
        label: 'Téléphone du contact',
        value: (data) => {
            const mobile = parsePhoneNumberFromString(data?.customer?.mobile || '', Locale.toUpperCase());
            if (mobile) {
                return mobile.formatNational();
            }

            const phone = parsePhoneNumberFromString(data?.customer?.phone || '', Locale.toUpperCase());
            if (phone) {
                return phone.formatNational();
            }

            return '';
        },
    },
    '{{VEHICLES}}': {
        label: 'Liste des véhicules',
        value: (data) => {
            if (data?.vehicles) {
                return data.vehicles
                    .map((vehicle) => {
                        const link = getUrl(vehicle);
                        const title = `${getBrandTemplate(vehicle, '')} ${vehicle.model} ${vehicle?.version}`;
                        return `<a href="${link}">${title}</a>`;
                    })
                    .join('<br/>');
            }

            return null;
        },
    },
    '{{DEALERSHIP_USUAL_NAME}}': {
        label: 'Nom de la concession',
        value: (data) => {
            const dealership = getCurrentDealership();
            return data?.dealership?.title || dealership?.name || '';
        },
    },
    '{{DEALER_ADDRESS}}': {
        label: 'Adresse de la concession',
        value: (data) => {
            const dealership = getCurrentDealership();

            if (data?.dealership?.address) {
                return `${data?.dealership?.address} - ${data?.dealership?.zipCode} ${data?.dealership?.city}`;
            } else if (dealership?.address) {
                return `${dealership?.address} - ${dealership?.zipCode} ${dealership?.city}`;
            }

            return '';
        },
    },
    '{{VEHICLE_BRAND}}': {
        label: 'Marque du véhicule',
        value: (data) => getBrandTemplate(data?.vehicle, ''),
    },
    '{{VEHICLE_MODEL}}': {
        label: 'Modèle du véhicule',
        value: (data) => data?.vehicle?.model || '',
    },
    '{{VEHICLE_VERSION}}': {
        label: 'Version du véhicule',
        value: (data) => data?.vehicle?.version || '',
    },
    '{{VEHICLE_LINK}}': {
        label: 'Lien du véhicule',
        value: (data) => {
            const link = getUrl(data?.vehicle, null);

            if (link) {
                const title = `${getBrandTemplate(data?.vehicle, '')} ${data?.vehicle.model} ${data?.vehicle?.version}`;
                return `<a href="${link}">${title}</a>`;
            }

            return '';
        },
    },
    '{{DEALER_NAME}}': {
        label: 'Nom du vendeur',
        value: (data) => `${data?.userData?.last_name || ''} ${data?.userData?.first_name}`,
    },
    '{{DEALER_ROLE}}': {
        label: 'Rôle du vendeur',
        value: (data) => `${data?.userData?.role.name || ''}`,
    },
    '{{DEALER_PHONE}}': {
        label: 'Téléphone du vendeur',
        value: (data) => {
            const mobile = parsePhoneNumberFromString(data?.userData?.cell_phone || '', Locale.toUpperCase());
            if (mobile) {
                return mobile.formatNational();
            }

            const phone = parsePhoneNumberFromString(data?.userData?.phone || '', Locale.toUpperCase());
            if (phone) {
                return phone.formatNational();
            }

            return '';
        },
    },
    '{{DEALER_MAIL}}': {
        label: 'Email du vendeur',
        value: (data) => data?.userData?.email || '',
    },
};

export default EMAIL_TAGS;

export const getEmailLabel = (label) => `{{${label}}}`;

export const formatEmailTag = (
    tag,
    originalValue,
    currentValue,
    editable = false,
    checkValue = true,
) => `<jodit class="email-tag ${checkValue && originalValue === currentValue && 'text-danger'}"
                  contenteditable="${editable}"
                  id="${tag}">
        ${currentValue}
    </jodit>`;

export const formatEmailValue = (value, data) => {
    const html = new DOMParser().parseFromString(value, 'text/html');

    let { length } = html.getElementsByClassName('email-tag');

    // Remove existing span
    while (length > 0) {
        const tag = html.getElementsByClassName('email-tag')[0];
        let deepestChild = tag;
        const id = tag.getAttribute('id');
        tag.removeAttribute('id');
        tag.removeAttribute('class');
        while (deepestChild.children.length > 0) {
            [deepestChild] = deepestChild.children;
        }
        deepestChild.innerHTML = id;
        deepestChild.className = '';

        // eslint-disable-next-line no-plusplus
        length--;
    }

    // Get new content value
    let innerHtml = html.documentElement.innerHTML;

    // Add dynamic span and fill with values
    const matches = [...(innerHtml?.matchAll?.(/{{([^\\}.]*)}}/g) || [])];

    if (matches?.length > 0) {
        matches.reverse().forEach((params) => {
            const match = params[0];
            const { index } = params;
            const tag = EMAIL_TAGS[match];
            const label = getEmailLabel(tag?.label);
            let emaiLabel = label;

            if (tag?.value) {
                const newValue = tag.value(data);
                if (!empty(newValue)) {
                    emaiLabel = newValue;
                }
            }

            const formattedTag = formatEmailTag(match, label, emaiLabel);
            const slicedInnerHtml = innerHtml.slice(0, index) + innerHtml.slice(index + match.length);
            innerHtml = slicedInnerHtml.slice(0, index) + formattedTag + slicedInnerHtml.slice(index);
        });
    }

    return innerHtml;
};
