import frAlerts from "./administration/alerts.json";
import frConsoleLeads from "./administration/console-leads.json";
import frEmailTemplate from "./administration/email-template.json";
import frLeadTags from "./administration/lead-tags.json"
import frSignatureTemplate from "./administration/signature-template.json";
import frAgenda from "./agenda.json"
import frApv from './apv'
import frApplications from "./autouser"
import frCampaigns from "./campaigns/campaigns.json";
import frConsentPublic from "./consent/consent-public.json";
import frCustomers from "./customers";
import frDashboard from "./dashboard"
import frFluidity from "./fluidity/fluidity.json";
import frGeneric from "./generic.json"
import frLeadsOnline from "./leadsOnline.json";
import frManufacturerPackage from "./manufacturer"
import frMenu from "./menu.json"
import frRPA from "./partials/content/RPA.json";
import frVehicleInfo from "./partials/content/vehicleInfo.json";
import frSecuryCar from "./securycar/securycar.json";
import frSettings from "./settings.json";
import frSmartRepair from "./smartRepair"
import frStock from "./stock";
import frUsers from "./user/user.json";

const frMessages = {
  ...frGeneric,
  ...frMenu,
  ...frApplications,
  ...frAgenda,
  ...frSettings,
  ...frApv,
  ...frCustomers,
  ...frLeadsOnline,
  ...frUsers,
  ...frFluidity,
  ...frStock,
  ...frEmailTemplate,
  ...frAlerts,
  ...frSignatureTemplate,
  ...frVehicleInfo,
  ...frDashboard,
  ...frConsoleLeads,
  ...frCampaigns,
  ...frLeadTags,
  ...frConsoleLeads,
  ...frRPA,
  ...frSmartRepair,
  ...frConsentPublic,
  ...frManufacturerPackage,
  ...frSecuryCar
}

export default frMessages;
