import React from 'react';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Package } from '@app/pages/apv/orders/Order';

import ErrorForm from '@app/partials/layout/ErrorForm';

import PackageName from '@app/components/forms/customers/PackageName';
import PackagePrice from '@app/components/forms/customers/PackagePrice';

import APVInStoreTimingList from './APVInStoreAddPackage/APVInStoreTimingList';

import '../APVInStore.scss';

type APVInStoreAddPackageCustomProps = {
    required: boolean;
    addPackage: (customPackage: Partial<Package>) => void;
    setShowAddPackageCustom: (v: boolean) => void;
};

const APVInStoreAddPackageCustom = ({
    required,
    addPackage,
    setShowAddPackageCustom,
}: APVInStoreAddPackageCustomProps) => {
    const methods = useFormContext();
    const { getValues, watch, setValue, errors } = methods;

    // Monitor form values for enabling/disabling the OK button
    const customPackageName = watch('customPackageName');
    const customPackagePrice = watch('customPackagePrice');
    const selectedCustomedTiming = watch('selectedCustomedTiming');

    // Determine if the OK button should be enabled
    const isFormComplete = customPackageName && customPackagePrice && selectedCustomedTiming;

    return (
        <>
            <div className="d-flex justify-content-between m-0 align-items-center mt-15 pb-1 apvInStore__customPackage">
                <div className="col-4 p-0">
                    <div className="mr-4">
                        <PackageName required={required} />
                    </div>
                </div>
                <div className="col-4">
                    <div className="mb-3">
                        <APVInStoreTimingList methods={methods} required={required} />
                    </div>
                </div>
                <div className="col-2">
                    <div className="mb-3">
                        <PackagePrice methods={methods} required={required} decimalPlaces={2} />
                    </div>
                </div>
                <div className="col-2 d-flex align-items-center mb-4 justify-content-between">
                    <Button
                        variant="primary"
                        disabled={!isFormComplete}
                        onClick={() => {
                            const packageToAdd = {
                                name: getValues('customPackageName'),
                                price: getValues('customPackagePrice'),
                                mecaplanning: {
                                    duration: getValues('selectedCustomedTiming'),
                                },
                            };
                            addPackage(packageToAdd);
                            setValue('customPackageName', '');
                            setValue('customPackagePrice', '');
                            setValue('selectedCustomedTiming', '');
                            setShowAddPackageCustom(false);
                        }}
                    >
                        <FormattedMessage id="TRANSLATOR.OK" />
                    </Button>

                    <Button
                        className="p-0"
                        variant=""
                        onClick={() => {
                            setShowAddPackageCustom(false);
                        }}
                    >
                        <i className="las la-times la-2x" />
                    </Button>
                </div>
            </div>
            <ErrorForm errors={errors} name="customPackagePrice" />
        </>
    );
};

export default APVInStoreAddPackageCustom;
