import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import KTUtil from '@src/_metronic/_assets/js/util';
import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';

import { getUserPerimeter } from '@app/crud/autouser/user.crud';

import { updateDatas } from '@app/pages/autouser/users/Helpers/Dealership';

import SearchInput from '@app/partials/content/SearchInput';

import FacetsHandler from '../../helpers/FacetsHandler';
import StockTreeSelect from '../../partials/StockTreeSelect';
import StockIndicators from '../../StockIndicators';

import StockVehiclesFilterChipsBlock from './filters/partials/StockVehiclesFilterChipsBlock';
import StockVehiclesFilters from './filters/StockVehiclesFilters';
import StockVehiclesFiltersQuick from './filters/StockVehiclesFiltersQuick';

export default function StockVehiclesHeader({ handleFilter, stockFilters, facets, loading, setLoadedPerimeter }) {
    const {
        updateFilters,
        resetAllFilters,
        updatePendingFilters,
        releasePendingFilters,
        resetFilters,
        resetFilter,
        updatedFilters,
    } = stockFilters;

    const [isOpenFiltersBlock, setIsOpenFiltersBlock] = useState(false);
    const [isDisabledResetButton, setIsDisabledResetButton] = useState(true);
    const { data: perimeter } = useFetch({
        fetchAction: getUserPerimeter,
        resultInterceptor: (response) => {
            setLoadedPerimeter(true);
            return updateDatas(response);
        },
        axios: false,
        autoFetch: true,
    });

    const variants = {
        open: { opacity: 1, width: '100%', height: 'auto', overflow: 'hidden' },
        closed: { opacity: 0, width: '100%', height: '0px', overflow: 'hidden' },
    };

    const [filterFacet, setFilterFacet] = useState({});
    const [counter, setCounter] = useState({});

    useEffect(() => {
        if (facets) {
            const facetData = FacetsHandler(facets);
            setCounter(facetData?.counter || null);
            setFilterFacet(facetData?.filterData || null);
        }
    }, [facets]);

    return (
        <>
            <Row className="p-4">
                <Col
                    xl={KTUtil.sizeHandler(
                        [
                            { device: 1300, value: 7 },
                            { device: 1500, value: 6 },
                            { device: 1800, value: 5 },
                        ],
                        4,
                    )}
                    lg={KTUtil.sizeHandler(
                        [
                            { device: 1150, value: 8 },
                            { device: 1300, value: 7 },
                        ],
                        6,
                    )}
                >
                    <StockIndicators
                        counter={counter}
                        loading={loading}
                        handleFilter={handleFilter}
                        resetFilters={resetFilters}
                    />
                </Col>
                <Col
                    xl={KTUtil.sizeHandler(
                        [
                            { device: 1300, value: 5 },
                            { device: 1500, value: 6 },
                            { device: 1800, value: 7 },
                        ],
                        8,
                    )}
                    lg={KTUtil.sizeHandler(
                        [
                            { device: 1150, value: 4 },
                            { device: 1300, value: 5 },
                        ],
                        6,
                    )}
                    className="d-flex flex-column justify-content-between"
                >
                    <StockTreeSelect
                        perimeter={perimeter}
                        updatePendingFilters={updatePendingFilters}
                        releasePendingFilters={releasePendingFilters}
                    />
                    <SearchInput
                        onChange={handleFilter}
                        name="keyword"
                        inputClassName="m-0"
                        placeholder={Intl.formatMessage({ id: 'STOCK_VEHICLES.FILTERS.SEARCH_INPUT.PLACEHOLDER' })}
                    />
                    <StockVehiclesFiltersQuick
                        updateFilters={updateFilters}
                        resetAllFilters={resetAllFilters}
                        resetFilter={resetFilter}
                        isOpenFiltersBlock={isOpenFiltersBlock}
                        setIsOpenFiltersBlock={setIsOpenFiltersBlock}
                        isDisabledResetButton={isDisabledResetButton}
                    />
                </Col>
            </Row>
            <Row className="p-4">
                <StockVehiclesFilterChipsBlock
                    resetFilter={resetFilter}
                    resetFilters={resetFilters}
                    updateFilters={updateFilters}
                    updatedFilters={updatedFilters}
                    setIsDisabledResetButton={setIsDisabledResetButton}
                />
            </Row>
            <motion.nav animate={isOpenFiltersBlock ? 'open' : 'closed'} variants={variants}>
                <StockVehiclesFilters
                    facets={filterFacet}
                    updatePendingFilters={updatePendingFilters}
                    releasePendingFilters={releasePendingFilters}
                    updateFilters={updateFilters}
                    setIsOpenFiltersBlock={setIsOpenFiltersBlock}
                />
            </motion.nav>
        </>
    );
}
