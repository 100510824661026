import { getUserData } from '@app/helpers/UserHelper';

import LeadLooseDefault from '@app/pages/customers/Modals/LeadLoose/LeadLooseDefault';
import LeadLooseFid from '@app/pages/customers/Modals/LeadLoose/LeadLooseFid';
import LeadLooseLocal from '@app/pages/customers/Modals/LeadLoose/LeadLooseLocal';
import LeadLooseReprise from '@app/pages/customers/Modals/LeadLoose/LeadLooseReprise';
import LeadWonDefault from '@app/pages/customers/Modals/LeadWon/LeadWonDefault';
import LeadWonFid from '@app/pages/customers/Modals/LeadWon/LeadWonFid';
import LeadWonLocal from '@app/pages/customers/Modals/LeadWon/LeadWonLocal';
import LeadWonReprise from '@app/pages/customers/Modals/LeadWon/LeadWonReprise';
import { leadTypes } from '@app/store/customers/customers.store';

const types = {
    local: {
        won: LeadWonLocal,
        loose: LeadLooseLocal,
    },
    reprise: {
        won: LeadWonReprise,
        loose: LeadLooseReprise,
    },
    achat_cash: {
        won: LeadWonReprise,
        loose: LeadLooseReprise,
    },
    local_achat_cash: {
        won: LeadWonReprise,
        loose: LeadLooseReprise,
    },
    achat_cash_abandon: {
        won: LeadWonReprise,
        loose: LeadLooseReprise,
    },
    fid_vn: {
        won: LeadWonFid,
        loose: LeadLooseFid,
    },
    fid_vo: {
        won: LeadWonFid,
        loose: LeadLooseFid,
    },
    default: {
        won: LeadWonDefault,
        loose: LeadLooseDefault,
    },
};

/**
 * Get lead type slug
 *
 * @param lead
 * @returns {*}
 */
const getLeadTypeSlug = (lead) => lead?.leadTypeSlug || lead?.leadType?.slug;

/**
 * Get status color by slug
 *
 * @param status
 * @returns {*|string}
 */
export const getLeadStatus = (status) => {
    const statusList = {
        in_progress_appointment_to_come: 'primary',
        in_progress_appointment_confirmed: 'primary',
        in_progress_relaunch_to_come: 'primary',
        in_progress_appointment_to_confirm: 'danger',
        in_progress_action_to_do: 'danger',
        in_progress_relaunch_to_qualify: 'danger',
        in_progress_appointment_to_qualify: 'danger',
        in_progress_contract_generated: 'danger',
        in_progress_awaiting_opportunity_update: 'closed_done',
        pending: 'danger',
        pending_30_min: 'danger',
        pending_60_min: 'danger',
        pending_90_min: 'danger',
        pending_120_min: 'danger',
        taken: 'danger',
        taken_30_min: 'danger',
        taken_60_min: 'danger',
        taken_90_min: 'danger',
        taken_120_min: 'danger',
        closed_won: 'success',
        closed_lost: 'closed',
        closed_auto: 'closed',
        closed_done: 'closed_done',
        pending_15_days: 'danger',
        pending_30_days: 'danger',
        taken_15_days: 'danger',
        taken_30_days: 'danger',
        waiting_order: 'warning',
        in_preparation_order: 'success',
        scheduled_installation: 'success',
    };

    return statusList[status] ? statusList[status] : 'primary';
};

/**
 *
 * @param status
 * @returns {*|string}
 */
export const getLeadStatusIcon = (status) => {
    const statusList = {
        pending_30_min: 'las la-hourglass-half',
        pending_60_min: 'las la-hourglass-half',
        pending_90_min: 'las la-hourglass-half',
        pending_120_min: 'las la-hourglass-half',
        taken_30_min: 'las la-hourglass-half',
        taken_60_min: 'las la-hourglass-half',
        taken_90_min: 'las la-hourglass-half',
        taken_120_min: 'las la-hourglass-half',
        pending_15_days: 'las la-hourglass-half',
        pending_30_days: 'las la-hourglass-half',
        taken_15_days: 'las la-hourglass-half',
        taken_30_days: 'las la-hourglass-half',
    };

    return statusList[status] || null;
};

/**
 * Get dealerships count of leads
 *
 * @param leads
 * @returns {*}
 */
export function getLeadsDealerships(leads) {
    if (!leads) return {};

    const dealerships = leads?.reduce?.((acc, curr) => {
        if (!acc[curr?.dealershipId]) acc[curr?.dealershipId] = 0;

        acc[curr?.dealershipId] += 1;

        return acc;
    }, {});

    return Object.entries(dealerships)?.reduce?.((acc, [refDealershipId, count]) => {
        if (count > 1) {
            acc[refDealershipId] = count;
        }

        return acc;
    }, {});
}

/**
 * Leads can be linked
 *
 * @param leads
 * @param conditions
 * @returns {boolean}
 */
export function leadsCanBeLinked(leads, conditions = [null]) {
    const userData = getUserData()?.userData;

    const eligibleLeads = leads?.filter(
        (_lead) =>
            _lead?.ownerId === userData.id &&
            ![leadTypes.localApv, leadTypes.localHomeCharge].includes(_lead?.leadType?.slug),
    );

    if (eligibleLeads?.length > 0) {
        const dealerships = getLeadsDealerships(eligibleLeads);

        const hasIsJoined = eligibleLeads.find(
            (_lead) => conditions.includes(_lead?.isJoined) && dealerships?.[_lead?.dealershipId],
        );

        return hasIsJoined && eligibleLeads.length > 1;
    }

    return false;
}

/**
 * Get leads vehicles
 *
 * @param leads
 * @param vehicles
 */
export const getLeadsVehicles = (leads, vehicles = []) => {
    let leadVehicles = [];

    leads?.forEach?.((lead) => {
        if (lead?.leadElements?.length > 0) {
            lead.leadElements.forEach((leadElement) => {
                if (leadElement?.vehicle) {
                    leadVehicles.push(leadElement?.vehicle);
                }
            });
        }

        if (lead?.linkedLeads) {
            leadVehicles = [...vehicles, ...getLeadsVehicles(lead.linkedLeads)];
        }
    });

    return leadVehicles;
};

/**
 *
 * @param lead
 * @returns {*|(function({params: *, listingParams?: *, lead: *, showModal?: *}): *)}
 */
export const getWonModal = (lead) => {
    const leadTypeSlug = getLeadTypeSlug(lead);
    return types[leadTypeSlug]?.won || types.default?.won;
};

/**
 *
 * @param lead
 * @returns {*}
 */
export const getLostModal = (lead) => {
    const leadTypeSlug = getLeadTypeSlug(lead);
    return types[leadTypeSlug]?.loose || types.default?.loose;
};

/**
 * Check if activity refresh is available
 *
 * @param last_action
 * @returns {boolean}
 */
export const doActivityRefresh = (last_action) => !['update_lead_element', 'update_comment'].includes(last_action);

/**
 * Get leadId
 * @param lead
 * @returns {*|undefined}
 */
export const getLeadId = (lead) => {
    return lead?.id || lead?.identifier || undefined;
};

export const leadSlug = {
    LEAD_AUDI_ORIGIN_SLUG: 'audi.fr',
    LEAD_ONE_LEAD_ORIGIN_SLUG: 'one_lead',
    CALL_TRACKING_STATUS_AUDI_CALL_IN_TIME: 'CALLED_IN_TIME',
    CALL_TRACKING_STATUS_ONE_LEAD_CALL_IN_TIME: 'Called in time',
    CALL_TRACKING_STATUS_AUDI_CALLED_AFTER_DEADLINE: 'CALLED_AFTER_DEADLINE',
    CALL_TRACKING_STATUS_ONE_LEAD_CALLED_AFTER_DEADLINE: 'Called after deadline',
    CALL_TRACKING_STATUS_COMMENT_JOINT: 'JOINT',
};

/**
 * Check if the current user is the lead owner
 *
 * @param lead
 * @returns {boolean}
 */
export const isLeadOwner = (lead) => {
    const userData = getUserData()?.userData;

    return lead.ownerId === userData.id;
};
