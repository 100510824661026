import classNames from 'classnames';
import React from 'react';
import { Card } from 'react-bootstrap';

const BusinessChild = ({ title, icone, children, className }) => (
    <Card className={classNames('business card--shadowed', className)}>
        <Card.Header>
            <div className="d-flex align-items-center">
                <i className={`business__icon la ${icone} la-lg text-light mr-2`} />
                <span>{title}</span>
            </div>
        </Card.Header>
        <Card.Body className="d-flex flex-column p-4 justify-content-between">{children}</Card.Body>
    </Card>
);

export default BusinessChild;
