import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { signContract } from '@app/crud/securyweb/securyweb.crud';

import variantTypes from '@app/constants';
import { useAppSelector } from '@app/hooks';

import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

type SecuryCarContractConfirmationCGVProps = {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
};

const SecuryCarContractCGV = ({ showModal, setShowModal }: SecuryCarContractConfirmationCGVProps) => {
    const Intl = useIntl();
    const [loading, setLoading] = useState(false);
    const methods = useForm();
    const {
        handleSubmit,
        // watch,
        register,
        getValues,
    } = methods;
    const { params } = useAppSelector((state) => state.modal);

    const onSubmit = () => {
        const values = getValues();
        const url = window.location.href;

        const dataToSend = {
            idContrat: params.idContract,
            autoriseGroupe: values.check_group,
            autorisePartenaires: values.check_partner,
            urlSuccesRedirectUniversign: `${url}?securycar=success`,
            urlErreurRedirectUniversign: `${url}?securycar=failure`,
            urlAnnulerRedirectUniversign: `${url}?securycar=cancel`,
        };

        setLoading(true);

        signContract(dataToSend)
            .then((response) => {
                if (response.result.ok && response.result.url) {
                    const newTab = window.open(response.result.url, '_blank');
                    if (newTab) {
                        toast({
                            variant: variantTypes.SUCCESS,
                            message: Intl.formatMessage({ id: 'TRANSLATOR.SUCCESS' }),
                        });
                        setShowModal(false);
                    } else {
                        toast({
                            variant: variantTypes.DANGER,
                            message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.ERROR.SIGN' }),
                        });
                    }

                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
                toast({
                    variant: variantTypes.DANGER,
                    message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                });
            });
    };

    const onError = () => {
        toast({
            variant: variantTypes.DANGER,
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    return (
        <ModalDefault
            show={showModal}
            bodyClassName="px-20"
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-edit" />}
            title={<FormattedMessage id="SECURYCAR.CONTRACT.MODAL.OPTIN.TITLE" />}
            body={
                <FormProvider {...methods}>
                    {loading && (
                        <Loader
                            style={{ width: '5rem', height: '5rem' }}
                            overlay
                            wrapperStyle={{ top: '0', left: '0' }}
                        />
                    )}
                    <Form.Group className="d-flex">
                        <Form.Check type="checkbox" disabled checked />
                        <Form.Label className="ml-4">
                            <FormattedMessage id="SECURYCAR.CONTRACT.MODAL.OPTIN.CGV" />
                        </Form.Label>
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label>
                            <FormattedMessage id="SECURYCAR.CONTRACT.MODAL.OPTIN.LAW_1" />
                            <a href={`mailto:${Intl.formatMessage({ id: 'SECURYCAR.CONTRACT.MODAL.OPTIN.MAIL' })}`}>
                                <FormattedMessage id="SECURYCAR.CONTRACT.MODAL.OPTIN.MAIL" />
                            </a>
                            <FormattedMessage id="SECURYCAR.CONTRACT.MODAL.OPTIN.LAW_2" />
                        </Form.Label>
                    </Form.Group>

                    <Form.Group className="d-flex">
                        <Form.Check type="checkbox" name="check_group" ref={register} />
                        <Form.Label className="ml-4">
                            <FormattedMessage id="SECURYCAR.CONTRACT.MODAL.OPTIN.CHECK_GROUP" />
                        </Form.Label>
                    </Form.Group>

                    <Form.Group className="d-flex">
                        <Form.Check type="checkbox" name="check_partner" ref={register} />
                        <Form.Label className="ml-4">
                            <FormattedMessage id="SECURYCAR.CONTRACT.MODAL.OPTIN.CHECK_PARTNER" />
                        </Form.Label>
                    </Form.Group>
                </FormProvider>
            }
            footer={
                <div className="d-flex justify-content-center">
                    <Button variant="primary" onClick={handleSubmit(onSubmit, onError)} disabled={loading}>
                        <FormattedMessage id="SECURYCAR.CONTRACT.MODAL.OPTIN.BUTTON" />
                    </Button>
                </div>
            }
        />
    );
};

export default SecuryCarContractCGV;
