import React from 'react';
import { Image, Row } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';

const PricingHandBand = ({ count, type, isHistory }: { count: number; type: string; isHistory: boolean }) => {
    const title = isHistory ? (
        <FormattedHTMLMessage id="STOCK.PRICING.HEADBAND.HISTORY.TITLE" values={{ number: count }} />
    ) : (
        <>
            <FormattedHTMLMessage id="STOCK.PRICING.HEADBAND.PRICING_TO_DO.TITLE" values={{ number: count }} />
            {type === 'list' && <FormattedHTMLMessage id="STOCK.PRICING.HEADBAND.PRICING_TO_DO.TITLE.TREAT" />}
            {type === 'check' && <FormattedHTMLMessage id="STOCK.PRICING.HEADBAND.PRICING_TO_DO.TITLE.CHECK" />}
        </>
    );

    return (
        <div className="customer-handband my-lg-0 my-2">
            <Row className="font-size-h4 text-white headband__content">
                <Image
                    className="customer-handband__pic ml-5"
                    src="/media/pages/customers/fiche-client-aucune-activite-en-cours.svg"
                />
                <div className="ml-5 border-bottom">{title}</div>
            </Row>
            <Row className="font-size-h4 text-white headband__content headband__content--scroll">
                <Image
                    className="customer-handband__pic--small ml-5"
                    src="/media/pages/customers/fiche-client-aucune-activite-en-cours.svg"
                />
                <div className="ml-lg-5 ml-0">{title}</div>
            </Row>
        </div>
    );
};

export default PricingHandBand;
