import classNames from 'classnames';
import dayjs from 'dayjs';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Column } from 'primereact/column';
import React, { useEffect } from 'react';

import KTUtil from '@src/_metronic/_assets/js/util';
import { Intl, Locale } from '@src/_metronic/i18n/I18nProvider';

import useFilters from '@app/hooks/useFilters';
import { Pagination } from '@app/hooks/useTableHandlers';

import { ucwords } from '@app/helpers/StringHelper';
import { empty } from '@app/helpers/ToolsHelper';

import {
    CustomerInNote,
    CustomerNoteType,
    CustomerNoteTypeInListing,
    NotesSearchParams,
} from '@app/crud/note/note.type';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import InputFilter from '@app/partials/content/InputFilter';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';

type ColumnType = {
    body: (note: CustomerNoteType) => JSX.Element;
};

type ColumnsType = {
    [key: string]: ColumnType;
};

type CustomerNotesDatatableProps = {
    notes: CustomerNoteTypeInListing[];
    status: string;
    totalRecords: number;
    handleFilter: (args: NotesSearchParams) => void;
    handlePagination: (e: Pagination) => void;
    loading: boolean;
    columns: ColumnsType;
};

const CustomerNotesDatatable = ({
    notes,
    status,
    totalRecords,
    handleFilter,
    handlePagination,
    loading,
    columns,
}: CustomerNotesDatatableProps) => {
    const { items, start, sortField, filters, sortOrder } = useFilters({
        name: 'customers_notes_search',
    });

    useEffect(() => {
        if (status)
            handleFilter({
                isRead: status ? status === 'read' : null,
            });
        else {
            handleFilter({});
        }
    }, [status]);

    const inputFilter = (name: string) => <InputFilter name={name} onChange={handleFilter} />;

    const getCustomerId = (customer: CustomerInNote) => {
        if (customer?.id) {
            return customer.id;
        }
    };

    const infoBody = (note: CustomerNoteTypeInListing) => {
        const customer = note.contact;
        return (
            <span>
                <span className="d-lg-block d-none">
                    Id: {getCustomerId(customer)}
                    <br />
                </span>
                <span
                    className={classNames({
                        'text-primary': customer?.isClient,
                        'text-danger': !customer?.isClient,
                    })}
                >
                    &bull;&nbsp;
                    {(customer?.isPro &&
                        !customer?.isClient &&
                        `${Intl.formatMessage({ id: 'TRANSLATOR.PROSPECT' })} (${Intl.formatMessage({
                            id: 'TRANSLATOR.PRO',
                        })})`) ||
                        (!customer?.isClient && Intl.formatMessage({ id: 'TRANSLATOR.PROSPECT' })) ||
                        (customer?.isPro &&
                            customer?.isClient &&
                            `${Intl.formatMessage({ id: 'TRANSLATOR.CLIENT' })} ${Intl.formatMessage({
                                id: 'TRANSLATOR.PRO',
                            })}`) ||
                        (customer?.isClient && Intl.formatMessage({ id: 'TRANSLATOR.CLIENT' }))}
                </span>
            </span>
        );
    };

    const nameBody = (note: CustomerNoteTypeInListing) => {
        const customer = note.contact;
        if (customer?.isPro) {
            if (!customer.companyName) {
                return '-';
            }

            return (
                <span>
                    {customer?.companyName ? (
                        <>
                            {customer.companyName}
                            <br className="d-lg-block d-none" />
                        </>
                    ) : (
                        ''
                    )}
                    <div className="d-lg-block d-inline-block ml-lg-none ml-1" />
                </span>
            );
        }
        if (!customer.lastname && !customer.firstname) {
            return '-';
        }

        return (
            <span>
                {customer?.lastname ? (
                    <>
                        {customer.lastname}
                        <br className="d-lg-block d-none" />
                    </>
                ) : (
                    ''
                )}
                <div className="d-lg-block d-inline-block ml-lg-none ml-1" />
                {customer?.firstname || ''}
            </span>
        );
    };

    const phoneBody = (customer: CustomerInNote, html = true) => {
        if (!customer.mobile && !customer.phone) {
            return null;
        }

        const phone = parsePhoneNumberFromString(customer?.phone || '', Locale.toUpperCase());
        const mobile = parsePhoneNumberFromString(customer?.mobile || '', Locale.toUpperCase());

        if (!html) {
            return (mobile && mobile.formatNational()) || (phone && phone.formatNational());
        }

        return (
            (mobile || phone) && (
                <>
                    {(mobile && mobile.formatNational()) || (phone && phone.formatNational())}
                    <br />
                </>
            )
        );
    };

    const cityBody = (note: CustomerNoteTypeInListing) => {
        const customer = note.contact;

        if (customer?.isPro) {
            return <span>{customer?.city && <span>{customer.city}</span>}</span>;
        }
        if (!customer.zipcode && !customer.city) {
            return (
                <i style={{ fontSize: '12px' }}>{Intl.formatMessage({ id: 'CUSTOMERS.TABLE.DATA.DEFAULT.CITY' })}</i>
            );
        }

        return (
            <span>
                {customer?.zipcode && (
                    <>
                        {customer.zipcode}
                        <br />
                    </>
                )}
                {customer?.city || ''}
            </span>
        );
    };

    const contactBody = (note: CustomerNoteTypeInListing) => {
        const customer = note.contact;
        if (customer?.isPro) {
            if (!customer.companyPhone) {
                return '-';
            }

            return (
                <>
                    {customer?.gdprDealerships && (
                        <div className="d-flex">
                            <div>
                                {customer?.gdprDealerships[0]?.gdprPhone ? (
                                    <i className="las la-mobile kt-font-success" />
                                ) : (
                                    <i className="las la-mobile kt-font-danger" />
                                )}
                                {customer?.gdprDealerships[0]?.gdprEmail ? (
                                    <i className="las la-comments kt-font-success" />
                                ) : (
                                    <i className="las la-comments kt-font-danger" />
                                )}
                                <br />
                                {customer?.gdprDealerships[0]?.gdprSms ? (
                                    <i className="las la-envelope kt-font-success" />
                                ) : (
                                    <i className="las la-envelope kt-font-danger" />
                                )}
                            </div>
                            <div>
                                &nbsp; {customer?.companyPhone || ''} <br />
                            </div>
                        </div>
                    )}
                    {!customer?.gdprDealerships && (
                        <>
                            &nbsp; {customer?.companyPhone || ''} <br />{' '}
                        </>
                    )}
                </>
            );
        }
        if (!customer.mobile && !customer.phone && !customer.email) {
            return '-';
        }

        return (
            <>
                {customer?.gdprDealerships && (
                    <div className="d-flex">
                        <div>
                            {customer?.gdprDealerships[0]?.gdprPhone ? (
                                <i className="las la-mobile kt-font-success" />
                            ) : (
                                <i className="las la-mobile kt-font-danger" />
                            )}
                            {customer?.gdprDealerships[0]?.gdprEmail ? (
                                <i className="las la-comments kt-font-success" />
                            ) : (
                                <i className="las la-comments kt-font-danger" />
                            )}
                            <br />
                            {customer?.gdprDealerships[0]?.gdprSms ? (
                                <i className="las la-envelope kt-font-success" />
                            ) : (
                                <i className="las la-envelope kt-font-danger" />
                            )}
                        </div>
                        <div>
                            {customer?.phone || customer?.mobile ? <>&nbsp;{phoneBody(customer)}</> : <br />}
                            <p className="text-flowing">&nbsp;{customer?.email || ''}</p>
                        </div>
                    </div>
                )}

                {!customer?.gdprDealerships && (
                    <span>
                        {phoneBody(customer)}
                        {customer?.email || ''}
                    </span>
                )}
            </>
        );
    };

    const dealershipBody = (note: CustomerNoteTypeInListing) => note.dealership.usualName;

    const vehicleBody = (note: CustomerNoteTypeInListing) => {
        const customer = note.contact;

        if (!customer?.vehicleKnow?.length || customer?.vehicleKnow?.length === 0) {
            return <>-</>;
        }

        if (empty(customer.vehicleKnow[0] && customer.vehicleKnow[0].brand) && empty(customer.vehicleKnow[0].model)) {
            return <>-</>;
        }

        const vehicles =
            Array.isArray(customer.vehicleKnow) &&
            customer.vehicleKnow
                .map((vehicle) => {
                    let brand = '';
                    let model = '';

                    if (!empty(vehicle.brand)) {
                        brand = ucwords((vehicle?.brand ?? '').toLowerCase());
                    }
                    if (!empty(vehicle.model)) {
                        model = ucwords((vehicle?.model ?? '').toLowerCase());
                    }

                    const registrationNumber = vehicle?.registrationNumber ?? '';
                    const vin = vehicle?.vin ?? '';

                    return [
                        [brand, model].filter((e) => !empty(e)).join(' '),
                        [registrationNumber, vin].filter((e) => !empty(e)).join(' '),
                    ];
                })
                .filter((e) => !empty(e));
        const firstVehicleData = vehicles.shift();

        return (
            <span className="d-flex justify-content-between align-items-end">
                <span className="d-flex justify-content-between align-items-center">
                    {firstVehicleData &&
                        firstVehicleData.map((row: string, index: number) => (
                            <React.Fragment key={row.replaceAll(' ', '_').toLowerCase()}>
                                {row}
                                {index !== firstVehicleData.length - 1 && <br />}
                            </React.Fragment>
                        ))}
                </span>

                {!empty(vehicles) && (
                    <OverlayTooltip
                        id="customer-vehicle-tooltip"
                        label={vehicles.map((vehicle) => vehicle.join(`<br>`)).join('<hr />')}
                        position="top"
                        translate={false}
                    >
                        <span className="label label-primary">+{vehicles.length}</span>
                    </OverlayTooltip>
                )}
            </span>
        );
    };

    const referentBody = (note: CustomerNoteTypeInListing) => {
        const { referent } = note;

        return (
            <span>
                {referent?.firstname} {referent?.lastname}
                {note.reader && (
                    <div className="font-italic" style={{ fontSize: '10px' }}>
                        Lu par {note.reader.firstname} {note.reader.lastname} le{' '}
                        {dayjs(note.dateRead).format('DD/MM/YYYY [à] HH [h] mm')}
                    </div>
                )}
            </span>
        );
    };

    const cols = [
        {
            field: 'contactId',
            header: Intl.formatMessage({ id: 'CUSTOMERS.TABLE.HEAD.INFO' }),
            body: infoBody,
            style: { width: '90px' },
            filter: true,
            filterElement: inputFilter('contactId'),
        },
        {
            field: 'contactName',
            header: Intl.formatMessage({ id: 'CUSTOMERS.TABLE.HEAD.NAME' }),
            body: nameBody,
            style: { width: '120px' },
            filter: true,
            filterElement: inputFilter('contactName'),
        },
        {
            field: 'contactInfoContact',
            header: Intl.formatMessage({ id: 'CUSTOMERS.TABLE.HEAD.CONTACT' }),
            body: contactBody,
            filter: true,
            filterElement: inputFilter('contactInfo'),
        },
        !KTUtil.isSmallDesktopOrMobileDevice() && {
            field: 'contactCity',
            header: Intl.formatMessage({ id: 'CUSTOMERS.TABLE.HEAD.CITY' }),
            body: cityBody,
            style: { width: '125px' },
            filter: true,
            filterElement: inputFilter('city'),
        },
        {
            field: 'dealershipKnown',
            header: Intl.formatMessage({ id: 'CUSTOMERS.VEHICLE.TABLE.HEAD.DEALERSHIP' }),
            body: dealershipBody,
            filter: true,
            filterElement: inputFilter('dealershipName'),
        },
        {
            field: 'vehicleKnown',
            header: Intl.formatMessage({ id: 'CUSTOMERS.TABLE.HEAD.VEHICLE' }),
            body: vehicleBody,
            filter: true,
            filterElement: inputFilter('vehicleKnown'),
        },
        {
            field: 'noteType',
            header: Intl.formatMessage({ id: 'CUSTOMERS.TABLE.HEAD.NOTE_TYPE' }),
            body: (note: CustomerNoteTypeInListing) => <span>{note.noteType.name}</span>,
            filter: false,
        },
        {
            field: 'ownerName',
            header: Intl.formatMessage({ id: 'CUSTOMERS.TABLE.HEAD.COMMERCE_CONTACT' }),
            body: referentBody,
            style: { width: '145px' },
            filter: true,
            filterElement: inputFilter('referent'),
        },
        {
            field: 'actions',
            header: Intl.formatMessage({ id: 'CUSTOMERS.TABLE.HEAD.ACTIONS' }),
            style: { width: '65px' },
            ...(columns?.actions ? columns.actions : {}),
        },
    ];

    const dynamicColumns = cols.map((col) => col && <Column key={col.field} {...col} />);

    return (
        <div>
            <FiltersProvider value={filters}>
                {/* @ts-ignore */}
                <HoCDataTable
                    value={notes}
                    totalRecords={totalRecords}
                    lazy
                    loading={loading}
                    paginator
                    onFilter={handleFilter}
                    onPage={handlePagination}
                    paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                    currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                    rows={items}
                    first={start}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    removableSort
                    filterDisplay="row"
                    rowsPerPageOptions={[25, 50, 100]}
                    emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                >
                    {dynamicColumns}
                </HoCDataTable>
            </FiltersProvider>
        </div>
    );
};

export default CustomerNotesDatatable;
