import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, UseFormMethods, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import useFetch from '@app/hooks/useFetch';

import { UserReturn, vendorsSearch } from '@app/crud/autouser/user.crud';

import { useAppSelector } from '@app/hooks';
import { fetchSchedules } from '@app/store/schedule/schedule.thunk';

type DealerSelectProps = {
    methods: UseFormMethods;
    datesSetSelected: {
        dateStart: string;
        dateEnd: string;
    };
    // eslint-disable-next-line @typescript-eslint/ban-types
    setDealerSelected: Function;
    defaultVendors: UserReturn[];
};

const DealerSelect = ({ methods, datesSetSelected, setDealerSelected, defaultVendors }: DealerSelectProps) => {
    const { userData } = useAppSelector((state) => state.auth);
    const selectedDealership = useWatch<string>({ name: 'dealership' });
    const [vendorsSelectValues, setVendorsSelectValues] = useState([]);
    const { fetch: fetchVendors, data: vendors } = useFetch({
        fetchAction: vendorsSearch,
        resultInterceptor: (response) => response.result,
    });
    const intl = useIntl();
    const { setValue, control } = methods;
    const dispatch = useDispatch();
    const [vendorSelected, setVendorSelected] = useState(null);

    useEffect(() => {
        if (selectedDealership && !defaultVendors) {
            fetchVendors({
                dealerships: selectedDealership,
                scope: false,
            });
        }
    }, [selectedDealership]);

    const formatValues = (vendor: UserReturn) => ({
        id: vendor.id,
        firstname: vendor.first_name,
        lastname: vendor.last_name,
        fullname: `${vendor.first_name} ${vendor.last_name}`,
        email: vendor.email,
    });

    useEffect(() => {
        if (defaultVendors?.length) {
            setVendorsSelectValues([...Object.values(defaultVendors).map((vendor) => formatValues(vendor))]);
            setValue('dealer', defaultVendors[0].id);
        } else if (vendors && userData) {
            if (Object.values(vendors).length) {
                setVendorsSelectValues([
                    ...Object.values(vendors)[0].map((vendor: UserReturn) => formatValues(vendor)),
                    {
                        id: userData.id,
                        fullname: `${userData.first_name} ${userData.last_name}`,
                        email: userData.email,
                    },
                ]);
            }

            setValue('dealer', userData.id);
        }
    }, [defaultVendors, vendors, userData]);

    useEffect(() => {
        if (vendorSelected !== null && datesSetSelected !== null) {
            dispatch(
                fetchSchedules({
                    userId: vendorSelected.id,
                    userEmail: vendorSelected.email,
                    dateStart: datesSetSelected.dateStart,
                    dateEnd: datesSetSelected.dateEnd,
                }),
            );
            setDealerSelected({
                id: vendorSelected.id,
                firstname: vendorSelected.firstname,
                lastname: vendorSelected.lastname,
                email: vendorSelected.email,
            });
        }
    }, [vendorSelected, datesSetSelected]);

    /* eslint-disable react/prop-types */
    return (
        <Form.Group>
            <Form.Label>{intl.formatMessage({ id: 'CUSTOMER.MODAL.ASSIGN.REFERENT.SELECT' })}</Form.Label>
            <Controller
                control={control}
                name="dealer"
                render={(props) => (
                    <Dropdown
                        options={vendorsSelectValues}
                        optionLabel="fullname"
                        optionValue="id"
                        value={props.value}
                        onChange={(e) => {
                            props.onChange(e.value);
                            const vendorSelectedItem = vendorsSelectValues.find((x) => x.id === e.value);
                            return vendorSelectedItem !== null && setVendorSelected(vendorSelectedItem);
                        }}
                    />
                )}
            />
        </Form.Group>
    );
};

export default DealerSelect;
