import { Column } from 'primereact/column';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import KTUtil from '@src/_metronic/_assets/js/util';

import { Filters } from '@app/hooks/useFilters';
import { TableHandler } from '@app/hooks/useTableHandlers';

import { VehiclesResponse } from '@app/crud/stock/stock.crud';

import { useAppDispatch, useAppSelector } from '@app/hooks';
import { actions } from '@app/store/stock/stock.store';
import { Vehicle } from '@app/store/stock/stock.type';

import HoCDataTable from '@app/partials/content/HoCDataTable';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';
import { getEligibleCredit, getEligibleLOA } from '@app/partials/content/Vehicle/VehicleHelpers';

import StockVehiclesActionsColumn from './column/StockVehiclesActionsColumn';
import StockVehiclesEligibilityColumn from './column/StockVehiclesEligibilityColumn';
import StockVehiclesEligibilityPublicationResponsiveColumn from './column/StockVehiclesEligibilityPublicationResponsiveColumn';
import StockVehiclesFundingColumn from './column/StockVehiclesFundingColumn';
import StockVehiclesPhotosColumn from './column/StockVehiclesPhotosColumn';
import StockVehiclesPlaceDurationColumn from './column/StockVehiclesPlaceDurationColumn';
import StockVehiclesPriceColumn from './column/StockVehiclesPriceColumn';
import StockVehiclesPublicationColumn from './column/StockVehiclesPublicationColumn';
import StockVehiclesPublicationStatusColumn from './column/StockVehiclesPublicationStatusColumn';
import StockVehiclesTypeColumn from './column/StockVehiclesTypeColumn';
import StockVehiclesVehicleGuaranteeColumn from './column/StockVehiclesVehicleGuaranteeColumn';
import StockVehiclesVehicleIdColumn from './column/StockVehiclesVehicleIdColumn';

interface StockVehiclesDataTableProps {
    stockFilters: Filters;
    stockTableHandler: TableHandler<VehiclesResponse>;
}
const StockVehiclesDataTable = ({ stockFilters, stockTableHandler }: StockVehiclesDataTableProps) => {
    const { items, start, sortField, sortOrder } = stockFilters;
    const { data, loading, handleFilter, handlePagination, handleSort } = stockTableHandler;
    const Intl = useIntl();
    const dispatch = useAppDispatch();
    const [forceRerender, setForceRerender] = useState(false);
    const { vehiclesSelected } = useAppSelector((state) => state.stock);
    const isContextEFB = useAppSelector((state) => state.auth.userData.context === 'efb');

    const vehicles = data?.results || null;

    const sortEventListener = (e: MouseEvent) => {
        const target = e.target as HTMLElement;
        target.closest<HTMLDivElement>('.p-column-title').click();
    };

    useEffect(() => {
        dispatch(actions.UpdateVehicleSelected([]));
        if (vehicles) {
            const columnsHeaders = Array.from<HTMLTableCellElement>(
                document.getElementsByClassName('p-sortable-column') as HTMLCollectionOf<HTMLTableCellElement>,
            );

            if (Array.isArray(columnsHeaders) && columnsHeaders.length > 0) {
                columnsHeaders.forEach((columnHeader) => {
                    columnHeader.removeEventListener('click', sortEventListener);
                    columnHeader.addEventListener('click', sortEventListener);
                });
            }
            setForceRerender(!setForceRerender);
        }
    }, [vehicles]);

    const headerIdColumn = () => {
        const vehiclesData = vehicles?.reduce((acc, vehicle) => {
            // eslint-disable-next-line no-underscore-dangle
            const vehicleData = vehicle._data;
            const identifier = vehicleData?.sku;
            return {
                ...acc,
                [identifier]: {
                    comptant: true,
                    credit: getEligibleCredit(vehicleData),
                    location: getEligibleLOA(vehicleData),
                },
            };
        }, []);
        return (
            <>
                <input
                    type="checkbox"
                    aria-label="Select"
                    className="p-checkbox--small mr-3"
                    checked={
                        JSON.stringify(
                            Object.keys(vehiclesSelected || {})
                                .filter((v) => vehiclesSelected[v] !== undefined)
                                .sort(),
                        ) === JSON.stringify(Object.keys(vehiclesData || {}).sort())
                    }
                    onClick={(e) => {
                        const target = e?.target as HTMLInputElement;
                        dispatch(actions.UpdateVehicleSelected(target?.checked && vehicles ? vehiclesData : []));
                        setForceRerender(!forceRerender);
                    }}
                />
                <FormattedHTMLMessage id="STOCK_VEHICLES.TABLE.VEHICLE_ID" />
            </>
        );
    };

    const cols = [
        {
            field: 'police_number',
            header: headerIdColumn(),
            body: ({ _data }: { _data: Vehicle }) => (
                <StockVehiclesVehicleIdColumn
                    vehicle={_data}
                    forceRerender={forceRerender}
                    setForceRerender={setForceRerender}
                />
            ),
            style: KTUtil.isSmallDesktopOrMobileDevice() ? { width: '12%' } : { width: '9%' },
        },
        !KTUtil.isSmallDesktopOrMobileDevice() && {
            field: 'type',
            header: Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.TYPE' }),
            style: { width: '100px' },
            body: ({ _data }: { _data: Vehicle }) => <StockVehiclesTypeColumn vehicle={_data} />,
        },
        {
            field: 'brand',
            header: Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.VEHICLE_GUARANTEE' }),
            style: { width: '17%' },
            body: ({ _data }: { _data: Vehicle }) => <StockVehiclesVehicleGuaranteeColumn vehicle={_data} />,
        },
        {
            field: 'dealer_usual_name',
            header: (
                <OverlayTooltip
                    label={Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.COLUMN_DEALERSHIP_TOOLTIP' })}
                    id="dealership"
                    position="top"
                >
                    <span className="p-sortable-column-tooltip">
                        {Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.PLACE_DURATION' })}
                    </span>
                </OverlayTooltip>
            ),
            sortable: true,
            style: KTUtil.isSmallDesktopOrMobileDevice() ? { width: '9%' } : { width: '10%' },
            body: ({ _data }: { _data: Vehicle }) => <StockVehiclesPlaceDurationColumn vehicle={_data} />,
        },
        {
            field: 'price',
            header: (
                <OverlayTooltip
                    label={Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.COLUMN_PRICE_TOOLTIP' })}
                    id="price"
                    position="top"
                >
                    <span className="p-sortable-column-tooltip">
                        {Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.PRICE_TTC' })}
                    </span>
                </OverlayTooltip>
            ),
            sortable: true,
            style: KTUtil.isSmallDesktopOrMobileDevice() ? { width: '9%' } : { width: '8%' },
            body: ({ _data }: { _data: Vehicle }) => <StockVehiclesPriceColumn vehicle={_data} />,
        },
        {
            field: 'funding',
            header: Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.FUNDING' }),
            style: KTUtil.isSmallDesktopOrMobileDevice() ? { width: '10%' } : { width: '8%' },
            body: ({ _data }: { _data: Vehicle }) => <StockVehiclesFundingColumn vehicle={_data} />,
        },
        {
            field: 'nb_photos',
            header: (
                <OverlayTooltip
                    label={Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.COLUMN_PICTURE_TOOLTIP' })}
                    id="pictures"
                    position="top"
                >
                    <span className="p-sortable-column-tooltip">
                        {Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.PHOTOS' })}
                    </span>
                </OverlayTooltip>
            ),
            sortable: true,
            style: { width: '8%' },
            body: ({ _data }: { _data: Vehicle }) => <StockVehiclesPhotosColumn vehicle={_data} />,
        },
        !KTUtil.isSmallDesktopOrMobileDevice() &&
            !isContextEFB && {
                field: 'eligibility',
                header: Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.ELIGIBILITY' }),
                style: { width: '7%' },
                body: ({ _data }: { _data: Vehicle }) => <StockVehiclesEligibilityColumn vehicle={_data} />,
            },
        {
            field: 'publication',
            header: (
                <OverlayTooltip
                    label={Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.COLUMN_PUBLICATION_STATUS_TOOLTIP' })}
                    id="publication-status"
                    position="top"
                >
                    <span className="p-sortable-column-tooltip">
                        {Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.PUBLICATION_STATUS' })}
                    </span>
                </OverlayTooltip>
            ),
            sortable: true,
            style: KTUtil.isSmallDesktopOrMobileDevice() ? { width: '10%' } : { width: '12%' },
            body: ({ _data }: { _data: Vehicle }) => <StockVehiclesPublicationStatusColumn vehicle={_data} />,
        },
        !KTUtil.isSmallDesktopOrMobileDevice() && {
            field: 'publication2',
            header: Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.PUBLICATION' }),
            style: { width: '120px' },
            body: ({ _data }: { _data: Vehicle }) => <StockVehiclesPublicationColumn vehicle={_data} />,
        },
        KTUtil.isSmallDesktopOrMobileDevice() && {
            field: 'publication2',
            header: `${Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.ELIGIBILITY' })} - ${Intl.formatMessage({
                id: 'STOCK_VEHICLES.TABLE.PUBLICATION',
            })}`,
            style: { width: '11%' },
            body: ({ _data }: { _data: Vehicle }) => (
                <StockVehiclesEligibilityPublicationResponsiveColumn vehicle={_data} />
            ),
        },
        {
            field: 'action',
            header: Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.ACTIONS' }),
            style: KTUtil.isSmallDesktopOrMobileDevice()
                ? {
                      width: '7%',
                      textAlign: 'center' as const,
                      verticalAlign: 'middle',
                  }
                : { width: '6%', textAlign: 'center' as const },
            body: ({ _data }: { _data: Vehicle }) => <StockVehiclesActionsColumn vehicle={_data} />,
        },
    ];

    const dynamicColumns = cols.map((col) => col && <Column key={col.field} {...col} />);

    return useMemo(
        () => (
            // @ts-ignore
            <HoCDataTable
                value={vehicles}
                totalRecords={data?.total}
                fixedHeader
                lazy
                rows={items}
                start={start}
                first={start}
                loading={loading}
                paginator
                onFilter={handleFilter}
                sortField={sortField}
                sortOrder={sortOrder}
                onPage={handlePagination}
                onSort={handleSort}
                className="p-datatable--baseline"
                paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                removableSort
                rowsPerPageOptions={[25, 50, 100]}
                emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
            >
                {dynamicColumns}
            </HoCDataTable>
        ),
        [vehicles, loading, forceRerender],
    );
};

export default StockVehiclesDataTable;
