import classNames from 'classnames';
import { Divider } from 'primereact/divider';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import useFetch from '@app/hooks/useFetch';

import { getDashboardRanking } from '@app/crud/dashboard/dashboard.crud';

import { actions } from '@app/store/modal/modal.store';

import Label from '@app/partials/content/Label';

import { DashboardContext } from '../../DashboardProvider';
import {
    getRankingColor,
    getTransformation,
    isAvailableDealershipsRanking,
    sortRanking,
} from '../../helpers/DashboardHelper';

import DashboardRankingIcon from './DashboardRankingIcon';

type DashboardRankingProps = {
    className?: string;
};

const DashboardRanking = ({ className }: DashboardRankingProps) => {
    const dispatch = useDispatch();
    const { role, params, overriddenParams } = useContext(DashboardContext);

    const { data, loading, fetch } = useFetch({
        fetchAction: getDashboardRanking,
    });

    useEffect(() => {
        if (overriddenParams) {
            fetch({ params: overriddenParams });
        }
    }, [overriddenParams]);

    if (loading) {
        return <DashboardRanking.Skeleton />;
    }

    return (
        <div className={classNames('dashboard-ranking d-flex flex-column', className)}>
            <div className="font-size-lg font-weight-bold d-flex align-items-center">
                <i className="dashboard-general-perf__icon las la-2x la-trophy mr-2" />
                <span className="font-size-sm text-nowrap">
                    {isAvailableDealershipsRanking(role, params) ? (
                        <FormattedMessage id="DASHBOARD.RANKING.TITLE.DEALERSHIPS" />
                    ) : (
                        <FormattedMessage id="DASHBOARD.RANKING.TITLE.DEALERS" />
                    )}
                </span>
            </div>
            <Divider type="dashed" />
            <div className="d-flex flex-column flex-grow-1">
                <div className="d-lg-block d-flex flex-lg-column flex-md-row flex-column flex-grow-1">
                    {data
                        /* @ts-ignore TODO : fix typing on sortRanking method */
                        ?.sort?.(sortRanking)
                        ?.slice?.(0, 3)
                        ?.map?.((dealer, idx) => {
                            const color = getRankingColor(idx);
                            const percentage = getTransformation(
                                dealer?.leads?.won,
                                dealer?.leads?.lost,
                                dealer?.leads?.total,
                            );

                            return (
                                <div
                                    key={`${dealer.firstname}_${dealer.lastname}`}
                                    className="mb-5 flex-grow-1 d-flex align-items-start"
                                >
                                    <DashboardRankingIcon color={color} idx={idx + 1} />
                                    <div>
                                        {isAvailableDealershipsRanking(role, params) ? (
                                            <>
                                                {/* @ts-ignore TODO : check if still used */}
                                                <div className="font-weight-bold">{dealer.name}</div>
                                            </>
                                        ) : (
                                            <>
                                                <div>
                                                    {dealer.firstname} {dealer.lastname}
                                                </div>
                                                <div>{dealer.dealership && `(${dealer.dealership})`}</div>
                                            </>
                                        )}
                                        <Label className={`text-white font-weight-bold mt-1 label-${color}`}>
                                            {percentage}&nbsp;%
                                        </Label>
                                    </div>
                                </div>
                            );
                        })}
                </div>
                <button
                    type="button"
                    className="cursor-pointer text-center text-nowrap font-weight-bold p-0 m-0 border-0 bg-transparent"
                    onClick={() => {
                        dispatch(actions.modalChange('ranking_details'));
                    }}
                >
                    <u>
                        <FormattedMessage id="DASHBOARD.RANKING.SHOW_MORE" />
                    </u>
                </button>
            </div>
        </div>
    );
};

DashboardRanking.defaultProps = {
    className: null,
};

DashboardRanking.Skeleton = () => <Skeleton borderRadius="10px" className=" d-flex flex-column mt-2 flex-grow-1" />;

export default DashboardRanking;
