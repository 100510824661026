import { get, isNil, set } from 'lodash';

import { Contract } from '@app/crud/securyweb/securyweb.types';

import { User } from '@app/store/login/login.store';

/* key : AMNG form field
 value : Securyweb field */
const MATCH_FIELD_FORM_CONTRACT = {
    id: 'id',
    contractNumber: 'numero',
    dealership: 'codeConcession',
    product: 'codeProduit',
    offerFamily: 'offreFamille',
    offerCollab: 'offreCollaborateur',
    initialDuration: 'dureeSouscription',
    subscriptionDate: 'dateSouscription',
    priceWithTaxes: 'prixVenteTtc',
    priceWithoutTaxe: 'prixVenteHt',
    iban: 'rib.iban',
    bic: 'rib.bic',
    securywebId: 'idVendeur',
    secretaryId: 'idSecretaire',
    statut: 'statut',
    'subscriber.civility': 'souscripteur.civilite',
    'subscriber.language': 'langue', // should be return in souscripteur and beneficiaire
    'subscriber.lastname': 'souscripteur.nom',
    'subscriber.firstname': 'souscripteur.prenom',
    'subscriber.addressFloor': 'souscripteur.adresse1',
    'subscriber.addressBuilding': 'souscripteur.adresse2',
    'subscriber.address': 'souscripteur.adresse3',
    'subscriber.addressPlaceCalled': 'souscripteur.adresse4',
    'subscriber.zipcode': 'souscripteur.ville.codePostal',
    'subscriber.city': 'souscripteur.ville.localite',
    'subscriber.cityId': 'souscripteur.ville.id',
    'subscriber.localityComplement': 'souscripteur.complementVille',
    'subscriber.phone': 'souscripteur.telephoneFixe',
    'subscriber.mobile': 'souscripteur.telephonePortable',
    'subscriber.email': 'souscripteur.email',
    'subscriber.birthDate': 'souscripteur.dateNaissance',
    'subscriber.maritalStatus': 'souscripteur.statutMarital',
    'subscriber.proCategory': 'souscripteur.categorieProfessionnelle',
    'subscriber.siren': 'souscripteur.siren',
    'subscriber.companyName': 'souscripteur.companyName',
    'beneficiary.civility': 'beneficiaire.civilite',
    'beneficiary.lastname': 'beneficiaire.nom',
    'beneficiary.firstname': 'beneficiaire.prenom',
    'beneficiary.addressFloor': 'beneficiaire.adresse1',
    'beneficiary.addressBuilding': 'beneficiaire.adresse2',
    'beneficiary.address': 'beneficiaire.adresse3',
    'beneficiary.addressPlaceCalled': 'beneficiaire.adresse4',
    'beneficiary.zipcode': 'beneficiaire.ville.codePostal',
    'beneficiary.city': 'beneficiaire.ville.localite',
    'beneficiary.cityId': 'beneficiaire.ville.id',
    'beneficiary.localityComplement': 'beneficiaire.complementVille',
    'beneficiary.phone': 'beneficiaire.telephoneFixe',
    'beneficiary.mobile': 'beneficiaire.telephonePortable',
    'beneficiary.email': 'beneficiaire.email',
    'beneficiary.birthDate': 'beneficiaire.dateNaissance',
    'beneficiary.maritalStatus': 'beneficiaire.statutMarital',
    'beneficiary.proCategory': 'beneficiaire.categorieProfessionnelle',
    'vehicle.registrationNumber': 'vehicule.immatriculation',
    'vehicle.registrationNumberDate': 'vehicule.datePremiereMiseEnCirculation',
    'vehicle.id': 'vehicule.id',
    'vehicle.vin': 'vehicule.codeVIN',
    'vehicle.registrationDocumentDate': 'dateCarteGrise',
    'vehicle.mine': 'vehicule.typeCNIT',
    'vehicle.brand': 'vehicule.modele.marque.libelle',
    'vehicle.modelId': 'vehicule.modele.id',
    'vehicle.vehicleCategory': 'vehicule.genre',
    'vehicle.model': 'vehicule.modele.libelle',
    'vehicle.fundingPrice': 'montantAchatVehicule',
    'vehicle.fundingMode': 'modeFinancementVehicule',
    'vehicle.paymentMode': 'modePaiement',
};

export const mapFieldFormToSecuryweb = (formData: any) => {
    const mappedContract = {};

    Object.entries(MATCH_FIELD_FORM_CONTRACT).forEach(([amngField, securywebField]) => {
        const value = get(formData, amngField);
        if (!isNil(value) && value !== '') {
            set(mappedContract, securywebField, value);
        }
    });

    return mappedContract as Contract;
};

export const mapSecurywebToFieldForm = (contract: Contract) => {
    const formData = {};

    Object.entries(MATCH_FIELD_FORM_CONTRACT).forEach(([amngField, securywebField]) => {
        let securywebFieldToMatch;
        switch (amngField) {
            case 'product':
                securywebFieldToMatch = 'produit.code';
                break;
            case 'dealership':
                securywebFieldToMatch = 'concession.code';
                break;
            default:
                securywebFieldToMatch = securywebField;
        }

        const value = get(contract, securywebFieldToMatch);
        if (!isNil(value) && value !== '') {
            set(formData, amngField, value);
        }

        if (get(formData, 'offerFamily')) {
            set(formData, 'offer', 'FAMILY');
        }
        if (get(formData, 'offerCollab')) {
            set(formData, 'offer', 'COLLAB');
        }
    });

    return formData;
};

export const mapFieldFormToCustomer = (customerId: string, userData: User, formData: any) => {
    const mappedCustomer = {};
    const mapCivility = (civility: string) => {
        if (civility === 'M') {
            return 'mr';
        } else if (civility === 'MME') {
            return 'mme';
        }
    };

    if (customerId) {
        set(mappedCustomer, 'user.id', Number(customerId));
    }

    const civility = get(formData, 'subscriber.civility');
    const clientCivility = mapCivility(civility);
    if (clientCivility) {
        set(mappedCustomer, 'civility', clientCivility);
    }
    if (get(formData, 'subscriber.firstname')) {
        set(mappedCustomer, 'firstname', get(formData, 'subscriber.firstname'));
        set(mappedCustomer, 'user.firstname', get(formData, 'subscriber.firstname'));
    }
    if (get(formData, 'subscriber.lastname')) {
        set(mappedCustomer, 'lastname', get(formData, 'subscriber.lastname'));
        set(mappedCustomer, 'user.lastname', get(formData, 'subscriber.lastname'));
    }
    if (get(formData, 'subscriber.birthday')) {
        set(mappedCustomer, 'birthday', get(formData, 'subscriber.birthday'));
    }
    if (get(formData, 'subscriber.companyName')) {
        set(mappedCustomer, 'companyName', get(formData, 'subscriber.companyName'));
    }
    if (get(formData, 'subscriber.siren')) {
        set(mappedCustomer, 'siren', get(formData, 'subscriber.siren'));
    }
    if (
        get(formData, 'subscriber.addressBuilding') ||
        get(formData, 'subscriber.addressFloor') ||
        get(formData, 'subscriber.addressPlaceCalled')
    ) {
        set(
            mappedCustomer,
            'additionalAddress',
            `${get(formData, 'subscriber.addressBuilding', '')} - ${get(
                formData,
                'subscriber.addressFloor',
                '',
            )} - ${get(formData, 'subscriber.addressPlaceCalled', '')}`,
        );
    }

    if (get(formData, 'subscriber.address')) {
        set(mappedCustomer, 'address', get(formData, 'subscriber.address'));
    }
    if (get(formData, 'subscriber.zipcode')) {
        set(mappedCustomer, 'zipcode', get(formData, 'subscriber.zipcode'));
    }
    if (get(formData, 'subscriber.city')) {
        set(mappedCustomer, 'city', get(formData, 'subscriber.city'));
    }
    if (civility !== 'STE') {
        set(mappedCustomer, 'isClient', true);
        set(mappedCustomer, 'phone', get(formData, 'subscriber.phone'));
        set(mappedCustomer, 'mobile', get(formData, 'subscriber.mobile'));
    } else if (civility === 'STE') {
        set(mappedCustomer, 'isPro', true);
        set(mappedCustomer, 'proPhone', get(formData, 'subscriber.phone'));
        set(mappedCustomer, 'companyPhone', get(formData, 'subscriber.mobile'));
    }

    const dealershipId = get(formData, 'dealership');
    if (dealershipId) {
        const foundDealershipKey = Object.keys(userData?.dealerships)?.find(
            (dealershipKey) => dealershipKey === dealershipId,
        );
        if (foundDealershipKey) {
            set(mappedCustomer, 'dealershipKnow', [
                {
                    refDealershipId: dealershipId,
                    refDealershipName: userData?.dealerships[foundDealershipKey]?.title,
                },
            ]);
        }
    }

    return mappedCustomer;
};
