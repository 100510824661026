import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { getProducts } from '@app/crud/securyweb/securyweb.crud';
import { Product } from '@app/crud/securyweb/securyweb.types';

import variantTypes from '@app/constants';
import { useFetch } from '@app/hooks';
import FormPartTitle from '@app/pages/customers/Modals/SecuryCarContract/FormPartTitle';
import DealershipVendors from '@app/pages/customers/Partials/DealershipVendors';
import type { User } from '@app/store/login/login.store';
import { actions } from '@app/store/modal/modal.store';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

import { isFieldRequired } from './Helpers/SecurywebHelper';

type SecuryCarContractProps = {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
};

const SecuryCarContract = ({ showModal, setShowModal }: SecuryCarContractProps) => {
    const Intl = useIntl();
    const dispatch = useDispatch();
    const methods = useForm();
    const { setValue, register, getValues, control, watch, errors, handleSubmit } = methods;

    const {
        fetch: fetchProducts,
        data: products,
        loading: loadingProducts,
    } = useFetch({
        fetchAction: getProducts,
        resultInterceptor: (response) => response.result as Product[],
    });

    useEffect(() => {
        register('dealership', {
            required:
                isFieldRequired('dealership', watch('statut')) && Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
        });
        register('dealer', {
            required: isFieldRequired('dealer', watch('statut')) && Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
        });
    }, [register]);

    const onChangeDealership = (value: string) => {
        setValue('dealership', value);
        setValue('product', '');

        fetchProducts(value);
    };

    const onChangeDealer = (value: object) => {
        setValue('dealer', value);
    };

    const onSubmit = (data: { dealer: { securyweb_id: string } }) => {
        dispatch(
            actions.modalChange('contract_securycar_steps', {
                formValues: { ...data, securywebId: data.dealer.securyweb_id },
                allProducts: products,
            }),
        );
    };

    const onError = () => {
        toast({
            variant: variantTypes.DANGER,
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const referentsInterceptor = (vendors: User[]) => {
        if (vendors) {
            return vendors.filter((vendor) => Boolean(vendor.securyweb_id));
        }
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-shield-alt" />}
            title={<FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.TITLE" />}
            body={
                <FormProvider {...methods}>
                    <FormPartTitle title="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.CATEGORY.DEALERSHIP.TITLE" />
                    {/* @ts-ignore */}
                    <DealershipVendors
                        labelDealership="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.DEALERSHIP"
                        labelVendor="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.VENDOR"
                        referent={watch('dealer')}
                        setReferent={onChangeDealer}
                        setDealership={onChangeDealership}
                        referentsInterceptor={referentsInterceptor}
                        dealership={getValues('dealership')}
                        registerPerimeter={false}
                        optionValueDealership="refDealershipId"
                        isRegistered
                    />
                    <FormPartTitle title="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.CATEGORY.PRODUCT.TITLE" />
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.CHOOSE_PRODUCT" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="product"
                            rules={{
                                required:
                                    isFieldRequired('product', watch('statut')) &&
                                    Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            }}
                            render={({ value, onChange }) => (
                                <Dropdown
                                    value={value}
                                    options={products}
                                    onChange={(e) => onChange(e.value)}
                                    optionLabel="libelle"
                                    optionValue="code"
                                    disabled={!watch('dealership')}
                                    className={classNames('', {
                                        'is-invalid': errors.product,
                                    })}
                                    itemTemplate={(item) => `${item?.code} - ${item?.libelle}`}
                                    {...(value && {
                                        valueTemplate: (item) => `${item?.code} - ${item?.libelle}`,
                                    })}
                                    placeholder={Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.PRODUCT',
                                    })}
                                    emptyMessage={
                                        loadingProducts
                                            ? Intl.formatMessage({
                                                  id: 'TRANSLATOR.LOADING',
                                              })
                                            : Intl.formatMessage({
                                                  id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.SELECT_PRODUCT.EMPTY',
                                              })
                                    }
                                />
                            )}
                        />
                        <ErrorForm errors={errors} name="product" />
                    </Form.Group>
                    <small>
                        <FormattedMessage id="TRANSLATOR.REQUIRED_FIELDS" />
                    </small>
                </FormProvider>
            }
            footer={
                <div className="d-flex justify-content-center">
                    <Button variant="primary" onClick={handleSubmit(onSubmit, onError)}>
                        <FormattedMessage id="TRANSLATOR.NEXT" />
                    </Button>
                </div>
            }
        />
    );
};

export default SecuryCarContract;
