import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import useFetch from '@app/hooks/useFetch';

import { getVehicleDetailClient } from '@app/crud/stock/stock.crud';

import Loader from '@app/partials/content/Loader';
import RowInfo from '@app/partials/content/RowInfo';
import toast from '@app/partials/content/Toast';

const StockVehicleStatistic = ({ vehicle }) => {
    const Intl = useIntl();
    const { fetch, data, loading, error } = useFetch({
        fetchAction: getVehicleDetailClient,
    });
    const isContextEFB = useSelector((state) => state.auth.userData.context === 'efb');

    useEffect(() => {
        if (error) {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.STATISTICS.ERROR.API' }),
            });
        } else if (vehicle?.sku) {
            fetch(vehicle.sku);
        }
    }, [vehicle, error]);

    return loading ? (
        <Loader style={{ width: '5rem', height: '5rem' }} overlay />
    ) : (
        <Card>
            <Card.Header>
                <i className="card__icon la-lg las la-comments text-dark" />
                <FormattedMessage id="STOCK_VEHICLE.CARD.STATISTICS.TITLE" />
            </Card.Header>
            <Card.Body>
                <RowInfo
                    label={<FormattedMessage id="STOCK_VEHICLE.CARD.STATISTICS.CONTENT.DATE_FIRST_PUBLICATION" />}
                    value={data?.date_first_publication || '-'}
                    colLabel={6}
                    colValue={6}
                />
                <RowInfo
                    label={<FormattedMessage id="STOCK_VEHICLE.CARD.STATISTICS.CONTENT.CLICK_NUMBER" />}
                    value={data?.views || '-'}
                    colLabel={6}
                    colValue={6}
                />
                <RowInfo
                    label={<FormattedMessage id="STOCK_VEHICLE.CARD.STATISTICS.CONTENT.ONLINE_LEAD_RECEIVED" />}
                    value={data?.leads_online || '-'}
                    colLabel={6}
                    colValue={6}
                />
                <RowInfo
                    label={
                        <FormattedMessage
                            id={`STOCK_VEHICLE.CARD.STATISTICS.CONTENT.ONLINE_LEAD_${
                                isContextEFB ? 'ABE' : 'AFR'
                            }_RECEIVED`}
                        />
                    }
                    value={data?.leads_online_afr || '-'}
                    colLabel={6}
                    colValue={6}
                />
                <RowInfo
                    label={<FormattedMessage id="STOCK_VEHICLE.CARD.STATISTICS.CONTENT.LOCAL_LEAD" />}
                    value={data?.leads_local || '-'}
                    colLabel={6}
                    colValue={6}
                />
                {!isContextEFB && (
                    <RowInfo
                        label={<FormattedMessage id="STOCK_VEHICLE.CARD.STATISTICS.CONTENT.ROAD_FW" />}
                        value={data?.full_web_course || '-'}
                        colLabel={6}
                        colValue={6}
                    />
                )}
            </Card.Body>
        </Card>
    );
};

export default StockVehicleStatistic;
