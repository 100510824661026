import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';

import useFetch from '@app/hooks/useFetch';

import { getDashboardPartDetailPendingLead } from '@app/crud/dashboard/dashboard.crud';

import { useAppSelector } from '@app/hooks';

import { DashboardContext } from '../DashboardProvider';

import { DashboardAgenda } from './Agenda';
import DashboardLead from './DashboardLead';
import { DashboardPriorities } from './Priorities';

interface DashboardActionsProps {
    className?: string;
}

const DashboardActions = ({ className }: DashboardActionsProps) => {
    const { overriddenParams } = useContext(DashboardContext);
    const { userData } = useAppSelector((state) => state.auth);
    const { data, loading, fetch } = useFetch({
        fetchAction: getDashboardPartDetailPendingLead,
    });

    useEffect(() => {
        if (overriddenParams) {
            fetch({ params: overriddenParams });
        }
    }, [overriddenParams]);

    const roleForApvDisplay = [
        'manager-autosphere-contact',
        'responsable-apv',
        'agent-autosphere-contact',
        'conseiller-commercial-services',
    ];

    return (
        <div className={classNames('dashboard-actions', className)}>
            <DashboardAgenda className="h-100" />
            <div className="d-flex flex-column">
                {loading ? (
                    <DashboardLead.Skeleton />
                ) : (
                    <DashboardLead pendingLeads={data} displayApv={roleForApvDisplay.includes(userData?.role?.slug)} />
                )}
                <DashboardPriorities className="mt-8 flex-grow-1" />
            </div>
        </div>
    );
};

DashboardActions.defaultProps = {
    className: '',
};

export default DashboardActions;
